import React from 'react';
import {Button, Modal, ModalBody, ModalFooter} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store';
import { dictionaryGet } from '../../common/functions';

type Props = {
  isModalOpen: boolean,
  closeModal: Function,
  delete: Function
}

export default function ModaleElimina(props: Props) {
  const dictionary = useSelector((state: AppState) => state.dictionary);
  return (
    <Modal isOpen={props.isModalOpen} toggle={() => {
      props.closeModal()
    }} size='md' style={{width: 'auto', height: 'auto', maxWidth: '40vw'}}>
      <ModalBody style={{marginLeft: '15px', marginRight: '15px'}}>
        Sei sicuro di voler procedere con l'eliminazione dell' utente selezionato?
      </ModalBody>
      <ModalFooter>
        <Button className="btn-form-secondary" onClick={() => {
          props.closeModal()
        }}>{dictionaryGet(dictionary, "global.console.cancel")}</Button>
        <Button className="btn-form-primary" color="primary" onClick={() => {
          props.delete();
        }}>{dictionaryGet(dictionary, "global.console.authorizations.delete")}</Button>
      </ModalFooter>
    </Modal>
  );
}
