export const Actions = {
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_ERROR: "LOGIN_ERROR",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGIN_TSID_SUCCESS: "LOGIN_TSID_SUCCESS",
  LOGOUT: 'LOGOUT',
  CURRENT_SUCCESS: 'CURRENT_SUCCESS',
  CURRENT_FAILURE: 'CURRENT_FAILURE',
  SEARCH_INVITATIONS_SUCCESS: 'SEARCH_INVITATIONS_SUCCESS',
  SEARCH_INVITATIONS_FAILURE: 'SEARCH_INVITATIONS_FAILURE',
  HAS_INVITATIONS_SUCCESS: "HAS_INVITATIONS_SUCCESS",
  HAS_INVITATIONS_FAILURE: "HAS_INVITATIONS_FAILURE",
  COUNT_INVITATIONS_SUCCESS: 'COUNT_INVITATIONS_SUCCESS',
  COUNT_INVITATIONS_FAILURE: 'COUNT_INVITATIONS_FAILURE',
  CREATE_INVITATION_SUCCESS: 'CREATE_INVITATION_SUCCESS',
  CREATE_INVITATION_FAILURE: 'CREATE_INVITATION_FAILURE',
  GET_LICENSE_SUCCESS: 'GET_LICENSE_SUCCESS',
  GET_LICENSE_FAILURE: 'GET_LICENSE_FAILURE',
  DELETE_INVITATION_SUCCESS: 'DELETE_INVITATION_SUCCESS',
  DELETE_INVITATION_FAILURE: 'DELETE_INVITATION_FAILURE',
  SEND_INVITATION_SUCCESS: 'SEND_INVITATION_SUCCESS',
  SEND_INVITATION_FAILURE: 'SEND_INVITATION_FAILURE',
  UNDO_INVITATION_SUCCESS: 'UNDO_INVITATION_SUCCESS',
  UNDO_INVITATION_FAILURE: 'UNDO_INVITATION_FAILURE',
  FETCH_SERVICES_LIST_SUCCESS: 'FETCH_SERVICES_LIST_SUCCESS',
  FETCH_SERVICES_LIST_FAILURE: 'FETCH_SERVICES_LIST_FAILURE',
  USER_SEARCH_SUCCESS: 'USER_SEARCH_SUCCESS',
  USER_SEARCH_FAILURE: 'USER_SEARCH_FAILURE',
  CREATE_AUTHGROUP_SUCCESS: 'CREATE_AUTHGROUP_SUCCESS',
  CREATE_AUTHGROUP_FAILURE: 'CREATE_AUTHGROUP_FAILURE',
  DELETE_AUTHGROUP_SUCCESS: 'DELETE_AUTHGROUP_SUCCESS',
  DELETE_AUTHGROUP_FAILURE: 'DELETE_AUTHGROUP_FAILURE',
  AUTHGROUP_ADD_USER_SUCCESS: 'AUTHGROUP_ADD_USER_SUCCESS',
  AUTHGROUP_ADD_USER_FAILURE: 'AUTHGROUP_ADD_USER_FAILURE',
  AUTHGROUP_MOVE_USER_SUCCESS: 'AUTHGROUP_MOVE_USER_SUCCESS',
  AUTHGROUP_MOVE_USER_FAILURE: 'AUTHGROUP_MOVE_USER_FAILURE',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  DELETE_USER_FAILURE: 'DELETE_USER_FAILURE',
  AUTHGROUP_HIERARCHY_SUCCESS: 'AUTHGROUP_HIERARCHY_SUCCESS',
  AUTHGROUP_HIERARCHY_FAILURE: 'AUTHGROUP_HIERARCHY_FAILURE',
  GET_OBJECT_CATALOG_SUCCESS: 'GET_OBJECT_CATALOG_SUCCESS',
  GET_OBJECT_CATALOG_FAILURE: 'GET_OBJECT_CATALOG_FAILURE',
  GET_COMPANY_AUTHORIZATIONS_SUCCESS: 'GET_COMPANY_AUTHORIZATIONS_SUCCESS',
  GET_COMPANY_AUTHORIZATIONS_FAILURE: 'GET_COMPANY_AUTHORIZATIONS_FAILURE',
  GET_NODE_AUTHORIZATIONS_SUCCESS: 'GET_NODE_AUTHORIZATIONS_SUCCESS',
  GET_NODE_AUTHORIZATIONS_FAILURE: 'GET_NODE_AUTHORIZATIONS_FAILURE',
  CHANGE_COMPANY_AUTHORIZATIONS_SUCCESS: 'CHANGE_COMPANY_AUTHORIZATIONS_SUCCESS',
  CHANGE_COMPANY_AUTHORIZATIONS_FAILURE: 'CHANGE_COMPANY_AUTHORIZATIONS_FAILURE',
  DELETE_USER_FROM_GROUP_SUCCESS: 'DELETE_USER_FROM_GROUP_SUCCESS',
  DELETE_USER_FROM_GROUP_FAILURE: 'DELETE_USER_FROM_GROUP_FAILURE',
  UPDATE_GROUP_SUCCESS: 'UPDATE_GROUP_SUCCESS',
  UPDATE_GROUP_FAILURE: 'UPDATE_GROUP_FAILURE',
  GET_USERS_TO_ADD_SUCCESS: "GET_USERS_TO_ADD_SUCCESS",
  GET_USERS_TO_ADD_FAILURE: "GET_USERS_TO_ADD_FAILURE",
  USER_COUNT_FAILURE: "USER_COUNT_FAILURE",
  USER_COUNT_SUCCESS: "USER_COUNT_SUCCESS",
  SWITCH_SELECTED_COMPANY: "SWITCH_SELECTED_COMPANY",
  CHANGE_PRINCIPAL_AUTH_BATCH_SUCCESS: "CHANGE_PRINCIPAL_AUTH_BATCH_SUCCESS",
  CHANGE_PRINCIPAL_AUTH_BATCH_FAILURE: "CHANGE_PRINCIPAL_AUTH_BATCH_FAILURE",
  SET_CURRENT_SERVICE: "SET_CURRENT_SERVICE",
  RESET_SERVICES_LIST: "RESET_SERVICES_LIST",
  FETCH_DICTIONARY: "FETCH_DICTIONARY",
  USER_LANGUAGE_SWITCH: "USER_LANGUAGE_SWITCH",
  SET_ERRORE: "SET_ERRORE",
  TOKEN_CHECK: "TOKEN_CHECK"
};
