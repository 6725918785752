import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { adoptInvitationToken } from '../../actions/invitations/actions';
import { simpleTokenCheck, userCurrent } from '../../actions/user/actions';
import { OID_CONFIG } from '../../common/constants';
import { removeIdTokenCookie, removeAccessTokenCookie, getIdTokenCookie, getParameterByName, getAccessTokenCookie } from '../../common/functions';
import { endSession } from '../../common/oauth';

type Props = {
  match: {
    isExact: boolean
    params: any
    path: string
    url: string
  },
  location: {
    hash: string,
    pathname: string,
    search: string,
    state: any
  }
}

export default function InvitationAccept(props: Props) {
  const dispatch = useDispatch();
  const [ userData, setUserData ] = useState<any>(null);

  useEffect(() => {
    simpleTokenCheck().then((r:any)=> {
      if (r.data!=null) {
        
        setUserData(r.data.data);
      }
    });
  }, []);

  const postTsidLoginUserInit = (tk:any) => {
    dispatch(userCurrent());
    window.location.href = '/#/dashboard';
  };

  return (
    <div className="" id="invitation-accept">
        <div className="div-img-wip">
            <img className="img-wip" src=".\images\LOGO_TS.png" />
            <p>Attiva la soluzione per il TeamSystem ID attualmente in uso:</p>
            <button onClick={ () => {
              adoptInvitationToken(getAccessTokenCookie(), getParameterByName("invtoken")).then((res) => {
                console.log("RESPONSE USE INVITATION:", res);
                postTsidLoginUserInit(getAccessTokenCookie());
              }).catch((err:any) => {
                console.log("ERRORE USE INVITATION:", err);
                window.location.href = '/#/error-page?error_code=not_valid_invitation';
              });
            }}>Attiva per { userData!=null?userData.email:"" }</button>
            <p className="mid-line"><span>OPPURE</span></p>
            <button onClick={ () => {
                // Nuova modalità TSIDv3
                let idToken = getIdTokenCookie();
                removeIdTokenCookie();
                removeAccessTokenCookie();
                let tsidState:any = {};
                tsidState["invToken"] = getParameterByName("invtoken");
                let redStr = OID_CONFIG.redirect_uri;
                endSession(idToken, encodeURIComponent(redStr), tsidState);
            } } className="secondary">Attiva per altro TeamySystem ID </button>
            <p className="p-top-20">In questo caso, verrà fatto il <strong>logout</strong> dal TeamSystem ID attualmente in uso e ti chiederemo di accedere con quello per cui vuoi attivare TeamSystem Analytics.</p>
        </div>
        
    </div>
  )
}
