import React, { useState, useEffect } from 'react';
import { TabContent, TabPane, Spinner } from 'reactstrap';
import InvitiPage from './InvitiPage';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../store';
import ServiceNav from '../../components/Navigation/ServiceNav';
import { fetchServicesList, setCurrentService, resetServiceList } from '../../actions/user/actions';
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";

export default function InvitiContainer(): JSX.Element {
  const selectedCompany = useSelector((state: AppState) => state.user.current.selectedCompany);
  const currentService = useSelector((state: AppState) => state.user.currentService);
  const thunkDispatch = useDispatch<ThunkDispatch<AppState, any, AnyAction>>();
  const [isLoading, setIsLoading] = useState(false);

  const changeService = (tab: number) => {
    thunkDispatch(setCurrentService(tab));
  };

  const renderNavTabs = () => {
    if (selectedCompany != null) {
      return (
        <ServiceNav activeTab={currentService} changeService={changeService} selectedCompany={selectedCompany} />
      );
    }
  };

  useEffect(() => {
    if (selectedCompany !== null) {
      if (selectedCompany.service === null) {
        if (selectedCompany.isOwner) {
          setIsLoading(true);
          thunkDispatch(resetServiceList());
          thunkDispatch(fetchServicesList(selectedCompany.company.id)).then(() => {
            setIsLoading(false);
          });
        }
      }
    }
  }, [selectedCompany]);

  return (
    <div id="page-gestione-utenti">
      {renderNavTabs()}
      <TabContent activeTab={currentService} style={{ borderRadius: '0 5px 5px 5px' }}>
        <TabPane tabId={currentService}>
          {isLoading
            ? <div style={{ height: "auto", display: "block", padding: "20%", textAlign: "center" }}><Spinner /></div>
            : selectedCompany !== null ? selectedCompany.service !== null ? selectedCompany.service.length > 0 ? <InvitiPage selectedService={selectedCompany.service[currentService]} selectedCompany={selectedCompany} /> : '' : '' : ''}
        </TabPane>
      </TabContent>
    </div>
  );
}
