import { Actions } from '../../actions/index';
import { IUserState, ICompanies, ICompany, IService, ISelectedCompany } from './types';
import { TUserActions } from '../../actions/user/types';
import { setAccessTokenCookie, setRefreshTokenCookie } from '../../common/functions';
import { USER_TYPE, USER_TYPE_AS_API } from '../../common/constants';

const initialState: IUserState = {
  login: {
    isLoggedIn: false,
    isError: false,
    errorMsg: '',
  },
  current: {
    errorMsg: '',
    isError: false,
    isLoaded: false,
    user: null,
    companies: [],
    selectedCompany: null,
    language: ''
  },
  list: {
    errorMsg: '',
    isError: false,
    isLoaded: false,
    data: []
  },
  currentService: 0
};

export function userReducer(state = initialState, action: TUserActions): IUserState {
  switch (action.type) {
    case Actions.LOGIN_SUCCESS:
      setAccessTokenCookie(action.payload.token);
      setRefreshTokenCookie(action.payload.refreshToken);
      return Object.assign({}, state, {
        login: {
          isLoggedIn: true,
          isError: false,
          errorMsg: '',
        }
      });
    case Actions.LOGIN_ERROR:
      let message = "";
      if (action.payload.data === undefined) {
        message = "Errore generico";
      } else {
        message = action.payload.data.error.errorMsg;
      }
      return Object.assign({}, state, {
        login: {
          isLoggedIn: false,
          isError: true,
          errorMsg: message
        }
      });

    case Actions.LOGIN_FAILURE:
      let failureMessage = "";

      // if (action.payload.data === undefined) {
      // message = "Errore generico";
      // } else {
      failureMessage = action.payload.error.errorMsg;
      // }
      return Object.assign({}, state, {
        login: {
          isLoggedIn: false,
          isError: true,
          errorMsg: failureMessage
        }
      });

    case Actions.CURRENT_SUCCESS:
      let selCompany: any = {};
      let companies: ISelectedCompany[] = [];
      let mergedCompany: any = {};

      action.payload.data.companies.filter((uc: ICompanies) => uc.isOwner || uc.userType === USER_TYPE_AS_API.ADMINISTRATOR).forEach((uc: ICompanies) => {
        let index: any = companies.findIndex(c => c.company.id === uc.company.id);
        if (index < 0) {
          let company: any = {
            company: uc.company,
            isDefault: uc.isDefault,
            isOwner: uc.isOwner,
            service: uc.service ? [uc.service] : [],
            userType: USER_TYPE_AS_API.ADMINISTRATOR
          };
          companies.push(company);
        } else {
          let company: any = companies[index];
          if (!company.isOwner) {
            company.isOwner = uc.isOwner;
          }
          if (!uc.isDefault) {
            company.isDefault = uc.isDefault;
          }
          if (company.service && !company.service.find((s: IService) => s.id === uc.service.id)) {
            company.service.push(uc.service);
          }
          companies[index] = company;
        }
      });
      return Object.assign({}, state, {
        ...state.login,
        current: {
          isLoaded: true,
          isError: false,
          errorMsg: '',
          companies: companies,
          user: action.payload.data.user,
          language: action.payload.data.user.language,
          selectedCompany: companies[0]
        }
      });
    case Actions.CURRENT_FAILURE:
      return Object.assign({}, state, {
        isLoggedIn: false,
        isLoaded: false,
        isError: true,
        errorMsg: action.payload.data.error.errorMsg,
        user: {}
      });
    case Actions.SWITCH_SELECTED_COMPANY:
      let cCurrent = { ...state.current };
      let newSelComp = null;
      if (cCurrent.selectedCompany !== null) {
        cCurrent.companies.map((comp: ICompanies) => {
          if (comp.company.id === action.payload) {
            newSelComp = comp;
          }
        });
      }
      cCurrent.selectedCompany = newSelComp;
      return Object.assign({}, state, {
        ...state.login,
        ...state.list,
        current: cCurrent
      });
    case Actions.FETCH_SERVICES_LIST_SUCCESS:
      let clonedCurrent = { ...state.current };
      if (clonedCurrent.selectedCompany !== null) {
        clonedCurrent.selectedCompany.service = action.payload.data;
      }

      return Object.assign({}, state, {
        ...state.login,
        current: clonedCurrent
      });

    case Actions.USER_SEARCH_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        list: {
          isLoaded: true,
          isError: false,
          errorMsg: '',
          data: [...action.payload.data]
        }
      });

    case Actions.USER_SEARCH_FAILURE:
      return Object.assign({}, state, {
        ...state,
        list: {
          isLoaded: false,
          isError: true,
          errorMsg: '',
          data: []
        }
      });

    case Actions.LOGOUT: {

    }

    case Actions.SET_CURRENT_SERVICE: {
      return Object.assign({}, state, {
        ...state,
        currentService: action.payload
      });
    }

    case Actions.USER_LANGUAGE_SWITCH:
      let langCurrent = { ...state.current };
      langCurrent.language = action.payload;
      return Object.assign({}, state, {
        ...state.login,
        ...state.list,
        current: langCurrent
      });

    default:
      return state;
  }
}
