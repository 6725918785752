import React, { useState } from "react";
import { TabContent, TabPane } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../store";
import ServiceNav from "../../components/Navigation/ServiceNav";
import VerificaAuthPage from "./VerificaAuthPage";
import { setCurrentService } from "../../actions/user/actions";

export default function VerficaAuth(): JSX.Element {
  const selectedCompany = useSelector((state: AppState) => state.user.current.selectedCompany);
  const currentService = useSelector((state: AppState) => state.user.currentService);
  const dispatch = useDispatch();

  const changeService = (tab: number) => {
    dispatch(setCurrentService(tab));
  };

  const renderNavTabs = () => {
    if (selectedCompany != null) {
      return (
        <ServiceNav activeTab={currentService} changeService={changeService} selectedCompany={selectedCompany} />
      );
    }
  };

  return (
    <div id="page-gestione-utenti">
      {renderNavTabs()}
      <TabContent activeTab={currentService} style={{ borderRadius: '0 5px 5px 5px' }}>
        <TabPane tabId={currentService}>
          {selectedCompany !== null ? selectedCompany.service !== null ? <VerificaAuthPage selectedService={selectedCompany.service[currentService]} selectedCompany={selectedCompany} /> : '' : ''}
        </TabPane>
      </TabContent>
    </div>
  );
}
