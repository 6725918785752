import {
  faEllipsisH,
  faFilter,
  faSearch,
  faSort,
  faSortDown,
  faSortUp,
  faTrashAlt,
  faEdit
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Switch from 'react-switch';
import {
  Alert,
  Button,
  ButtonDropdown,
  Col,
  CustomInput,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Table,
  UncontrolledButtonDropdown
} from 'reactstrap';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { getLicenses } from '../../actions/invitations/actions';
import { deleteUser, updateUser, userSearch, userCount, canActivateOwner } from '../../actions/user/actions';
import { ORDER_BY, ORDER_DIR, USER_TYPE, USER_TYPE_AS_API } from '../../common/constants';
import ModaleModifica from '../../components/Utenti/ModaleModifica';
import { AppState } from '../../store';
import { IInvitation, ILicense } from '../../store/invitations/types';
import { ISelectedCompany, IService } from '../../store/user/types';
import ModaleElimina from '../../components/Utenti/ModaleElimina';
import ActivateLicensesDialog from '../../components/Home/ActivateLicensesDialog';
import { dictionaryGet } from '../../common/functions';

type Props = {
  selectedService: IService,
  selectedCompany: ISelectedCompany
}

export default function InvitiPage(props: Props): JSX.Element {
  const users = useSelector((state: AppState) => state.user.list);
  const user = useSelector((state: AppState) => state.user.current);
  const [orderFiltersArray, setOrderFiltersArray] = useState<Array<number>>([0, 0, 0]);
  const thunkDispatch = useDispatch<ThunkDispatch<AppState, any, AnyAction>>();
  const licenses = useSelector((state: AppState) => state.invitations.licenses);
  const [usedLicenseDropdown, setusedLicenseDropdown] = useState<boolean>(false);
  const [availableLicensesDropdown, setAvailableLicensesDropdown] = useState<boolean>(false);
  const [solutionsFilterArray, setSolutionsFilterArray] = useState<Array<any>>([]);
  const [userTypeFilterArray, setUserTypeFilterArray] = useState<Array<any>>([
    { value: false, label: 'ADMINISTRATOR' },
    { value: false, label: 'USER' }]);
  const [queryString, setQueryString] = useState<string>('');
  const [typeFilter, setTypeFilter] = useState<string>('');
  const [solutionFilter, setSolutionsFilter] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');
  const [dropSoluzioni, setDropSoluzioni] = useState<boolean>(false);
  const [dropTipo, setDropTipo] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [editUser, setEditUser] = useState();
  const [checkArray, setCheckArray] = useState<Array<boolean>>([false]);
  const [userIDToDelete, setUserIDToDelete] = useState<Number>();
  const [isEliminaModalOpen, setEliminaModal] = useState<boolean>(false);
  const [activationAlert, setActivationAlert] = useState<any>({ isOpen: false, date: null });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [pagFirstIndex, setPagFirstIndex] = useState<number>(0);
  const [pagLastIndex, setPagLastIndex] = useState<number>(0);
  const dictionary = useSelector((state: AppState) => state.dictionary);

  useEffect(() => {
    if (user.selectedCompany !== null && props.selectedService !== null) {
      onEdit();
      setCurrentPage(1);
    }
  }, [props.selectedService]);

  const onEdit = () => {
    if (user.selectedCompany !== null && props.selectedService !== null) {
      thunkDispatch(getLicenses(props.selectedService.code, user.selectedCompany.company.id));
      thunkDispatch(userCount(props.selectedService.id, user.selectedCompany.company.id, currentPage, queryString)).then((res: any) => {
        calculatePagesFromCount(res.payload.data);
      });
      thunkDispatch(userSearch(props.selectedService.id, user.selectedCompany.company.id, currentPage));
    }
  };

  const calculatePagesFromCount = (count: number) => {
    let totalePagine = Math.ceil(count / 10);
    setTotalPages(totalePagine);
    setPagLastIndex(10);
  };

  useEffect(() => {
    if (user.selectedCompany !== null && props.selectedService !== null) {
      onEdit();
    }
  }, [currentPage]);

  useEffect(() => {
    applyFilters(0);
  }, [orderFiltersArray]);

  useEffect(() => {
    // if (queryString !== '') {
    if (user.selectedCompany !== null && props.selectedService !== null) {
      thunkDispatch(userCount(props.selectedService.id, user.selectedCompany.company.id, currentPage, queryString)).then((res: any) => {
        calculatePagesFromCount(res.payload.data);
      });
      thunkDispatch(userSearch(props.selectedService.id, user.selectedCompany.company.id, currentPage, queryString));
      // }
    }
  }, [queryString]);

  const paginaSelezionata = (i: any) => {
    let pagFindex = pagFirstIndex;
    let pagLindex = pagLastIndex;

    if (i === 'prev') {
      if (currentPage === 1) {
        i = 1;
      } else {
        i = currentPage - 1;
        if (i < pagFirstIndex) {
          pagFindex = pagFindex - 1;
          pagLindex = pagLindex - 1;
        }
      }
    }

    if (i === 'next') {
      if (currentPage === totalPages) {
        i = totalPages;
      } else {
        i = currentPage + 1;
        if (i >= pagLastIndex) {
          pagFindex = pagFindex + 1;
          pagLindex = pagLindex + 1;
        }
      }
    }

    let fIndex = (i * 10);
    let lIndex = fIndex + 9;

    setCurrentPage(i);
    setPagFirstIndex(pagFindex);
    setPagLastIndex(pagLindex);
    renderPagination();
  };

  const pagination = () => {
    let buttons = [];
    if (totalPages == 1) {
      buttons.push(<button className={'btn-pagine-active'} key={1} id={'btn' + 1} onClick={() => {
        paginaSelezionata(1);
      }}>{1}</button>);
    } else {
      for (let i = 0; i < totalPages; i++) {
        buttons.push(
          <button className={currentPage === i + 1 ? 'btn-pagine-active' : 'btn-pagine'} key={i} id={'btn' + i}
            onClick={() => {
              paginaSelezionata(i + 1);
            }}>{i + 1}</button>
        );
      }
    }
    return buttons;
  };

  const renderPagination = () => {
    let buttons = pagination();
    let serie = [];
    for (let i = pagFirstIndex; i < pagLastIndex; i++) {
      serie.push(buttons[i]);
    }
    return serie;
  };

  const renderAvailableLicenseDropdown = () => {
    if (licenses.data !== null) {
      let dropItems = licenses.data.map((lice, i) => {
        return (
          <React.Fragment key={i}>
            <DropdownItem><span>{lice.product.name}</span><span
              style={{ color: 'rgb(119, 188, 92)', marginLeft: "30px" }}><b>{lice.totalLicences}</b></span></DropdownItem>
          </React.Fragment>
        );
      });
      return (
        <ButtonDropdown className="dropdown-licenze" isOpen={availableLicensesDropdown} toggle={() => {
          setAvailableLicensesDropdown(!availableLicensesDropdown)
        }}>
          <DropdownToggle>
            <FontAwesomeIcon icon={faEllipsisH} />
          </DropdownToggle>
          <DropdownMenu bottom right>
            {dropItems}
          </DropdownMenu>
        </ButtonDropdown>
      );
    }
  };

  const renderUsedLicensesDropdown = () => {
    if (licenses.data !== null) {
      let dropItems = licenses.data.map((lice, i) => {
        return (
          <React.Fragment key={i}>
            <DropdownItem key={i}><span>{lice.product.name}</span><span style={{
              color: 'rgb(218, 88, 72)',
              marginLeft: "30px"
            }}><b>{lice.totalLicences - lice.tempBalance}</b></span></DropdownItem>
          </React.Fragment>
        );
      });
      return (
        <ButtonDropdown className="dropdown-licenze" isOpen={usedLicenseDropdown} toggle={() => {
          setusedLicenseDropdown(!usedLicenseDropdown)
        }}>
          <DropdownToggle>
            <FontAwesomeIcon icon={faEllipsisH} />
          </DropdownToggle>
          <DropdownMenu bottom right>
            {dropItems}
          </DropdownMenu>
        </ButtonDropdown>
      );
    }
  };

  const renderFilter = (idx: number) => {
    switch (orderFiltersArray[idx]) {
      case 0:
        return faSort;
      case 1:
        return faSortDown;
      case 2:
        return faSortUp;
      default:
        return faSort;
    }
  };

  const infoLicenze = () => {
    let avail = 0;
    let used = 0;
    if (licenses.data !== null) {
      licenses.data.map((license: ILicense) => {
        avail = avail + license.totalLicences;
        used = avail - license.tempBalance;
      });
    }
    const totaleSpan: JSX.Element = <span style={{ color: 'rgb(119, 188, 92)' }}><b>{avail}</b></span>;
    const usedSpan: JSX.Element = <span style={{ color: 'rgb(218, 88, 72)' }}><b>{used}</b></span>;
    return (
      <Row className="riga-licenze">
        <Col>
          <div className="div-licenze">
            <strong>{ dictionaryGet(dictionary, "global.console.invitations.total_licenses") }</strong>
            <span className="div-numero-licenze">
              {totaleSpan}
              {renderAvailableLicenseDropdown()}
            </span>
          </div>
        </Col>
        <Col>
          <div className="div-licenze">
            <strong>{ dictionaryGet(dictionary, "global.console.invitations.used_licenses") }</strong>
            <span className="div-numero-licenze">
              {usedSpan}
              {renderUsedLicensesDropdown()}
            </span>
          </div>
        </Col>
      </Row>
    );
  };

  const productsTD = (invito: IInvitation) => {
    if (invito.license.length > 1) {
      const dropItems = () => {
        for (let i = 1; i < invito.license.length; i++) {
          const lice = invito.license[i];
          return (
            <React.Fragment key={i}>
              <DropdownItem className="drop-item-solutions">
                <span className="single-tag-blue">{lice.name}</span>
              </DropdownItem>
            </React.Fragment>
          );
        }
      };

      return (
        <>
          <span className="single-tag-blue">{invito.license[0].name}</span>
          <UncontrolledButtonDropdown direction="right">
            <DropdownToggle className="more-solutions">
              <FontAwesomeIcon icon={faEllipsisH} />
            </DropdownToggle>
            <DropdownMenu className="dropmenu-filtro">
              <DropdownItem className="drop-item-solutions">Altre</DropdownItem>
              {dropItems()}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </>
      );
    } else {
      if (invito.license.length > 0) {
        return <span className="single-tag-blue">{invito.license[0].name}</span>;
      }
    }
  };

  const userType = (userType: string, isOwner: Boolean) => {
    let span: JSX.Element = <span className="single-tag-violet">{ dictionaryGet(dictionary, "global.console.owner") }</span>;
    if (isOwner) {
      return span;
    } else {
      if (userType === USER_TYPE_AS_API.ADMINISTRATOR) {
        return <span className="single-tag-red">{USER_TYPE.ADMINISTRATOR}</span>
      } else {
        return '';
      }
    }
  };


  const renderDeleteUserButton = (u:any) => {
    if (!u.isOwner && (user.user!=null && u.email!=user.user.teamsystemId)) {
      return <Button onClick={() => {
        setUserIDToDelete(u.id);
        setEliminaModal(true);
      }} className="btn-secondario-danger" type="button"><FontAwesomeIcon icon={faTrashAlt} /></Button>
    }
  };

  const renderBody = () => {
    if (users.data.length > 0) {
      return users.data.map((utente, i) => {
        return (
          <tr key={i}>
            <td>{utente.firstName}</td>
            <td>{utente.lastName}</td>
            <td>{utente.email}</td>
            <td style={{ textAlign: "center" }}>{userType(utente.userType, utente.isOwner)}</td>
            <td>{productsTD(utente)}</td>
            <td>
              <Switch
                className="switch-component-table"
                height={20}
                width={40}
                disabled={utente.isOwner}
                onColor={'#415ca3'}
                checked={utente.active}
                onChange={(checked, evt, id) => {
                  activateUser(checked, utente.id, utente.userType)
                }}
              />
            </td>
            <td ><Button className="btn-modifica" onClick={() => {
              setEditUser(utente);
              setOpenEditModal(true);
            }} style={{ marginRight: "5px" }} type="button"><FontAwesomeIcon icon={faEdit} /></Button>
            { renderDeleteUserButton(utente) }
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <th colSpan={8} style={{ textAlign: "center" }}>{ dictionaryGet(dictionary, "global.console.users_management.no_users") }</th>
        </tr>
      );
    }
  };

  const applyFilters = (idx: number) => {
    let stringPayload = queryString;
    let esito = orderFiltersArray.some((val) => {
      return val > 0
    });
    let usTypeFilter = '';
    let solFilter = '';
    let orderFilter = '';
    let typeOrderFilter = '';
    let search = '';

    userTypeFilterArray.map((val) => {
      if (val.value) {
        usTypeFilter = '&userType=' + val.label
      }
    });

    setTypeFilter(typeFilter);

    if (searchText.length > 1) {
      search = '&name=' + searchText;
    }

    solutionsFilterArray.map((solution) => {
      if (solution.value) {
        solFilter = '&license=' + solution.label
      }
    });

    if (esito) {
      orderFiltersArray.map((val, i) => {
        if (val > 0) {
          if (val === 1) {
            orderFilter = '&orderDir=' + ORDER_DIR[0];
            typeOrderFilter = '&orderBy=' + ORDER_BY[i]
          }
          if (val === 2) {
            orderFilter = '&orderDir=' + ORDER_DIR[1];
            typeOrderFilter = '&orderBy=' + ORDER_BY[i];
          }
        }
      });
    }

    stringPayload = usTypeFilter + solFilter + typeOrderFilter + orderFilter + search;

    setSolutionsFilter(solFilter);
    setQueryString(stringPayload);

    if (idx === 1) {
      setDropTipo(!dropTipo)
    }
    if (idx === 2) {
      setDropSoluzioni(!dropSoluzioni)
    }
  };

  const cercaUtente = () => {
    applyFilters(0);
  };

  const resetAllFilters = () => {
    let orderClone = [...orderFiltersArray];
    orderClone.fill(0);
    setUserTypeFilterArray([{ value: false, label: 'ADMINISTRATOR' }, { value: false, label: 'USER' }]);
    setOrderFiltersArray(orderClone);
    setSearchText('');
    setQueryString('');
  };

  const InterfacciaRicerca = () => {
    return (
      <Row className="riga-utenti">
        <Col style={{ display: 'inline-flex', padding: "10px" }} xs="8" md="8" lg="7" xl="8">
          <Col md="10" lg="8" style={{ padding: "0px", maxWidth: "400px" }}>
            <Input value={searchText} className="input-cerca-inviti" name="nominativo" id="nominativo"
              placeholder={ dictionaryGet(dictionary, "global.console.invitations.search") } onChange={(e) => {
                setSearchText(e.target.value);
              }} />
          </Col>
          <Col md="1" lg="2" style={{ padding: "0px", maxWidth: "80px" }}>
            <Button className="btn-form-blue-cerca" color="primary" size="md"
              onClick={() => cercaUtente()}><FontAwesomeIcon icon={faSearch} className="font-1xl" /></Button>
          </Col>
        </Col>
        <Col md="4" xs="4" lg="4" xl="4" style={{ padding: "15px" }}>
          <div className="div-btn-right">
            <Button className="btn-form-secondary interfaccia" color="secondary" size="md" onClick={() => {
              resetAllFilters()
            }}>{ dictionaryGet(dictionary, "global.console.invitations.remove_filters") }</Button>
          </div>
        </Col>
      </Row>
    );
  };

  const toggleDrop = (e: any, idx: number) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    if (idx === 1) {
      setDropSoluzioni(!dropSoluzioni);
      setDropTipo(false);
    }
    if (idx === 2) {
      setDropSoluzioni(false);
      setDropTipo(!dropTipo);
    }
  };

  const resetTypeFilters = () => {
    let clone = [...userTypeFilterArray];
    clone.map((res) => {
      res.value = false;
    });
    let cloneSolutions = [...solutionsFilterArray];
    cloneSolutions.map((sol) => {
      sol.value = false;
    });
    setSolutionsFilter('');
    setSolutionsFilterArray(cloneSolutions);
    setTypeFilter('');
    setUserTypeFilterArray(clone);
    setOrderFiltersArray([0, 0, 0]);
    applyFilters(1);
  };

  const dropDownTipo = () => {
    return (
      <ButtonDropdown toggle={() => {
      }} isOpen={dropTipo} className="dropdown-filtro">
        <DropdownToggle onClick={(e) => {
          toggleDrop(e, 2)
        }}>
          <FontAwesomeIcon icon={faFilter} />
        </DropdownToggle>
        <DropdownMenu className="dropmenu-filtro">
          {userTypeFilterArray.length > 0 ?
            userTypeFilterArray.map((usrType, i) => {
              return (
                <DropdownItem key={i} className="drop-item">
                  <div className="item-filtro">
                    <span>
                      <CustomInput checked={usrType.value} value={usrType.label} type="radio" id={"check-user" + i}
                        onChange={(e) => {
                          handleChangeFiltro(e, i, 1)
                        }} />
                    </span>
                    <span className="opzione-filtro">{USER_TYPE[usrType.label]}</span>
                  </div>
                </DropdownItem>
              );
            }) : ''}
          <div className="drop-menu-controls">
            <Button className="secondary" onClick={() => {
              applyFilters(1)
            }}>Ok</Button>
            <Button className="secondary" onClick={() => {
              resetTypeFilters()
            }}>Reset</Button>
          </div>
        </DropdownMenu>
      </ButtonDropdown>
    );
  };

  const setOrderFilter = (idx: number) => {
    let clone = [...orderFiltersArray];
    switch (clone[idx]) {
      case 0:
        clone.fill(0);
        clone[idx] = 1;
        break;
      case 1:
        clone.fill(0);
        clone[idx] = 2;
        break;
      case 2:
        clone.fill(0);
        clone[idx] = 0;
        break;
    }
    setOrderFiltersArray(clone);
  };

  const handleChangeFiltro = (e: any, i: number, idx: number) => {
    e.nativeEvent.stopImmediatePropagation();
    e.preventDefault();
    e.stopPropagation();
    if (idx === 1) {
      let array = [...userTypeFilterArray];
      array.map((val) => {
        val.value = false;
      });
      array[i].value = !array[i].value;
      setUserTypeFilterArray(array);
    }
    if (idx === 2) {
      let array = [...solutionsFilterArray];
      array[i].value = !array[i].value;
      setSolutionsFilterArray(array);
    }
  };

  const handleChange = (e: any, i: any) => {
    let arrayCopia: boolean[] = [...checkArray];
    arrayCopia[i] = !arrayCopia[i];
    setCheckArray(arrayCopia);
  };

  const TableHeader = (): JSX.Element => {
    return (
      <thead>
        <tr>
          <th><span>{ dictionaryGet(dictionary, "global.console.invitations.first_name") }</span></th>
          <th><span>{ dictionaryGet(dictionary, "global.console.invitations.last_name") }</span><span onClick={() => {
            setOrderFilter(0)
          }}><FontAwesomeIcon className="icona-filtro" icon={renderFilter(0)} /></span></th>
          <th><span>Email</span><span onClick={() => {
            setOrderFilter(1)
          }}><FontAwesomeIcon className="icona-filtro" icon={renderFilter(1)} /></span></th>
          <th style={{ textAlign: "center" }}><span>{ dictionaryGet(dictionary, "global.console.invitations.type") }</span>{dropDownTipo()}</th>
          <th><span>{ dictionaryGet(dictionary, "global.console.invitations.solutions") }</span></th>
          <th><span>{ dictionaryGet(dictionary, "global.console.active") }</span></th>
          <th />
        </tr>
      </thead>
    );
  };

  const renderTable = (): JSX.Element => {
    let paginazione = <Col className="text-right col-12">
      <button className="btn-pagine-left" key={'prev'} id="prev" onClick={() => {
        paginaSelezionata('prev');
      }}>{'<'}</button>
      {renderPagination()}
      <button className="btn-pagine-right" key={'next'} id="next" onClick={() => {
        paginaSelezionata('next');
      }}>{'>'}</button>
    </Col>;
    return (
      <>
        <Table>
          <TableHeader />
          <tbody>
            {renderBody()}
          </tbody>
        </Table>
        {paginazione}
      </>
    );
  };

  const chiudiModale = () => {
    setOpenEditModal(false);
    onEdit();
  };

  const chiudiModaleElimina = () => {
    setEliminaModal(false);
  };

  const eliminaUtente = () => {
    if (userIDToDelete !== undefined) {
      thunkDispatch(deleteUser(userIDToDelete)).then(() => {
        onEdit();
        chiudiModaleElimina();
      })
    }
  };

  const activateUser = (stato: boolean, id: number, userType: String) => {
    thunkDispatch(updateUser(id, stato, userType)).then((res: any) => {
      if (res.payload.result === "NOK") {
        let activationAlert = {
          isOpen: true,
          date: res.payload.error.errorMsg
        };
        onEdit();
        setActivationAlert(activationAlert);
        timeOut();
      } else {
        onEdit();
      }
    });
  };

  const timeOut = () => {
    setTimeout(function () {
      let obj = {
        isOpen: false,
        date: null
      };
      setActivationAlert(obj);
    }, 3000);
  };
  return (
    <>
      <div>
        <Alert isOpen={activationAlert.isOpen} color="danger">{activationAlert.date}</Alert>
        <ModaleModifica selectedService={props.selectedService} selectedCompany={props.selectedCompany} editUser={editUser} serviceCode={props.selectedService.code} isModalOpen={openEditModal} closeModal={chiudiModale} />
        <ModaleElimina isModalOpen={isEliminaModalOpen} closeModal={chiudiModaleElimina} delete={eliminaUtente} />
        {infoLicenze()}
        {InterfacciaRicerca()}
        <Row className="riga-console-tabella">
          <Col className="colonna-console-tabella" xs="12" md="12">
            {renderTable()}
          </Col>
        </Row>
      </div>
    </>
  );
};
