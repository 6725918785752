import React, { useState, useEffect } from 'react';
import { Dropdown, DropdownToggle, DropdownItem, DropdownMenu, Popover, PopoverBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff, faUserCircle, faPen } from '@fortawesome/pro-regular-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { removeAccessTokenCookie, removeIdTokenCookie, getIdTokenCookie } from '../../common/functions';
import { AppState } from '../../store';
import { userLogout, fetchDictionary, switchUserLanguage } from '../../actions/user/actions';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { LANGUAGES, getLanguageByCode } from '../../common/languages';
import { endSession } from '../../common/oauth';

export function DropdownUser() {
  const [dropdownOpen, setDropDown] = useState<boolean>(false);
  const [languagePopover, setLanguagePopover] = useState<boolean>(false);
  const current = useSelector((state: AppState) => state.user.current);
  const thunkDispatch = useDispatch<ThunkDispatch<AppState, any, AnyAction>>();
  let wrapperRef: React.RefObject<HTMLInputElement> = React.createRef();
  const dispatch = useDispatch();

  const logOut = () => {
    let idToken = getIdTokenCookie();
    removeIdTokenCookie();
    removeAccessTokenCookie();
    endSession(idToken);
  };

  const handleClickOutside = (event:any) => {
    if (!languagePopover) {
      if (wrapperRef!=null && wrapperRef.current!=null && !wrapperRef.current.contains(event.target)) {
        setDropDown(false);
        setLanguagePopover(false);
      }
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
  });

  const switchLanguage = (lang:any) => {
    dispatch(fetchDictionary(lang.value));
    dispatch(switchUserLanguage(lang.value));
    toggleLanguagePopover();
  }

  const toggleLanguagePopover = () => {
    setLanguagePopover(!languagePopover);
  };

  const getUser = () => {
    let utente: JSX.Element;
    if (current.user !== null) {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        utente = <FontAwesomeIcon icon={faUserCircle} className="font-2xl" style={{ color: '#FFFFFF' }}></FontAwesomeIcon>;
      } else {
        utente = (<div><FontAwesomeIcon icon={faUserCircle} className="font-2xl" style={{ color: '#FFFFFF', fontSize: "24px", marginRight: "10px", verticalAlign: "text-top" }}></FontAwesomeIcon><span style={{ verticalAlign: "-webkit-baseline-middle" }}>{current.user.firstName + ' ' + current.user.lastName}</span></div>);
      }
      return utente;
    }
  };

  let lang = getLanguageByCode(current.language);


  if (current.user !== null) {
    return (
      <div>
        <div ref={wrapperRef} className="user-div">
          <a className="nav-link" onClick={() => {
            setDropDown(!dropdownOpen);
          }}>
            {getUser()}
          </a>
          <div className={ "user-profile-panel " + (dropdownOpen?"show":"") }>
            <div className="user-profile-section" style={{ textAlign: "center", paddingBottom: "20px" }}>
              <p><strong>{current.user.firstName} {current.user.lastName}</strong></p>
              <p className="p-fisso" style={{ fontSize: '12px', color: 'gray' }}></p><p className="p-variabile">{current.user.teamsystemId}</p>
            </div>
            <div className="user-profile-section" style={{ height: "62px", cursor: 'default' }}>
              <span style={{ display:"block", float: "left" }}>Lingua</span>
              <span style={{ display:"block", float: "right" }}><strong>{ (lang!=null?lang.label:"") }</strong>
              <a id="Popover1" onClick={ () => {

              }}><FontAwesomeIcon icon={faPen} style={{ marginLeft: '10px' }} className="font-1xl"></FontAwesomeIcon></a></span>
              <Popover className="language-popover" placement="bottom" isOpen={languagePopover} target="Popover1" toggle={toggleLanguagePopover}>
                <PopoverBody>
                  <ul className="list-group">
                    {
                      LANGUAGES.map((l) => {
                        return <li onClick={() => {
                          switchLanguage(l);
                        }} key={ l.value } className={ "list-group-item" + (l.value==current.language?" active":"") }><a >{ l.label }</a></li>
                      })
                    }
                  </ul>
                </PopoverBody>
              </Popover>
            </div>
            <div className="user-profile-section link" style={{ paddingBottom: "20px" }} onClick={() => { logOut(); }}><FontAwesomeIcon icon={faPowerOff} style={{ marginRight: '10px' }} className="font-1xl"></FontAwesomeIcon>Esci</div>
          </div>
        </div>
      </div>
    );
    /*
    return (
      <div className="user-div">
        <Dropdown nav isOpen={dropdownOpen} toggle={() => { setDropDown(!dropdownOpen); }} className="header-dropdown-user" style={{ float: 'left' }}>
          <DropdownToggle nav>
            {getUser()}
          </DropdownToggle>
          <DropdownMenu >
            <DropdownItem toggle={false}><FontAwesomeIcon icon={faUserCircle} className="font-1xl" style={{ marginRight: '10px' }}></FontAwesomeIcon>{current.user.firstName} {current.user.lastName}</DropdownItem>
            <DropdownItem disabled style={{ cursor: 'default' }}><p className="p-fisso" style={{ fontSize: '12px', color: 'gray' }}>TeamSystem ID</p><p className="p-variabile">{current.user.teamsystemId}</p></DropdownItem>
            <div className="user-profile-section" style={{ height: "62px", cursor: 'default' }}>
              <span style={{ display:"block", float: "left" }}>Lingua</span>
              <span style={{ display:"block", float: "right" }}><strong>{ (lang!=null?lang.label:"") }</strong>
              <a id="Popover1" onClick={ () => {

              }}><FontAwesomeIcon icon={faPen} style={{ marginLeft: '10px' }} className="font-1xl"></FontAwesomeIcon></a></span>
            </div>
            <DropdownItem onClick={() => logOut()}><FontAwesomeIcon icon={faPowerOff} style={{ marginRight: '10px' }} className="font-1xl"></FontAwesomeIcon>Esci</DropdownItem>
          </DropdownMenu>

        </Dropdown>
      </div>
    );
    */
  } else {
    return null;
  }
}
