import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faSearch, faSquare, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faTimesSquare } from '@fortawesome/pro-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Spinner,
  UncontrolledButtonDropdown
} from 'reactstrap';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import {
  changePrincipalAuthorization,
  getCompanyAuthorizations,
  getGroupAuthorizations,
  changePrincipalAuthorizationBatch
} from '../../actions/authgroups/actions';
import { setCurrentService } from '../../actions/user/actions';
import { AppState } from '../../store';
import { IObject } from '../../store/catalog/types';
import ServiceNav from '../Navigation/ServiceNav';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { dictionaryGet } from '../../common/functions';

let viewState;
let setViewState: any;
let node: any;
let setNode: any;

export default function SideView(props: any) {
  const dictionary = useSelector((state: AppState) => state.dictionary);
  [viewState, setViewState] = useState(false);
  [node, setNode] = useState<any>();
  const thunkDispatch = useDispatch<ThunkDispatch<AppState, any, AnyAction>>();
  const [activeTab, setActiveTab] = useState<number>(0);
  const current = useSelector((state: AppState) => state.user.current);
  const catalog = useSelector((state: AppState) => state.catalog.data);
  const auths = useSelector((state: AppState) => state.authorizations.data);
  const [searchText, setSearchText] = useState<string>("");
  const [filteredCatalog, setFilteredCatalog] = useState<any[]>([]);
  const currentService = useSelector((state: AppState) => state.user.currentService);
  const dispatch = useDispatch();

  let isLoading = true;

  const changeService = (tab: number) => {
    //setActiveTab(tab);
    dispatch(setCurrentService(tab));
  };

  useEffect(() => {
    isLoading = false;
  }, []);

  useEffect(() => {
    if (node !== undefined) {
      if (current.selectedCompany !== null) {
        if (node.type === "entryGroup") {
          thunkDispatch(getCompanyAuthorizations(current.selectedCompany.company.id, current.selectedCompany.service[currentService].id, node.simpleId, node.type));
        } else {
          thunkDispatch(getGroupAuthorizations(current.selectedCompany.company.id, current.selectedCompany.service[currentService].id, node.simpleId, node.type));
        }
      }
    }
  }, [node]);

  useEffect(() => {
    getAuths();
  }, [currentService]);

  const createPayload = (action: String, catal: any) => {
    if (current.selectedCompany !== null) {
      let payload = {
        "action": action,
        "companyId": catal.companyId,
        "objectId": catal.objectId,
        "objectType": catal.objectType,
        "principalId": node.simpleId,
        "principalType": node.type,
        "serviceId": current.selectedCompany.service[currentService].id
      };
      return payload;
    }
  };

  const getAuths = () => {
    if (node !== undefined) {
      if (current.selectedCompany !== null) {
        if (node.type === "entryGroup") {
          thunkDispatch(getCompanyAuthorizations(current.selectedCompany.company.id, current.selectedCompany.service[currentService].id, node.simpleId, node.type));
        } else {
          thunkDispatch(getGroupAuthorizations(current.selectedCompany.company.id, current.selectedCompany.service[currentService].id, node.simpleId, node.type));
        }
      }
    }
  };

  const setEnabled = (catal: any) => {
    let payload = createPayload("ALLOW", catal);
    if (payload !== undefined) {
      changePrincipalAuth(payload);
    }
  };

  const setUncheck = (catal: any) => {
    let payload = createPayload("REMOVE", catal);
    if (payload !== undefined) {
      changePrincipalAuth(payload);
    }
  };

  const setDisabled = (catal: any) => {
    let payload = createPayload("DENY", catal);
    if (payload !== undefined) {
      changePrincipalAuth(payload);
    }
  };

  const getStatus = (catal: IObject) => {
    let ret: any = {};
    let filteredArray: any[] = auths.filter((filterd) => {
      return filterd.objectId == catal.objectId && filterd.objectType == catal.objectType;
    });
    if (filteredArray.length > 0) {
      if (filteredArray.reduce((val) => {
        return val;
      }).isAuthorized) {
        ret.icon = faCheckSquare;
        ret.color = "#77bc5c";
        ret.style = { width: "20px", height: "20px" };
      } else {
        ret.icon = faTimesSquare;
        ret.color = "#da5848";
        ret.style = { width: "20px", height: "20px" };
      }
    } else {
      ret.icon = faSquare;
      ret.color = "white";
      ret.style = { border: "2px solid #009ada", width: "18px", height: "18px", borderRadius: "2px" };
    }
    return ret;
  };

  const renderCatalog = () => {
    if (filteredCatalog.length > 0) {
      return createCatalog(filteredCatalog, getStatus);
    } else {
      if (current.selectedCompany !== null) {
        if (catalog[current.selectedCompany.service[currentService].id] !== undefined) {
          let activeArray: any[] = catalog[current.selectedCompany.service[currentService].id].map((cat: any) => cat);
          return createCatalog(activeArray, getStatus);
        } else {
          return <Spinner />;
        }
      } else {
        return <Spinner />;
      }
    }
  };

  const header = (nodeType: string) => {
    switch (nodeType) {
      case 'GROUP':
        return dictionaryGet(dictionary, "global.console.authorizations.group_permissione");
      case 'USER':
        return dictionaryGet(dictionary, "global.console.authorizations.user_permissions");
      case 'INVITE':
        return dictionaryGet(dictionary, "global.console.authorizations.user_permissions_message");
      default:
        break;
    }
  };

  const name = (node: any) => {
    if (node.type == "INVITE") {
      return <><span>{" «" + node.title + "» "}</span><FontAwesomeIcon icon={faEnvelope as IconProp}></FontAwesomeIcon></>;
    } else {
      return <span>{" «" + node.title + "» "}</span>;
    }
  };

  const searchCatalog = () => {
    if (current.selectedCompany !== null) {
      let selectedCompany = current.selectedCompany;
      let regx = new RegExp(searchText, "i");
      let res = catalog[selectedCompany.service[currentService].id].filter((cat: any) => cat !== null).filter((cata: any) => cata.description !== null).filter((ca: any) => ca.description.match(regx));
      setFilteredCatalog(res);
    }
  };

  const changePrincipalAuth = (payload: any) => {
    if (current.selectedCompany !== null) {
      let selectedCompany = current.selectedCompany;
      thunkDispatch(changePrincipalAuthorization(payload, current.selectedCompany.company.id)).then(() => {
        if (node.type === "entryGroup") {
          thunkDispatch(getCompanyAuthorizations(selectedCompany.company.id, selectedCompany.service[currentService].id, node.simpleId, node.type));
        } else {
          thunkDispatch(getGroupAuthorizations(selectedCompany.company.id, selectedCompany.service[currentService].id, node.simpleId, node.type));
        }
      });
    }
  };

  const createCatalog = (activeArray: any[], getStatus: (catal: IObject) => any) => {
    return activeArray.map((catal, i) => {
      let className = 'span-cat-object level' + catal.level;
      // BUG: Casting due to incompatible types;
      let icon: IconProp = faTimesSquare as IconProp;
      return (
        <div key={i} className={className} style={{ marginLeft: (30 * catal.level), fontWeight: catal.level == 1 || catal.level == 0 ? "bold" : "normal" }}>
          <UncontrolledButtonDropdown>
            <DropdownToggle>
              <span style={{ marginRight: "20px" }} onClick={getStatus(catal).func}>
                <FontAwesomeIcon style={getStatus(catal).style !== undefined ? getStatus(catal).style : ''}
                  icon={getStatus(catal).icon} color={getStatus(catal).color} />
              </span>
              {catal.description}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => { setEnabled(catal); }}><FontAwesomeIcon icon={faCheckSquare} color={'#77bc5c'}
                style={{ width: "20px", height: "20px" }} /> {dictionaryGet(dictionary, "global.console.authorizations.allow")}</DropdownItem>
              <DropdownItem onClick={() => { setDisabled(catal); }}><FontAwesomeIcon icon={icon} color={'#da5848'}
                style={{ width: "20px", height: "20px" }} /> {dictionaryGet(dictionary, "global.console.authorizations.deny")}</DropdownItem>
              <DropdownItem onClick={() => { setUncheck(catal); }}><FontAwesomeIcon icon={faSquare} color={'#ffffff'} style={{
                marginLeft: "3px",
                width: "20px",
                height: "20px",
                border: "1px solid #e5e5e5",
                borderRadius: "2px"
              }} /> {dictionaryGet(dictionary, "global.console.authorizations.remove")}</DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>
      );
    });
  };

  const mapIndexToAction = (index: number) => {
    switch (index) {
      case 1:
        return "ALLOW";
      case 2:
        return "DENY";
      case 3:
        return "REMOVE";
    }
  };

  const batchObjCatalOps = (index: number) => {
    if (current !== null) {
      if (current.selectedCompany !== null) {
        if (catalog !== null && current.selectedCompany !== null) {
          let objects = catalog[current.selectedCompany.service[currentService].id].map((catal: any) => {
            return {
              "objectId": catal.objectId,
              "objectType": catal.objectType
            };
          });
          let obj = {
            "action": mapIndexToAction(index),
            "companyId": current.selectedCompany.company.id,
            "objects": objects,
            "principalId": node.simpleId,
            "principalType": node.type,
            "serviceId": current.selectedCompany.service[0].id
          };
          thunkDispatch(changePrincipalAuthorizationBatch(current.selectedCompany.company.id, obj)).then((res) => {
            getAuths();
          });
        }
      }
    }
  };

  const renderBody = (): JSX.Element | undefined => {
    if (node !== undefined && current.selectedCompany != null) {
      return (
        <div style={{ overflowX: "auto" }}>
          <div className="auth-header-wrapper">
            <div className="auth-header">
              <h4><span>{header(node.type)}</span>{name(node)}</h4>
            </div>
          </div>
          <div className="container">
            <div className="auth-nav">
              <ServiceNav activeTab={currentService} changeService={changeService} selectedCompany={current.selectedCompany} />
            </div>
            <div className="input-wrapper">
              <Input style={{ borderColor: "rgba(217, 217, 217, 1)" }} className="input-form" onChange={(e) => { setSearchText(e.target.value); }} placeholder={dictionaryGet(dictionary, "global.console.authorizations.user_permissions_search")} />
              <Button className="btn-form-blue-cerca" color="primary" size="md" onClick={() => { searchCatalog(); }}>
                <FontAwesomeIcon icon={faSearch} className="font-1xl" />
              </Button>
            </div>
            <div style={{ marginLeft: "15px", display: "inline-flex" }}>
              <Button onClick={() => { batchObjCatalOps(1); }} outline className="btn-authgroup-side">
                <FontAwesomeIcon icon={faCheckSquare} color={'#77bc5c'} style={{ marginRight: "20px", width: "20px", height: "22px" }} /> {dictionaryGet(dictionary, "global.console.authorizations.allow_all")}
                </Button>
              <Button onClick={() => { batchObjCatalOps(2); }} outline className="btn-authgroup-side">
                <FontAwesomeIcon icon={faTimesSquare as IconProp} color={'#da5848'} style={{ marginRight: "15px", width: "20px", height: "20px" }} /> {dictionaryGet(dictionary, "global.console.authorizations.deny_all")}
                </Button>
              <Button onClick={() => { batchObjCatalOps(3); }} outline className="btn-authgroup-side">
                <FontAwesomeIcon icon={faSquare} color={"#fff"} style={{ border: "1px solid rgb(0, 144, 209)", borderRadius: "2px", marginRight: "15px" }} /> {dictionaryGet(dictionary, "global.console.authorizations.remove_all")}
                </Button>
            </div>
            <div className="div-catalog">
              <div className="catalog-title">{dictionaryGet(dictionary, "global.console.authorizations.scope_and_kpi")}</div>
              {renderCatalog()}</div>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <div className={"side-view-overlay" + (viewState ? " side-view-overlay-show" : "")} onClick={hideSideView}></div>
      <div className={"side-view" + (viewState ? " side-view-show" : "")}>
        {isLoading ? renderBody() : <Spinner />}
      </div>
    </>
  );
}

export const showSideView = (node: any) => {
  setViewState(true);
  setNode(node);
};

export const hideSideView = () => {
  setViewState(false);
};
