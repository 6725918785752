import { Actions } from '../../actions/index';
import { IInvitationsStore } from './types';
import { TUserActions } from '../../actions/user/types';

const initialState: IInvitationsStore = {
  invitations: {
    errorMsg: '',
    isError: false,
    isLoaded: false,
    data: []
  },
  licenses: {
    errorMsg: '',
    isError: false,
    isLoaded: false,
    data: null
  }
};

export function invitationsReducer(state = initialState, action: TUserActions): IInvitationsStore {
  switch (action.type) {
    case Actions.GET_LICENSE_SUCCESS:
      return Object.assign({}, state, {
        licenses: {
          isLoaded: true,
          isError: false,
          errorMsg: '',
          data: action.payload.data
        }
      });

    case Actions.GET_LICENSE_FAILURE:
      return Object.assign({}, state, {
        // ...state.login,
        licenses: {
          isLoaded: false,
          isError: true,
          errorMsg: '',
          data: null
        }
      });
    case Actions.SEARCH_INVITATIONS_SUCCESS:
      return Object.assign({}, state, {
        ...state.licenses,
        invitations: {
          isLoaded: true,
          isError: false,
          errorMsg: '',
          data: [...action.payload.data]
        }
      });

    case Actions.SEARCH_INVITATIONS_FAILURE:
      return Object.assign({}, state, {
        ...state.licenses,
        invitations: {
          isLoaded: false,
          isError: true,
          errorMsg: '',
          data: []
        }
      });

    case Actions.RESET_SERVICES_LIST:
      return Object.assign({}, state, {
        ...state.invitations,
        licenses: {
          isLoaded: false,
          isError: false,
          errorMsg: '',
          data: null
        }
      });

    default:
      return state;
  }
}
