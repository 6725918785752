import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Badge, Nav, NavItem, NavLink as RsNavLink, Tooltip, UncontrolledTooltip } from 'reactstrap';
import classNames from 'classnames';
// import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { faHome, faUserCheck, faDownload, faMailBulk, faUsers, faUserLock } from "@fortawesome/pro-regular-svg-icons";
import { useSelector } from 'react-redux';
import { AppState } from '../../store';
import { IService } from '../../store/user/types';
import { USER_TYPE_AS_API, SERVICE_CODE, SERVICE_CODES } from '../../common/constants';
import { dictionaryGet } from '../../common/functions';

export default function Sidebar(props: any) {
  const [clicked, setClicked] = useState<boolean>(false);
  const dictionary = useSelector((state: AppState) => state.dictionary);
  const current = useSelector((state: AppState) => state.user.current);

  const nav = {
    items: [
      {
        id: 0,
        name: "global.console.dashboard",
        url: '/home',
        icon: 'home',
        mainMenu: true
      },
      {
        id: 1,
        name: "global.console.download.title",
        url: '/download',
        icon: faDownload,
        mainMenu: false
      },
      {
        id: 2,
        name: "global.console.invitations.title",
        url: '/inviti',
        icon: faMailBulk,
        mainMenu: true
      },
      {
        id: 3,
        name: "global.console.users_management.title",
        url: '/utenti',
        icon: faUsers,
        mainMenu: true
      },
      {
        id: 4,
        name: "global.console.authorizations.title",
        url: '/autorizzazioni',
        icon: faUserLock,
        mainMenu: true
      },
      {
        id: 5,
        name: "global.console.authorization_verification.title",
        url: '/verifica-autorizzazioni',
        icon: faUserCheck,
        mainMenu: true
      },
    ]
  };

  const handleClick = (e: any) => {
    e.preventDefault();
    e.target.parentElement.classList.toggle('open');
  };

  const hideMobile = () => {
    if (document.body.classList.contains('sidebar-mobile-show')) {
      document.body.classList.toggle('sidebar-mobile-show');
    }
  };

  const badge = (badge: any) => {
    if (badge) {
      const classes = classNames(badge.class);
      return (<Badge className={classes} color={badge.variant}>{badge.text}</Badge>);
    }
  };

  const wrapper = (item: any) => {
    return (item.wrapper && item.wrapper.element ? (React.createElement(item.wrapper.element, item.wrapper.attributes, item.name)) : item.name);
  };

  const title = (title: any, key: any) => {
    const classes = classNames('nav-title', title.class);
    return (<li key={key} className={classes}>{wrapper(title)} </li>);
  };

  const divider = (divider: any, key: any) => {
    const classes = classNames('divider', divider.class);
    return (<li key={key} className={classes}></li>);
  };

  const navLabel = (item: any, key: any) => {
    const classes = {
      item: classNames('hidden-cn', item.class),
      link: classNames('nav-label', item.class ? item.class : ''),
      icon: classNames(
        !item.icon ? 'fa fa-circle' : item.icon,
        item.label.variant ? `text-${item.label.variant}` : '',
        item.label.class ? item.label.class : ''
      )
    };
    return (
      navLink(item, key, classes)
    );
  };

  const navItem = (item: any, key: any) => {
    const classes = {
      item: classNames(item.class),
      link: classNames('nav-link', item.variant ? `nav-link-${item.variant}` : ''),
      icon: item.icon
    };
    return (
      navLink(item, key, classes)
    );
  };

  const tooltipToggle = (isOpen: boolean) => {
    isOpen = !isOpen;
  };

  const navLink = (item: any, key: any, classes: any) => {
    const url = item.url ? item.url : '';
    let tooltipOpen = false;
    if (item.id == 0) {
      return (
        <NavItem key={key} className={classes.item}>
          {isExternal(url) ?
            <RsNavLink href={url} className={classes.link} active>
              <FontAwesomeIcon icon={item.icon} className="icona-nav" />
              <UncontrolledTooltip delay={0} className="menuTooltip" placement="right" target={'nl' + key}>
                {'Dashboard'}
              </UncontrolledTooltip>
            </RsNavLink>
            :
            <NavLink id={'nl' + key} to={url} className={classes.link + ' ' + 'home'}
              activeClassName="active" onClick={() => {
                hideMobile();
                setClicked(false);
              }}>
              <FontAwesomeIcon icon={faHome} className="icona-nav" />
              <UncontrolledTooltip delay={0} className="menuTooltip" placement="right" target={'nl' + key}>
                {'Dashboard'}
              </UncontrolledTooltip>
            </NavLink>
          }
          <Tooltip placement="right" isOpen={tooltipOpen} target={'nl' + key}
            toggle={() => tooltipToggle(tooltipOpen)}>
            {'Dashboard'}
          </Tooltip>
        </NavItem>
      );
    } else {
      if (item.mainMenu) {

        return (
          <NavItem key={key} className={classes.item}>
            {isExternal(url) ?
              <RsNavLink href={url} className={classes.link}>
                <FontAwesomeIcon icon={item.icon} className="icona-nav" />
                <UncontrolledTooltip delay={0} className="menuTooltip" placement="right" target={'nl' + key}>
                  {dictionaryGet(dictionary, item.name)}
                </UncontrolledTooltip>
              </RsNavLink>
              :
              <NavLink id={'nl' + key} to={url} className={classes.link + ' ' + item.icon}
                activeClassName="active" onClick={() => {
                  hideMobile();
                  setClicked(false);
                }}>
                <FontAwesomeIcon icon={item.icon} className="icona-nav" />
                <UncontrolledTooltip delay={0} className="menuTooltip" placement="right" target={'nl' + key}>
                  { dictionaryGet(dictionary, item.name) }
                </UncontrolledTooltip>
              </NavLink>
            }
            <Tooltip placement="right" isOpen={tooltipOpen} target={'nl' + key}
              toggle={() => tooltipToggle(tooltipOpen)}>
              { item.name }
            </Tooltip>
          </NavItem>
        );
      }
    }
  };

  const navDropdown = (item: any, key: any) => {
    return (
      <li key={key}>
        <a className="nav-link nav-dropdown-toggle" href="#" onClick={(e) => {
          handleClick(e)
        }}><i className={item.icon}></i>{dictionaryGet(dictionary, item.name)}</a>
        <ul className="nav-dropdown-items">
          {navList(item.children)}
        </ul>
      </li>
    );
  };

  const navType = (item: any, idx: any) =>
    item.title ? title(item, idx) :
      item.divider ? divider(item, idx) :
        item.label ? navLabel(item, idx) :
          item.children ? navDropdown(item, idx)
            : navItem(item, idx);

  const navList = (items: any) => {
    if (current.selectedCompany !== null) {
      let itemsapp = [...items];
      if ((current.selectedCompany.isOwner && current.selectedCompany.service.findIndex((serv) => { return serv.code === SERVICE_CODES.BI }) !== -1) || current.selectedCompany.service.findIndex((serv) => { return serv.code === SERVICE_CODES.BI }) !== -1) {
        items[1].mainMenu = true;
        return itemsapp.map((item: any, index: number) => navType(item, index));
      } else {
        if (current.selectedCompany.service !== null) {
          if (current.selectedCompany.service.findIndex((serv: IService) => serv.code === SERVICE_CODES.BI) !== -1 && current.selectedCompany.userType === USER_TYPE_AS_API.ADMINISTRATOR) {
            items[1].mainMenu = true;
            return itemsapp.map((item: any, index: number) => navType(item, index));
          } else {
            items[1].mainMenu = false;
            return items.map((item: any, index: number) => navType(item, index));
          }
        }
      }
    }
  };

  const isExternal = (url: string) => {
    const link = url ? url.substring(0, 4) : '';
    return link === 'http';
  };

  const menuHelp = () => {
    if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return (
        <>
          <hr style={{ 'borderBottom': '1px solid rgba(255, 255, 255, 0.65)', 'width': '88%' }} />
          <NavItem key={"nav-guida"} className={"hidden-cn"} active={true}>
            <RsNavLink href="./img/MGO_Alyante_Analytics.pdf" className={"nav-link"}>
              <FontAwesomeIcon icon={faQuestionCircle} className="icona-nav" />
              <span style={{ paddingTop: '10px', paddingLeft: '10px', marginLeft: '6px' }}>Guida</span>
            </RsNavLink>
          </NavItem>
        </>
      );
    }
    return "";
  };

  const activeRoute = (routeName: any, props: any) => {
    return props.location.pathname.indexOf(routeName) > -1 ? 'nav-item nav-dropdown open' : 'nav-item nav-dropdown';
  };

  return (
    <div className="sidebar">
      <nav className="sidebar-nav">
        <Nav>
          {navList(nav.items)}
          {menuHelp()}
        </Nav>
      </nav>
    </div>
  );
}
