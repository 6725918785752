import React, { Suspense, useEffect, useState } from 'react';
import SideView from '../../components/Autorizzazioni/SideView';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { getObjectCatalog } from '../../actions/authgroups/actions';
import CompanyAuthorizations from '../../components/Autorizzazioni/CompanyAuthorizations';
import { Spinner } from 'reactstrap';
import { fetchServicesList } from '../../actions/user/actions';


const AuthTree = React.lazy(() => import('../../components/Autorizzazioni/AuthTree'));

export default function AuthPage() {
  const user = useSelector((state: AppState) => state.user);
  const thunkDispatch = useDispatch<ThunkDispatch<AppState, any, AnyAction>>();
  const [viewCompanyAuth, setViewCompanyAuth] = useState<boolean>(true);
  const [activeNode, setActiveNode] = useState<any>();
  const selectedCompany = useSelector((state: AppState) => state.user.current.selectedCompany);

  useEffect(() => {
    if (user.current.selectedCompany !== null) {
      let idCompany = user.current.selectedCompany.company.id;
      if (user.current.selectedCompany.service === null) {
        if (user.current.selectedCompany !== null) {
          if (user.current.selectedCompany.service === null) {
            thunkDispatch(fetchServicesList(user.current.selectedCompany.company.id)).then(() => {
              if (user.current.selectedCompany !== null) {
                user.current.selectedCompany.service.map((service) => {
                  thunkDispatch(getObjectCatalog(idCompany, service.id));
                });
              }
            });
          }
        }
      } else {
        user.current.selectedCompany.service.map((service) => {
          thunkDispatch(getObjectCatalog(idCompany, service.id));
        });
      }
    }
  }, [user]);

  const openViewDefault = (nodeArray: any[]) => {
    setActiveNode(nodeArray[0]);
  };

  const openView = (node: any, closeProgrammatically: boolean) => {
    if (viewCompanyAuth) {
      if (closeProgrammatically) {
        setViewCompanyAuth(false);
      } else {
        setActiveNode(node);
        setViewCompanyAuth(!viewCompanyAuth);
      }
    } else {
      if (!closeProgrammatically) {
        setActiveNode(node);
        setViewCompanyAuth(!viewCompanyAuth);
      }
    }
  };

  useEffect(() => {
    if (selectedCompany !== null) {
      if (selectedCompany.service === null) {
        if (selectedCompany.isOwner) {
          thunkDispatch(fetchServicesList(selectedCompany.company.id));
        }
      }
    }
  }, [selectedCompany]);

  return (
    <div>
      <div className="div-tab-container">
        <div className="auth-main-page">
          <Suspense fallback={<Spinner />}>
            <AuthTree openCompanyAuthView={openView} openViewDefault={openViewDefault} />
          </Suspense>
          {viewCompanyAuth ? <CompanyAuthorizations node={activeNode} setActiveNode={setActiveNode} /> : ''}
        </div>
        <SideView />
      </div>
    </div>
  );
}