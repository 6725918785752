import React from "react";
const HTMLPurify = (props) =>{
    const DOMPurify = require('dompurify') (window);
    const clearHtml = DOMPurify.sanitize(props.html);
    return(
        
        <div dangerouslySetInnerHTML={{__html: clearHtml}}></div>
    )

}
export default HTMLPurify;