import React, { Suspense, useEffect } from 'react';
import { Redirect, Route, Switch } from "react-router";
import Loading from '../../components/Loading/Loading';
import { getAccessTokenCookie } from '../../common/functions';
import { Container } from 'reactstrap';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Home from '../Home/Home';
import Inviti from '../Inviti/InvitiContainer';
import Utenti from '../Utenti/UtentiContainer';
import { useDispatch, useSelector } from 'react-redux';
import { fetchServicesList, userCurrent, fetchDictionary, userLanguageSwitch, setUserTSIDLoginSuccess } from '../../actions/user/actions';
import { adoptInvitationToken } from '../../actions/invitations/actions';
import { AppState } from '../../store';
import AuthPage from '../Autorizzazioni/AuthPage';
import VerficaAuth from "../Verifica Autorizzazioni/VerificaAuth";
import { Download } from '../Downlaod/Download';
import Cookies from 'universal-cookie';
import { getParameterByName } from '../../common/functions';
import { startTsidAuth, getToken } from '../../common/oauth';
import {DEFAULT_LANGUAGE} from '../../common/constants';
import { DownloadQlikHistory } from '../Downlaod/DownloadQlikHistory';

type Props = {
  match: {
    isExact: boolean
    params: any
    path: string
    url: string
  },
  location: {
    hash: string,
    pathname: string,
    search: string,
    state: any
  }
}

export default function Main(props: Props) {
  const current = useSelector((state: AppState) => state.user.current);
  const dispatch = useDispatch();

  useEffect(() => {
    const cookies = new Cookies();
    let sessionToken = getAccessTokenCookie();
    if (sessionToken == null && sessionToken == undefined) {
      console.log("no session");
      // Test Oauth with TSIDv3
      let authCode = getParameterByName("code");
      if (authCode != null && authCode != "") {
        getToken(authCode).then((tk:any) => {
          let tsidState = (getParameterByName("state") != "" ? JSON.parse(getParameterByName("state")) : null);
          if (tsidState != null) {
            if (tsidState.invToken != null) {
              // Il token JWT è in : tk.data.access_token
              // In questo punto va effettuata la chiamata allo use invitation
              initializeUserAnalytics(tk, tsidState.invToken);
            }
          } else {
            initializeUserAnalytics(tk);
          }
        });
      } else {
        let tsidState:{ [id: string] : any; }|null = null;
        let invToken:any = getParameterByName("invtoken");

        console.log(getParameterByName("state"));
      
      if (getParameterByName("state")!=null &&  getParameterByName("state")!="") {
        invToken = JSON.parse(decodeURIComponent( getParameterByName("state")));
        invToken = invToken["invToken"];
      }
      console.log(invToken);

        if (invToken != null && invToken != "") {
          tsidState = {};
          tsidState["invToken"] = invToken;
        }

        startTsidAuth(tsidState);
      }
    } else {
      let invToken:any = getParameterByName("invtoken");

      
      
      if (invToken != null && invToken != "") {
        console.log("test" + invToken);
        window.location.href = '/#/invitation-accept?invtoken=' + invToken;
        
        /*adoptInvitationToken(getAccessTokenCookie(), invToken).then((res) => {
          console.log("RESPONSE USE INVITATION:", res);
          postTsidLoginUserInit(getAccessTokenCookie());
        }).catch((err:any) => {
          console.log("ERRORE USE INVITATION:", err);
          window.location.href = '/#/error-page';
        });*/

      } else {
        dispatch(userCurrent());
      }


    }
    /*if (current.isLoaded === false) {
      if (getAccessTokenCookie() !== undefined) {
        dispatch(userCurrent());
      }
    }
    if (current.selectedCompany !== null) {
      if (current.selectedCompany.service === null) {
        dispatch(fetchServicesList(current.selectedCompany.company.id));
      }
    }
    let userLang = current.language;
    console.log(current.language);

    if (userLang=="") {
      userLang = (navigator.language);
      if (userLang==null||userLang=="") {
        if (current.selectedCompany !== null) {
          userLang = current.selectedCompany.company.language;
        }
      }
      dispatch(userLanguageSwitch(userLang));
    }
    dispatch(fetchDictionary(userLang));*/
  }, []);
  useEffect(() => {
    if (current && current.isLoaded) {
      let userLang = current.language;

      if (userLang==null || userLang=="") {
        userLang = DEFAULT_LANGUAGE ;
        if (userLang==null||userLang=="") {
          if (current.selectedCompany !== null) {
            userLang = current.selectedCompany.company.language;
          }
        }

        dispatch(userLanguageSwitch(userLang));
      } else {
        dispatch(fetchDictionary(userLang));
      }
    }
  }, [current.language]);

  useEffect(() => {
    if (current.selectedCompany !== null) {
      if (current.selectedCompany.isOwner) {
        dispatch(fetchServicesList(current.selectedCompany.company.id));
      }
    }
  }, [current.selectedCompany]);

  const postTsidLoginUserInit = (tk:any) => {
    dispatch(userCurrent());
    window.history.replaceState({}, '', '/#/dashboard');
  };

  const initializeUserAnalytics = (tk:any, invToken?:string) => {
    console.log(invToken);
    if (invToken != null && invToken.length > 0) {
      setUserTSIDLoginSuccess(tk.data.access_token, tk.data.id_token);
      window.location.href = '/#/invitation-accept?invtoken=' + invToken;
      
      /*adoptInvitationToken(tk.data.access_token, invToken).then((res) => {
        console.log("RESPONSE USE INVITATION:", res);
        postTsidLoginUserInit(tk);
      }).catch((err:any) => {
        console.log("ERRORE USE INVITATION:", err);
        window.location.href = '/#/error-page';
      });*/
    } else {
      setUserTSIDLoginSuccess(tk.data.access_token, tk.data.id_token);
      postTsidLoginUserInit(tk);
    }

  }

  const bodySwitch = () => {
    return (
      <main className="main">
        <Breadcrumb {...props} />
        <Container fluid>
          <Suspense fallback={<Loading />}>
            <Switch>
              <Route path="/home" name="Home" exact component={Home} />
              <Route path="/download" name="Download" exact component={Download} />
              <Route path="/inviti" name="Inviti" exact component={Inviti} />
              <Route path="/utenti" name="Utenti" exact component={Utenti} />
              <Route path="/autorizzazioni" name="Autorizzazioni" exact component={AuthPage} />
              <Route path="/verifica-autorizzazioni" name="Verifica Autorizzazioni" exact component={VerficaAuth} />
              <Route path='/download-qlik-history' component={DownloadQlikHistory} />
              <Redirect from="/" to="/home" />
            </Switch>
          </Suspense>
        </Container>
      </main>
    );
  };


    return (
      <div className="app">
        <Header {...props} />
        <div className="app-body">
          <Sidebar {...props} />
          {bodySwitch()}
          {/* <Aside /> */}
        </div>
        {/* <Footer /> */}
      </div>
    );

}
