
import { IAuthorizationsReducer } from './types';
import { Actions } from '../../actions';
import { TAuthGroup } from '../../actions/authgroups/types';

const initialState: IAuthorizationsReducer = {
  errorMsg: '',
  isError: false,
  isLoaded: false,
  data: []
}

export function authorizationReducer(state = initialState, action: TAuthGroup): IAuthorizationsReducer {
  switch (action.type) {
    case Actions.GET_NODE_AUTHORIZATIONS_SUCCESS:
      return Object.assign({}, state, {
        isLoaded: true,
        isError: false,
        errorMsg: '',
        data: [...action.payload.data]
      });

    default:
      return state;
  }
}