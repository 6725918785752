import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ISelectedCompany, ICompanies, ICompany } from '../../store/user/types';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AppState } from '../../store';
import { AnyAction } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { userActivateOwner } from '../../actions/user/actions';
import { dictionaryGet } from '../../common/functions';
type Props = {
  isModalOpen: boolean,
  closeModal: Function,
  selectedCompany: any
}

export default function ActivateLicensesDialog(props: Props) {
  const thunkDispatch = useDispatch<ThunkDispatch<AppState, any, AnyAction>>();
  const dictionary = useSelector((state: AppState) => state.dictionary);
  
  const submit = () => {
    thunkDispatch(userActivateOwner(props.selectedCompany.company.id)).then((res: any) => {
      props.closeModal(props.selectedCompany.company.id);
    });
  }

  const renderModalBody = () => {
    return (
      <>
        <ModalHeader>
          <FontAwesomeIcon icon={faQuestionCircle} color={'#fbc863'} /> Vuoi attivare tutte le soluzioni a tua disposizione?
        </ModalHeader>
        <ModalBody style={{ marginLeft: '15px', marginRight: '15px' }}>
          L'attivazione comporterà il consumo di tante licenze quante sono le soluzioni disponibili.
        </ModalBody>
      </>
    );
  }

  return (
    <Modal isOpen={props.isModalOpen} toggle={() => { props.closeModal(props.selectedCompany.company.id) }} size='md' style={{ width: 'auto', height: 'auto', maxWidth: '40vw' }}>
      {renderModalBody()}
      <ModalFooter>
        <Button className="btn-form-secondary" onClick={() => { props.closeModal(props.selectedCompany.company.id) }}>{dictionaryGet(dictionary, "global.console.cancel")}</Button>
        <Button className="btn-form-primary" color="primary" onClick={() => { submit(); }}>Attiva Tutte</Button>
      </ModalFooter>
    </Modal>
  );
}
