import axios from 'axios';
import { getParameterByName } from './functions';
import { OID_CONFIG } from './constants';

export const endSession = (idToken:string, redirect_url?:string, state?:any) => {
  if (redirect_url==null)
    redirect_url = OID_CONFIG.redirect_uri;

  let strState = "";
  if (state!=null) {
    state = JSON.stringify(state);
    strState = "&state=" + encodeURIComponent(state);
  }
  
  console.log(OID_CONFIG.endsession_endpoint + "?id_token_hint=" + idToken + "&post_logout_redirect_uri=" + redirect_url + strState);
  
  
  window.location.href = OID_CONFIG.endsession_endpoint + "?id_token_hint=" + idToken + "&post_logout_redirect_uri=" + redirect_url + strState;
};

export const getToken = (authCode:string) => {
  let state = getParameterByName("state");
  console.log(state);

  if (localStorage.getItem("pkce_state") != state) {
    alert("Invalid state");
    throw "invalid state";
  }

  return new Promise<any>((resolve, reject) => {
    const configHeaders:any = {
      "Content-type": "application/x-www-form-urlencoded",
    };
    const payload = `grant_type=authorization_code&code=${authCode}&client_id=${OID_CONFIG.client_id}&redirect_uri=${OID_CONFIG.redirect_uri}&code_verifier=${localStorage.getItem("pkce_code_verifier")}`;

    axios
      .post(OID_CONFIG.token_endpoint, payload, configHeaders)
      .then((response:any) => {
        resolve(response);
      });
  });
};

export const startTsidAuth = (tsidState:any) => {
  let state:any = null;
  if (tsidState!=null)
    state = JSON.stringify(tsidState);

  localStorage.setItem("pkce_state", state);

  // Create and store a new PKCE code_verifier (the plaintext random secret)
  var code_verifier = generateRandomString();
  localStorage.setItem("pkce_code_verifier", code_verifier);

  // Hash and base64-urlencode the secret to use as the challenge
  pkceChallengeFromVerifier(code_verifier).then((v:any) => {
    var url = OID_CONFIG.authorization_endpoint
      + "?response_type=code"
      + "&client_id=" + encodeURIComponent(OID_CONFIG.client_id)
      + "&state=" + encodeURIComponent(state)
      + "&scope=" + encodeURIComponent(OID_CONFIG.requested_scopes)
      + "&redirect_uri=" + encodeURIComponent(OID_CONFIG.redirect_uri)
      + "&code_challenge=" + encodeURIComponent(v)
      + "&code_challenge_method=S256";

    // Redirect to the authorization server
    window.location.href = url;
  });
};

function generateRandomString() {
  var array = new Uint32Array(28);
  window.crypto.getRandomValues(array);
  return Array.from(array, dec => ('0' + dec.toString(16)).substr(-2)).join('');
}

function pkceChallengeFromVerifier(v:string) {
  return new Promise((resolve, reject) => {
    sha256(v).then((x:any) => {
      resolve(base64urlencode(x));
    });

  });
}

// Calculate the SHA256 hash of the input text.
// Returns a promise that resolves to an ArrayBuffer
function sha256(plain:string) {
  return new Promise((resolve, reject) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(plain);
    resolve(window.crypto.subtle.digest('SHA-256', data));
  });
}

// Base64-urlencodes the input string
function base64urlencode(str:any) {
  // Convert the ArrayBuffer to string using Uint8 array to conver to what btoa accepts.
  // btoa accepts chars only within ascii 0-255 and base64 encodes them.
  // Then convert the base64 encoded to base64url encoded
  //   (replace + with -, replace / with _, trim trailing =)
  let a = new Uint8Array(str);
  let k = Array.from(a);
  return btoa(String.fromCharCode.apply(null, k))
    .replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}
