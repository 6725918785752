import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Collapse, Input, InputGroup, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import { IInvitation } from '../../store/invitations/types';
import { IService, ISelectedCompany } from '../../store/user/types';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AppState } from '../../store';
import { AnyAction } from 'redux';
import { sendInvitation } from '../../actions/invitations/actions';
import { dictionaryGet, stringTemplateReplace } from '../../common/functions';

type Props = {
  isModalOpen: boolean,
  toggleModal: Function,
  selectedService: IService,
  selectedCompany: ISelectedCompany
  payloadArray: Array<number>,
  visArray: Array<IInvitation>,
  baseURL: string
}

export default function ModaleRiepilogo(props: Props) {
  const thunkDispatch = useDispatch<ThunkDispatch<AppState, any, AnyAction>>();
  const [baseUrl, setBaseUrl] = useState<string>("");
  const [isCollapseOpen, setCollapseOpen] = useState<boolean>(false);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [isSent, setIsSent] = useState<boolean>(false);
  const dictionary = useSelector((state: AppState) => state.dictionary);

  useEffect(() => {
    setBaseUrl(props.baseURL);
  }, []);

  useEffect(() => {
    setBaseUrl(props.baseURL);
  }, [props.baseURL]);

  const submit = () => {
    setIsSending(true);
    let appBaseUrl;
    if (baseUrl !== props.baseURL) {
      appBaseUrl = baseUrl
    } else {
      appBaseUrl = null;
    }

    let invitationsDto = {
      "inviteIds": props.payloadArray,
      "url": appBaseUrl
    };

    thunkDispatch(sendInvitation(invitationsDto, props.selectedService.id, props.selectedCompany.company.id, 1, "")).then(() => {
      setIsSending(false);
      setIsSent(true);
    });
  }

  const chiudiModale = () => {
    props.toggleModal(false);
  };

  const renderBody = () => {
    return props.visArray.map((invito) => {
      return (
        <React.Fragment key={invito.id}>
          <div className="div-riepilogo-invito"><div><span><strong>{invito.lastName + " " + invito.firstName}</strong></span><span>{invito.email}</span></div></div><br />
        </React.Fragment>
      );
    });
  };

  const renderFooter = () => {
    return (
      <ModalFooter>
        <Button disabled={isSending} className="btn-form-secondary" onClick={() => { chiudiModale(); }}>{ dictionaryGet(dictionary, "global.console.cancel") }</Button>
        <Button disabled={isSending} className="btn-form-primary" color="primary" onClick={() => { submit(); }}>{isSending ? <Spinner size="sm" /> : dictionaryGet(dictionary, "global.console.invitations.send")}</Button>
      </ModalFooter>
    );
  };

  const icon = () => {
    let icon: IconProp;
    if (isCollapseOpen) {
      icon = faChevronDown as IconProp;
    } else {
      icon = faChevronRight as IconProp;
    }
    return icon as IconProp;
  }

  const renderCollapsibleCard = () => {
    return (
      <div className="collapsible-baseurl" onClick={() => { setCollapseOpen(!isCollapseOpen); }}>
        <div className="collapsed">
          <FontAwesomeIcon icon={icon()} />
          <span style={{ marginLeft: "15px" }}>{ dictionaryGet(dictionary, "global.console.invitations.invitation_link") }: </span>
        </div>
        <Collapse isOpen={isCollapseOpen}>
          <Card>
            <CardBody>
              <InputGroup>
                <span style={{ marginRight: "10px", lineHeight: "35px" }}>{ dictionaryGet(dictionary, "global.console.invitations.application_path") }: </span>
                <Input disabled={isSending} className="input-base-url" onClick={(e: any) => { e.stopPropagation(); }} onChange={(e: any) => { setBaseUrl(e.target.value); }} value={baseUrl}></Input>
              </InputGroup>
            </CardBody>
          </Card>
        </Collapse>
      </div>
    );
  };

  const renderBodyWrapper = () => {
    if (!isSent) {
      return (
        <>
          <div className="modal-riepilogo-intestazione"><strong>{ dictionaryGet(dictionary, "global.console.invitations.invitation_recipients")}</strong></div>
          <div className="scrollable-body">
            {renderBody()}
          </div>
          {renderCollapsibleCard()}
        </>
      );
    } else {
      return (
        <>
          <div>
            <div style={{ marginBottom: "30px", textAlign: "center" }}>
              <div style={{ margin: "20px 0px" }}><FontAwesomeIcon color={"#77bc5c"} size={"6x"} icon={faCheckCircle as IconProp} /></div>
              <div style={{ marginBottom: "20px" }}>{ dictionaryGet(dictionary, "global.console.invitations.invitation_sent")}</div>
              <Button disabled={isSending} className="btn-form-primary" color="primary" onClick={() => { chiudiModale(); }}>{ dictionaryGet(dictionary, "global.console.invitations.continue")}</Button>
            </div>

          </div>
        </>
      );
    }
  }

  return (
    <Modal isOpen={props.isModalOpen} toggle={() => { chiudiModale() }} size='md' style={{ width: 'auto', height: 'auto', maxWidth: '40vw' }}>
      <ModalHeader toggle={() => { chiudiModale() }}><strong>{props.selectedService.name + " | " + dictionaryGet(dictionary, "global.console.invitations.send") }</strong></ModalHeader>
      <ModalBody style={{ marginLeft: '15px', marginRight: '15px' }}>
        {renderBodyWrapper()}
      </ModalBody>
      {isSent ? "" : renderFooter()}
    </Modal>
  );
}
