import React from 'react';
import { NavbarToggler, NavbarBrand, Nav } from 'reactstrap';
import { faBars, faQuestionCircle, faBuilding } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DropdownUser } from './DropdownUser';
import DropdownAziende from './DropdownAziende';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';

export default function Header(props: any) {
  const userCurrent = useSelector((state: AppState) => state.user.current);

  const sidebarToggle = (e: any) => {
    e.preventDefault();
    document.body.classList.toggle('sidebar-minimized');
  }

  const sidebarMinimize = (e: any) => {
    e.preventDefault();
    document.body.classList.toggle('sidebar-minimized');
  }

  const mobileSidebarToggle = (e: any) => {
    e.preventDefault();
    document.body.classList.toggle('sidebar-mobile-show');
  }

  const asideToggle = (e: any) => {
    e.preventDefault();
    document.body.classList.toggle('aside-menu-hidden');
  }

  const getAziende = (): JSX.Element | null => {
    if (userCurrent.isLoaded) {
      if (userCurrent.companies.length > 1) {
        return (
          <DropdownAziende />
        );
      } else {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          return (
            <div className="item-azienda">
              {userCurrent.companies[0].company.name}
            </div>
          );
        } else {
          return (
            <div className="item-azienda">
              <FontAwesomeIcon icon={faBuilding} className="font-2xl" style={{ color: '#FFFFFF', marginRight: '10px', fontSize: '21px' }}></FontAwesomeIcon>{userCurrent.companies[0].company.name}
            </div>
          );
        }
      }
    } else {
      return null;
    }
  }

  if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    let headerHeight = { height: "109px" };
    return (
      <header style={headerHeight} className="app-header navbar">
        <NavbarToggler className="d-lg-none" onClick={(e) => { mobileSidebarToggle(e) }}>
          <a><FontAwesomeIcon icon={faBars} className="font-1xl" style={{ color: '#FFFFFF' }}></FontAwesomeIcon></a>
        </NavbarToggler>
        <NavbarToggler className="d-md-down-none mr-auto" onClick={(e) => { sidebarToggle(e) }}>
          <a><FontAwesomeIcon icon={faBars} className="font-1xl" style={{ color: '#FFFFFF' }}></FontAwesomeIcon></a>
        </NavbarToggler>
        <NavbarBrand tag={'span'}>
          <p className="navbarbrand-titolo"><img className="head-img" src="img/u16.png"></img></p>
          {getAziende()}
        </NavbarBrand>
        <Nav className="ml-auto" navbar>
          {/* <HeaderDropdown {...this.props} /> */}
        </Nav>
        {/* {searchBox()} */}
      </header>
    );
  } else {
    return (
      <>
        <header className="app-header navbar">
          <NavbarToggler className="d-lg-none" onClick={(e) => { mobileSidebarToggle(e); }}>
            <a><FontAwesomeIcon icon={faBars} className="font-1xl" style={{ color: '#FFFFFF' }}></FontAwesomeIcon></a>
          </NavbarToggler>
          <NavbarToggler className="d-md-down-none mr-auto" onClick={(e) => { sidebarToggle(e); }}>
            <a><FontAwesomeIcon icon={faBars} className="font-1xl" style={{ color: '#FFFFFF' }}></FontAwesomeIcon></a>
          </NavbarToggler>
          <div className="head-div-img"><img className="head-img" src="images/u16.png"></img></div>
          <NavbarBrand></NavbarBrand>
          {/* {searchBox()} */}
          <Nav className="ml-auto" navbar>
            {getAziende()}
            <DropdownUser />
          </Nav>
          {/* <Nav className="ml-auto" navbar onClick={this.openPdfguide} style={{ cursor: "pointer", padding: "3px 20px 0 20px" }}> */}

        </header>
      </>
    );
  }
}
