import React, { useEffect, useState } from 'react';
import HTMLPurify from "../../components/HTMLPurify";
import queryString from 'query-string';
import { fetchDictionary } from '../../actions/user/actions';
import { AppState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { dictionaryGet } from "../../common/functions";

export default function ErrorPage(props: any) {
  const dictionary = useSelector((state: AppState) => state.dictionary);
  const dispatch = useDispatch();

  const [errorCode, setErrorCode] = useState<any>("maintenance");
  const [userLanguage, setUserLanguage] = useState<any>("it-IT");

  useEffect(() => {
    let values = queryString.parse(props.location.search);
    if (values.error_code) {
      setErrorCode(values.error_code);
    }

    if (dictionary == null) {
      let userLang = (navigator.language);
      if (userLang != null || userLang != "") {
        setUserLanguage(userLang);
        dispatch(fetchDictionary(userLang));
      } else {
        dispatch(fetchDictionary(userLanguage));
      }
    }
  }, []);

  let back = dictionaryGet(dictionary, "global.console.back");
  let error = dictionaryGet(dictionary, "global.messages." + errorCode);
  let url_img = `\images\\${errorCode}.svg`;

  return (
    <div className="">
      <div className="div-img-wip">
        <img className="img-wip" src=".\images\LOGO_Analytics_cut.png" />
      </div>
      <div className="div-text-errorpage">
        <p>
          <img className="img-wip-secondary" src={url_img} />
        </p>
        <p><HTMLPurify html={error} /></p>
        <div className="div-bottoni-errorpage"><p onClick={() => { window.location.href = "#/home"; }}>{back}</p></div>
      </div>
    </div>
  );
}
