export const  DEFAULT_LANGUAGE:string  = "it-IT";


export const URL = {
  API_URL : process.env.REACT_APP_USER_SERVICES_URL,
  ANALYTICS_URL : process.env.REACT_APP_ANALYTICS_LAMBDA_URL,
  TSID_STAGE : process.env.REACT_APP_TSID_URL
}

export const LAMBDA_INSTALLER_URL = {
  DEV : process.env.REACT_APP_ANALYTICS_LAMBDA_URL,
  PROD : ""
}

export const OID_CONFIG = {
  client_id: (process.env.REACT_APP_TSID_CLIENT_ID!=null?process.env.REACT_APP_TSID_CLIENT_ID:""),
  redirect_uri: (process.env.REACT_APP_TSID_REDIRECT_URL!=null?process.env.REACT_APP_TSID_REDIRECT_URL:""),
  authorization_endpoint: URL.TSID_STAGE + "/connect/authorize",
  token_endpoint: URL.TSID_STAGE + "/connect/token",
  endsession_endpoint: URL.TSID_STAGE + "/connect/endsession",
  requested_scopes: "openid profile"
};

export const LAMBDA_URL = process.env.REACT_APP_ANALYTICS_LAMBDA_URL;

export enum SERVICE_CODE {
  ADMIN = 'ADMIN'
}

export const COOKIE_ACCESS = 'admin-console-session-cookie';
export const COOKIE_REFRESH = 'admin-console-refresh-cookie';
export const ID_TOKEN_COOKIE = 'admin-console-id-token';

export enum USER_TYPE {
  OWNER = 'Proprietario',
  USER = 'Utente',
  ADMINISTRATOR = 'Amministratore'
};

export enum USER_TYPE_AS_API {
  USER = 'USER',
  ADMINISTRATOR = 'ADMINISTRATOR'
}

export enum ORDER_BY {
  lastName,
  email,
  lastSend
}

export enum ORDER_DIR {
  DESC,
  ASC
}

export enum AUTH_ACTIONS {
  'ALLOW',
  'DENY'
}

export enum SERVICE_CODES {
  AN = "AN",
  BI = "BI"
}

export enum INSTALLER_CODE {
  BI = "BI",
  QLIK = "qlik"
}
