import React from 'react';
import { Button, Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import { ISelectedCompany, IService } from '../../store/user/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { deleteInvitation, undoInvitation } from '../../actions/invitations/actions';
import { IInvitation } from '../../store/invitations/types';
import { AppState } from '../../store';
import { dictionaryGet } from '../../common/functions';

type Props = {
  isModalOpen: boolean,
  toggleModal: Function,
  selectedService: IService,
  selectedCompany: ISelectedCompany,
  editInvite: IInvitation,
  queryString: string
}

export default function DeleteConfirmationModal(props: Props) {
  const thunkDispatch = useDispatch();
  const dictionary = useSelector((state: AppState) => state.dictionary);

  const chiudiModale = () => {
    props.toggleModal(false);
  };

  const deleteInvito = () => {
    thunkDispatch(deleteInvitation(props.editInvite.id, props.selectedService.id, props.selectedCompany.company.id, 1, props.queryString));
    chiudiModale();
  };

  const annullaInvito = () => {
    thunkDispatch(undoInvitation(props.editInvite.id, props.selectedService.id, props.selectedCompany.company.id, 1, props.queryString));
    chiudiModale();
  };

  const modalBody = () => {
    if (props.editInvite.lastSend === null) {
      return (
        <>
          <Row style={{ marginBottom: '25px' }}>
            <Col md="1"><FontAwesomeIcon icon={faQuestionCircle} style={{ color: "orange" }} /></Col>
            <Col md="11"><span><strong>{ dictionaryGet(dictionary, "global.console.invitations.invitation_cancel")}</strong></span></Col>
          </Row>
          <Row style={{ marginTop: '25px', fontSize: '14px' }}>
            <Col md="1"></Col>
            <Col md="11"><span> { dictionaryGet(dictionary, "global.console.invitations.invitation_not_sent")}</span></Col>
          </Row>
        </>
      );
    } else {
      return (
        <>
          <Row style={{ marginBottom: '25px' }}>
            <Col md="1"><FontAwesomeIcon icon={faQuestionCircle} style={{ color: "orange" }} /></Col>
            <Col md="11"><span><strong>{ dictionaryGet(dictionary, "global.console.invitations.invitation_delete")}</strong></span></Col>
          </Row>
          <Row style={{ marginTop: '25px', fontSize: '14px' }}>
            <Col md="1"></Col>
            <Col
              md="11"><span> { dictionaryGet(dictionary, "global.console.invitations.invitation_cancel_mail")}</span></Col>
          </Row>
        </>
      );
    }
  };

  return (
    <Modal isOpen={props.isModalOpen} toggle={() => {
      chiudiModale()
    }} style={{ width: '35vw', height: 'auto', maxWidth: '20vw' }}>
      <ModalBody style={{ marginLeft: '15px', marginRight: '15px' }}>
        {modalBody()}
      </ModalBody>
      <ModalFooter>
        <Button className="btn-form-secondary" onClick={() => {
          chiudiModale();
        }}>{ dictionaryGet(dictionary, "global.console.cancel") }</Button>
        {props.editInvite.lastSend !== null ? <Button className="btn-form-primary" color="primary" onClick={() => {
          annullaInvito();
        }}>{ dictionaryGet(dictionary, "global.console.authorizations.delete") }</Button> : <Button className="btn-form-primary" color="primary" onClick={() => {
          deleteInvito();
        }}>{ dictionaryGet(dictionary, "global.console.authorizations.delete") }</Button>}
      </ModalFooter>
    </Modal>
  );
}
