import React, { useEffect, useState } from 'react';
import { Row, Col, Button, UncontrolledAlert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faUserLock, faMailBulk, faDownload } from '@fortawesome/free-solid-svg-icons';
import { faUserCheck, faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import ActivateLicensesDialog from '../../components/Home/ActivateLicensesDialog';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { canActivateOwner } from '../../actions/user/actions';
import { SERVICE_CODES } from '../../common/constants';
import { getEnvironment, hasInvitations } from '../../actions/invitations/actions';
import { dictionaryGet, stringTemplateReplace } from '../../common/functions';
import HTMLPurify from '../../components/HTMLPurify';
import { log } from 'console';

export default function Home() {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [canOwnerActivate, setCanOwnerActivate] = useState<boolean>(false);
  const selectedCompany = useSelector((state: AppState) => state.user.current.selectedCompany);
  const currentUser = useSelector((state: AppState) => state.user.current);
  const thunkDispatch = useDispatch<ThunkDispatch<AppState, any, AnyAction>>();
  const [canUserActivate, setCanUserActivate] = useState<boolean>(false);
  const [showInvitationsToast, setShowInvitationsToast] = useState<boolean>(false);
  const dictionary = useSelector((state: AppState) => state.dictionary);
  const fragment = React.Fragment;

  const countInvitationsAndShowToast = () => {
    if (selectedCompany !== null) {
      thunkDispatch(hasInvitations(selectedCompany.company.id)).then((res: any) => {
        if (res !== undefined) {
          if (res.payload !== undefined) {
            setShowInvitationsToast(res.payload.data)
          }
        }
      });
    }
  };

  useEffect(() => {
    if (selectedCompany !== null) {
      if (selectedCompany.isOwner) {
        thunkDispatch(canActivateOwner(selectedCompany.company.id)).then((res: any) => {
          setCanUserActivate(res.payload.data);


        });


      }
      countInvitationsAndShowToast();
    }
  }, []);

  useEffect(() => {
    if (selectedCompany!=null) {
      let biServ = selectedCompany.service.findIndex((serv) => { return serv.code === SERVICE_CODES.BI });        
      if (selectedCompany.isOwner) {
        if (biServ !== -1) {
          thunkDispatch(getEnvironment(selectedCompany.service[biServ].id, selectedCompany.company.id)).then((res: any) => {
            if (res.payload.data.baseUrl != null && res.payload.data.baseUrl != "") {
              setCanOwnerActivate(true);
            } else {
              setCanOwnerActivate(false);
            }

          });
        } else {
          setCanOwnerActivate(false);
        }
      }
    }
    
  }, [selectedCompany!=null?selectedCompany.service:null]);

  useEffect(() => {
    if (selectedCompany !== null) {

      if (selectedCompany.isOwner) {
        thunkDispatch(canActivateOwner(selectedCompany.company.id)).then((res: any) => {
          setCanUserActivate(res.payload.data);
        });

      }
      countInvitationsAndShowToast();
    }
  }, [selectedCompany]);

  const welcomeCards = () => {
    return (
      <>

        {renderDownloadCard()}
        <Col xs="12" med="6" sm="6" xl="3">
          <a href={'#/inviti'}>
            <div className="card-container dashboard-card">
              <div style={{
                float: "left",
                display: "flex",
                padding: "24px 0 24px 24px",
                color: "rgb(0, 80, 117)"
              }}>
                <div style={{
                  float: "left",
                  width: "40px",
                  padding: "2px 0"
                }}>
                  <FontAwesomeIcon icon={faMailBulk} className="font-1xl" style={{ fontSize: '24px !important' }} />
                </div>
                <div style={{
                  float: "left",
                  padding: "0",
                  maxWidth: "180px"
                }}><p style={{
                  fontSize: "24px",
                  fontFamily: "Cairo-SemiBold",
                  lineHeight: "24px",
                  marginBottom: "10px"
                }}>{dictionaryGet(dictionary, "global.console.invitations.title")}</p>
                  <p style={{
                    color: "rgb(157, 166, 173)",
                    fontSize: "14px"
                  }}>{dictionaryGet(dictionary, "global.console.invitations.description")}</p></div>
              </div>
              <div className="card-right"><a href={'#/inviti'}><FontAwesomeIcon icon={faArrowRight} style={{ fontSize: "20px" }} /></a></div>
            </div>
          </a>
        </Col>
        <Col xs="12" med="6" sm="6" xl="3">
          <a href={'#/utenti'}>
            <div className="card-container dashboard-card">
              <div style={{
                float: "left",
                display: "flex",
                padding: "24px 0 24px 24px",
                color: "rgb(0, 80, 117)"
              }}>
                <div style={{
                  float: "left",
                  width: "40px",
                  padding: "2px 0"
                }}>
                  <FontAwesomeIcon icon={faUsers} className="font-1xl" style={{ fontSize: '24px !important' }} />
                </div>
                <div style={{
                  float: "left",
                  padding: "0",
                  maxWidth: "180px"
                }}><p style={{
                  fontSize: "24px",
                  fontFamily: "Cairo-SemiBold",
                  lineHeight: "24px",
                  marginBottom: "10px"
                }}>{dictionaryGet(dictionary, "global.console.users_management.title")}</p>
                  <p style={{
                    color: "rgb(157, 166, 173)",
                    fontSize: "14px"
                  }}>{dictionaryGet(dictionary, "global.console.users_management.description")}</p></div>
              </div>
              <div className="card-right"><a href={'#/utenti'}><FontAwesomeIcon icon={faArrowRight} style={{ fontSize: "20px" }} /></a></div>
            </div>
          </a>
        </Col>
        <Col xs="12" med="6" sm="6" xl="3">
          <a href={'#/autorizzazioni'}>
            <div className="card-container dashboard-card">
              <div style={{
                float: "left",
                display: "flex",
                padding: "24px 0 24px 24px",
                color: "rgb(0, 80, 117)"
              }}>
                <div style={{
                  float: "left",
                  width: "40px",
                  padding: "2px 0"
                }}>
                  <FontAwesomeIcon icon={faUserLock} className="font-1xl" style={{ fontSize: '24px !important' }} />
                </div>
                <div style={{
                  float: "left",
                  padding: "0",
                  maxWidth: "180px"
                }}><p style={{
                  fontSize: "24px",
                  fontFamily: "Cairo-SemiBold",
                  lineHeight: "24px",
                  marginBottom: "10px"
                }}>{dictionaryGet(dictionary, "global.console.authorizations.title")}</p>
                  <p style={{
                    color: "rgb(157, 166, 173)",
                    fontSize: "14px"
                  }}>{dictionaryGet(dictionary, "global.console.authorizations.description")}</p></div>
              </div>
              <div className="card-right"><a href={'#/autorizzazioni'}><FontAwesomeIcon icon={faArrowRight} style={{ fontSize: "20px" }} /></a></div>
            </div>
          </a>
        </Col>
        <Col xs="12" med="6" sm="6" xl="3">
          <a href={'#/verifica-autorizzazioni'}>
            <div className="card-container dashboard-card">
              <div style={{
                float: "left",
                display: "flex",
                padding: "24px 0 24px 24px",
                color: "rgb(0, 80, 117)"
              }}>
                <div style={{
                  float: "left",
                  width: "40px",
                  padding: "2px 0"
                }}>
                  <FontAwesomeIcon icon={faUserCheck} className="font-1xl" style={{ fontSize: '24px !important' }} />
                </div>
                <div style={{
                  float: "left",
                  padding: "0",
                  maxWidth: "180px"
                }}><p style={{
                  fontSize: "24px",
                  fontFamily: "Cairo-SemiBold",
                  lineHeight: "24px",
                  marginBottom: "10px"
                }}>{dictionaryGet(dictionary, "global.console.authorization_verification.title")}</p>
                  <p style={{
                    color: "rgb(157, 166, 173)",
                    fontSize: "14px"
                  }}>{dictionaryGet(dictionary, "global.console.authorization_verification.description")}</p></div>
              </div>
              <div className="card-right"><a href={'#/verifica-autorizzazioni'}><FontAwesomeIcon icon={faArrowRight} style={{ fontSize: "20px" }} /></a></div>
            </div>
          </a>
        </Col>

      </>
    );
  };

  const renderDownloadCard = () => {
    if (selectedCompany !== null) {
      if (selectedCompany.service.findIndex((serv) => { return serv.code === SERVICE_CODES.BI }) !== -1) {
        return (
          <Col xs="12" med="6" sm="6" xl="3">
            <a href={'#/download'}>
              <div className="card-container dashboard-card">
                <div style={{
                  float: "left",
                  display: "flex",
                  padding: "24px 0 24px 24px",
                  color: "rgb(0, 80, 117)"
                }}>
                  <div style={{
                    float: "left",
                    width: "40px",
                    padding: "2px 0"
                  }}>
                    <FontAwesomeIcon icon={faDownload} className="font-1xl" style={{ fontSize: '24px !important' }} />
                  </div>
                  <div style={{
                    float: "left",
                    padding: "0",
                    maxWidth: "180px"
                  }}><p style={{
                    fontSize: "24px",
                    fontFamily: "Cairo-SemiBold",
                    lineHeight: "24px",
                    marginBottom: "10px"
                  }}>{dictionaryGet(dictionary, "global.console.download.title")}</p>
                    <p style={{
                      color: "rgb(157, 166, 173)",
                      fontSize: "14px"
                    }}>{dictionaryGet(dictionary, "global.console.download.description")}</p></div>
                </div>
                <div className="card-right"><a href={'#/download'}><FontAwesomeIcon icon={faArrowRight} style={{ fontSize: "20px" }} /></a></div>
              </div>
            </a>
          </Col>
        );
      }
    }
  };

  const renderToast = () => {
    if (showInvitationsToast) {
      return (

        <UncontrolledAlert className="alert-dashboard" color="primary"  >
          <Row>
            <Col xs="10"><div className="alert-text"><FontAwesomeIcon icon={faInfoCircle} size="1x" style={{ color: "rgb(0, 80, 117)", marginTop: "-2px", marginRight: "20px" }} />{dictionaryGet(dictionary, "global.console.invitations.invitations_toast")}</div></Col>
            <Col xs="2"><div className="alert-buttons"><a href={'#/inviti'}><Button color="primary" size="md">{dictionaryGet(dictionary, "global.console.invitations.title").toUpperCase()}</Button></a></div></Col>
          </Row>
        </UncontrolledAlert>
      );
    }
  };

  const renderOwnerActivation = () => {
    return (
      <Row>
        <Col md="12" style={{ marginTop: '36px' }}>
          <div className="alert-dashboard" style={{ padding: '0.9rem', backgroundColor: 'white', fontSize: '16px', border: '1px solid #e8e8e8' }}>
            <div style={{ marginLeft: "5px" }} className="box-title"><strong>{dictionaryGet(dictionary, "global.console.owner_user")}</strong></div>
            <Row>
              <Col xs="6"><div style={{ marginLeft: "5px" }} className="alert-text">{dictionaryGet(dictionary, "global.console.owner_licenses_message")}</div></Col>
              <Col xs="6">
                <div style={{ marginRight: "5px" }} className="alert-buttons">
                  {canOwnerActivate ? <Button onClick={() => { setIsModalOpen(true) }} style={{ width: "80%" }} className="btn-ambiti" color="primary" size="md">{dictionaryGet(dictionary, "global.console.owner_licenses_activate")}</Button> :
                    <Button disabled style={{ width: "80%" }} className="btn-ambiti" color="primary" size="md">Non puoi attivare licenze per il proprietario finché non è stata installato il pacchetto BI</Button>}
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    );
  }

  const closeModal = (idCompany: number) => {
    thunkDispatch(canActivateOwner(idCompany)).then((res: any) => {
      setIsModalOpen(false);
      setCanUserActivate(res.payload.data);
    });
  }

  const welcome = (userData: any) => {
    let message = dictionaryGet(dictionary, "global.console.dashboard_welcome");
    let key = { "userFullName": userData.firstName + ' ' + userData.lastName };
    message = stringTemplateReplace(message, key);
    return message;
  };

  return (
    <div style={{ padding: "20px" }}>
      <ActivateLicensesDialog selectedCompany={selectedCompany} closeModal={closeModal} isModalOpen={isModalOpen} />
      <Row style={{ marginTop: "-40px" }}>
        <Col>
          <div className="card-container dashboard-claim">
            <div className="card-body">
              <Row>
                <Col sm="8">
                  <h4 className="simple-kpi-head">Dashboard</h4>
                  {currentUser.user !== null
                    ?
                    <HTMLPurify html={welcome(currentUser.user)} />
                    : ""
                  }
                </Col>
                <Col sm="4">

                </Col>
              </Row>
            </div>
          </div>

        </Col>
      </Row>

      <Row>
        <Col md="12" style={{ marginTop: '20px' }}>
          {renderToast()}
        </Col>

      </Row>
      <Row>
        {welcomeCards()}
      </Row>
      {canUserActivate ? renderOwnerActivation() : ""}
    </div>
  );
}
