import React, { useEffect, useState } from 'react';
import Switch from 'react-switch';
import { Button, Col, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { USER_TYPE_AS_API } from '../../common/constants';
import { IInvitation } from '../../store/invitations/types';
import { ISelectedCompany, IService } from '../../store/user/types';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AppState } from '../../store';
import { AnyAction } from 'redux';
import { updateUser } from '../../actions/user/actions';
import { dictionaryGet } from '../../common/functions';

type Props = {
  isModalOpen: boolean,
  closeModal: Function,
  serviceCode: string,
  selectedService: IService,
  selectedCompany: ISelectedCompany,
  editUser: IInvitation | undefined
}

export default function ModaleModifica(props: Props) {
  const dictionary = useSelector((state: AppState) => state.dictionary);
  const thunkDispatch = useDispatch<ThunkDispatch<AppState, any, AnyAction>>();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isOwner, setIsOwner] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);

  useEffect(() => {
    if (props.editUser !== undefined) {
      if (props.editUser.userType === USER_TYPE_AS_API.ADMINISTRATOR) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
      setIsOwner(props.editUser.isOwner);
      setIsActive(props.editUser.active);
    }
  }, [props.editUser]);

  const submitForm = () => {
    let userType = USER_TYPE_AS_API.USER;
    if (isAdmin) {
      userType = USER_TYPE_AS_API.ADMINISTRATOR
    }
    if (props.editUser !== undefined) {
      thunkDispatch(updateUser(props.editUser.id, isActive, userType)).then(() => {
        props.closeModal();
      });
    }
  };

  const renderSoluzioni = () => {
    if (props.editUser !== undefined) {
      return props.editUser.license.map((lice) => {
        return <span key={lice.id} className="single-tag-blue">{lice.name}</span>
      });
    }
  };

  const renderForm = () => {
    if (props.editUser !== undefined) {
      return (
        <Form className="form-inviti">
        <Row>
          <Col md="6">
            <FormGroup>
              <Label for="nome" className="label-form-inviti">{dictionaryGet(dictionary, "global.console.invitations.first_name")}:</Label>
              <p>
                <Label>{props.editUser.firstName}</Label>
              </p>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label for="cognome" className="label-form-inviti">{dictionaryGet(dictionary, "global.console.invitations.last_name")}:</Label>
              <p>
              <Label>{props.editUser.lastName}</Label>
              </p>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <FormGroup>
              <Label for="email" className="label-form-inviti">E-mail:</Label>
              <Label>{props.editUser.email}</Label>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <FormGroup>
              <Switch
                className="switch-component"
                style={{ marginRight: '10px' }}
                height={22}
                width={48}
                disabled={isOwner}
                onColor={'#415ca3'}
                checked={isOwner ? false : isAdmin}
                onChange={() => {
                  setIsAdmin(!isAdmin)
                }}
              />
              <span className="label-form-inviti" style={{
                marginLeft: "15px",
                marginRight: "30px"
              }}>{isAdmin ? "Amministratore" : "Non Amministratore"}</span>
              <Switch
                className="switch-component"
                disabled
                style={{ marginLeft: '20px' }}
                height={22}
                width={48}
                onColor={'#415ca3'}
                checked={isOwner}
                onChange={() => {
                }}
              />
              <span className="label-form-inviti" style={{ marginLeft: "15px" }}>{"Proprietario"}</span>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <FormGroup>
              <Label for="email" className="label-form-inviti">{dictionaryGet(dictionary, "global.console.invitations.solutions")}:</Label>
              {renderSoluzioni()}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <FormGroup>

              <Switch
                className="switch-component"
                style={{ marginRight: '10px' }}
                height={22}
                width={48}
                onColor={'#415ca3'}
                checked={isActive}
                disabled={isOwner}
                onChange={() => { setIsActive(!isActive) }}
              />
              <span className="label-form-inviti" style={{ marginLeft: "15px" }}>{dictionaryGet(dictionary, "global.console.active")}</span>
            </FormGroup>
          </Col>
        </Row>
        </Form>
      );
    } else {
      return null;
    }
  };

  return (
    <Modal isOpen={props.isModalOpen} toggle={() => {
      props.closeModal()
    }} size='md' style={{ width: 'auto', height: 'auto', maxWidth: '40vw' }}>
      <ModalHeader toggle={() => {
        props.closeModal()
      }}>{props.selectedService.name + " - Modifica Utente"}</ModalHeader>
      <ModalBody style={{ marginLeft: '15px', marginRight: '15px' }}>
        <Row style={{ marginBottom: '25px' }}>
          <Col md="12"><span>Azienda selezionata: <strong>{props.selectedCompany.company.name}</strong></span></Col>
        </Row>
        {renderForm()}
      </ModalBody>
      <ModalFooter>
        <Button className="btn-form-secondary" onClick={() => {
          props.closeModal()
        }}>{dictionaryGet(dictionary, "global.console.cancel")}</Button>
        <Button className="btn-form-primary" color="primary" onClick={() => {
          submitForm();
        }}>{dictionaryGet(dictionary, "global.console.save")}</Button>
      </ModalFooter>
    </Modal>
  )
}
