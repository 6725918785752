import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { rootReducer } from './store';
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import Proxy from './containers/Proxy/Proxy';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/custom.scss';
import 'react-sortable-tree/style.css';

const createStoreWithMiddleware = applyMiddleware(thunk)(
  createStore
);
ReactDOM.render(
  <Provider store={createStoreWithMiddleware(rootReducer)}>
    <HashRouter>
      <Switch>
        <Route path='/' component={Proxy} />
      </Switch>
    </HashRouter>
  </Provider>, document.getElementById('root'));

serviceWorker.unregister();
