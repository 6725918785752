import React, { useEffect, useState } from "react";
import { Row, Col, Button, Spinner } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { getInstallerDescription, getInstallerQlik, getInstallerQlikVersion, getInstallerBi, getBIManualUrl, getBIUtils } from '../../actions/download/actions';
import { INSTALLER_CODE, SERVICE_CODES } from '../../common/constants';
import { Redirect } from 'react-router';
import { dictionaryGet } from '../../common/functions';

interface Installer {
  BI: any,
  QLIK: any;
}

export function Download() {
  const current = useSelector((state: AppState) => state.user.current);
  const thunkDispatch = useDispatch<ThunkDispatch<AppState, any, AnyAction>>();
  const [installers, setInstallers] = useState<Installer | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dictionary = useSelector((state: AppState) => state.dictionary);

  useEffect(() => {
    let installerObj: any = {};
    if (current.selectedCompany !== null) {
      let idCompany = current.selectedCompany.company.id;
      thunkDispatch(getInstallerDescription(INSTALLER_CODE.BI, idCompany)).then((ress: any) => {
        thunkDispatch(getInstallerDescription(INSTALLER_CODE.QLIK, idCompany)).then((res: any) => {
          installerObj.QLIK = JSON.parse(res.data);
          installerObj.BI = JSON.parse(ress.data);
          setInstallers(installerObj);
        });
      });
    }
  }, [current.selectedCompany]);

  const downloadBiManual = () => {
    if (current.selectedCompany !== null) {
      let idCompany = current.selectedCompany.company.id;
      getBIManualUrl(idCompany).then((ress: any) => {
        window.open(ress.data);
      });
    }
  };

  const downloadBIUtils = () => {
    if (current.selectedCompany !== null) {
      let idCompany = current.selectedCompany.company.id;
      getBIUtils(idCompany).then((ress: any) => {
        window.open(ress.data);
      });
    }
  };

  const downlaodVersion = (type: INSTALLER_CODE, fileName: "") => {
    if (current.selectedCompany !== null) {
        return thunkDispatch(getInstallerQlikVersion(current.selectedCompany.company.id, fileName)).then((res: any) => {
          window.open(res.payload.data);
        });
    }
  };

  const downlaod = (type: INSTALLER_CODE) => {
    if (current.selectedCompany !== null) {
      if (type === INSTALLER_CODE.BI) {
        setIsLoading(true);
        return thunkDispatch(getInstallerBi(current.selectedCompany.company.id, current.selectedCompany.company.guid))
          .then((res: any) => {
            setIsLoading(false);
          });
      } else {
        return thunkDispatch(getInstallerQlik(current.selectedCompany.company.id)).then((res: any) => {
          window.open(res.payload.data);
        });
      }
    }
  };

  const renderQlikHistory = () => {
    if (installers != null && installers.QLIK.legacy != null && installers.QLIK.legacy.length > 0) {
      

      return (
        <>
          <span className="qlik-history">
            <a href='#/download-qlik-history'>Download versioni precedenti</a><br />
          </span>
          <p></p>
        </>
      );
    }
  };

  const renderCards = () => {
    if (installers !== null) {
      return (
        <>
          <Row style={{ marginBottom: "30px" }}>
            <Col>
              <div className="card-container card-download">
                <div className="card-download body">
                  <div className="div-logo">
                    <img src={process.env.PUBLIC_URL + "/images/installers/BI.png"}></img>
                  </div>
                  <div className="div-description">
                    <p style={{
                      color: "rgb(0, 80, 117)",
                      fontFamily: "Cairo-SemiBold",
                      fontSize: "24px"
                    }}>TS Enterprise BI 2019/2022</p>
                    <p>{dictionaryGet(dictionary, "global.console.download.version")} {installers.BI.version}</p>
                  </div>
                </div>
                <div className="div-download">
                  <p className="box-bottom"><Button disabled={isLoading} onClick={() => { downlaod(INSTALLER_CODE.BI); }} className="btn-primary-viola" color="primary" size="md">{isLoading ? <Spinner style={{ width: "1rem", height: "1rem" }} /> : <><FontAwesomeIcon style={{ marginRight: "5px" }} icon={faDownload} />{dictionaryGet(dictionary, "global.console.download.download")}</>}</Button></p>
                </div>
              </div>
            </Col>
          </Row>
          <Row style={{ marginBottom: "30px" }}>
            <Col>
              <div className="card-container card-download">
                <div className="card-download body">
                  <div className="div-logo">

                  </div>
                  <div className="div-description">
                    <p style={{
                      color: "rgb(0, 80, 117)",
                      fontFamily: "Cairo-SemiBold",
                      fontSize: "24px"
                    }}>BI Utilities</p>
                    <p>{dictionaryGet(dictionary, "global.console.download.version")} {installers.BI.biUtilitiesVersion}</p>
                  </div>
                </div>
                <div className="div-download">
                  <p className="box-bottom"><Button disabled={isLoading} onClick={() => { downloadBIUtils() }} className="btn-primary-viola" color="primary" size="md">{isLoading ? <Spinner style={{ width: "1rem", height: "1rem" }} /> : <><FontAwesomeIcon style={{ marginRight: "5px" }} icon={faDownload} />{dictionaryGet(dictionary, "global.console.download.download")}</>}</Button></p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="card-container card-download">
                <div className="card-download body">
                  <div className="div-logo">

                  </div>
                  <div className="div-description">
                    <p style={{
                      color: "rgb(0, 80, 117)",
                      fontFamily: "Cairo-SemiBold",
                      fontSize: "24px"
                    }}>{dictionaryGet(dictionary, "global.console.guide")}</p>
                    <p>{dictionaryGet(dictionary, "global.console.download.version")} {installers.BI.version}</p>
                  </div>
                </div>
                <div className="div-download">
                  <p className="box-bottom"><Button disabled={isLoading} onClick={() => { downloadBiManual() }} className="btn-primary-viola" color="primary" size="md">{isLoading ? <Spinner style={{ width: "1rem", height: "1rem" }} /> : <><FontAwesomeIcon style={{ marginRight: "5px" }} icon={faDownload} />{dictionaryGet(dictionary, "global.console.download.open")}</>}</Button></p>
                </div>
              </div>
            </Col>
          </Row>
          
        </>
      );
    } else {
      return <Spinner />;
    }
  };

  if (current.selectedCompany !== null) {
    if (current.selectedCompany.service.findIndex((serv) => { return serv.code === SERVICE_CODES.BI; }) !== -1) {
      return (
        <>
        <div style={{ padding: "20px 20px 0 20px" }}>
          <div style={{ marginBottom: "20px" }}>{dictionaryGet(dictionary, "global.console.download.page_caption")}</div>
        </div>
        {renderQlikHistory()}
        <div style={{ padding: "20px" }}>
          {renderCards()}
        </div>
        </>
      );
    } else {
      return <Redirect to="/home" />;
    }
  } else {
    return (
      <div style={{ padding: "20px" }}>
        <div>{dictionaryGet(dictionary, "global.console.download.page_caption")}</div>

        {renderCards()}

      </div>
    );
  }
}
