import React, { useEffect, useState } from 'react';
import {
  Button,
  ButtonDropdown,
  Col,
  CustomInput,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Spinner,
  Table,
  UncontrolledButtonDropdown,
  Label,
  Popover,
  PopoverHeader,
  PopoverBody,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisH,
  faFilter,
  faSearch,
  faSort,
  faSortDown,
  faSortUp,
  faTrashAlt,
  faEdit,
  faAngleLeft,
  faAngleRight
} from '@fortawesome/free-solid-svg-icons';
import 'react-table/react-table.css';
import { ISelectedCompany, IService } from '../../store/user/types';
import ModaleInviti from '../../components/Inviti/ModaleInviti';
import { useDispatch, useSelector } from 'react-redux';
import { getLicenses, searchInvitations, sendInvitation, countInvitations, getEnvironment, setEnvironment } from '../../actions/invitations/actions';
import { AppState } from '../../store';
import { IInvitation, ILicense } from '../../store/invitations/types';
import { ORDER_BY, ORDER_DIR, USER_TYPE, USER_TYPE_AS_API } from '../../common/constants';
import { formatDateFromTimestamp } from '../../common/functions';
import DeleteConfirmationModal from '../../components/Inviti/DeleteConfirmationModal';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import ModaleRiepilogo from '../../components/Inviti/ModaleRiepilogo';
import NoInvitationsSelected from '../../components/Inviti/NoInvitationsSelected';
import { dictionaryGet } from '../../common/functions';

type Props = {
  selectedService: IService,
  selectedCompany: ISelectedCompany;
};

export default function InvitiPage(props: Props): JSX.Element {
  const thunkDispatch = useDispatch<ThunkDispatch<AppState, any, AnyAction>>();
  const user = useSelector((state: AppState) => state.user.current);
  const [searchText, setSearchText] = useState<string>('');
  const [isModaleInvitiOpen, setModaleInvitiOpen] = useState<boolean>(false);
  const [availableLicensesDropdown, setAvailableLicensesDropdown] = useState<boolean>(false);
  const [usedLicenseDropdown, setusedLicenseDropdown] = useState<boolean>(false);
  const licenses = useSelector((state: AppState) => state.invitations.licenses);
  const inviti = useSelector((state: AppState) => state.invitations.invitations.data);
  const [checkArray, setCheckArray] = useState<Array<boolean>>([false]);
  const [masterCheck, setMasterCheck] = useState<boolean>(false);
  const [dropSoluzioni, setDropSoluzioni] = useState<boolean>(false);
  const [dropTipo, setDropTipo] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [editInvite, setEditInvite] = useState<any>({});
  const [solutionsFilterArray, setSolutionsFilterArray] = useState<Array<any>>([]);
  const [userTypeFilterArray, setUserTypeFilterArray] = useState<Array<any>>([
    { value: false, label: 'ADMINISTRATOR' },
    { value: false, label: 'USER' }
  ]);
  const [queryString, setQueryString] = useState<string>('');
  const [typeFilter, setTypeFilter] = useState<string>('');
  const [solutionFilter, setSolutionsFilter] = useState<string>('');
  const [isDeleteModalOpen, setDeleteModal] = useState<boolean>(false);
  const [orderFiltersArray, setOrderFiltersArray] = useState<Array<number>>([0, 0, 0]);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [isModaleRiepilogoOpen, setModaleRiepilogoOpen] = useState<boolean>(false);
  const [payloadArray, setPayloadArray] = useState<Array<number>>([]);
  const [visArray, setVisPayloadArray] = useState<Array<IInvitation>>([]);

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [firstIndex, setFirstIndex] = useState<number>(0);
  const [lastIndex, setLastIndex] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [pagFirstIndex, setPagFirstIndex] = useState<number>(0);
  const [pagLastIndex, setPagLastIndex] = useState<number>(0);
  const [hidePagination, setHidePagination] = useState<boolean>(false);
  const [baseUrl, setBaseUrl] = useState<string>("");
  const [appBaseUrl, setAppBaseUrl] = useState<string>("");
  const [baseUrldropdownOpen, setBaseUrldropdownOpen] = useState<boolean>(false);
  const [errorModalOpen, setErrorModal] = useState<boolean>(false);
  const dictionary = useSelector((state: AppState) => state.dictionary);

  const calculatePagesFromCount = (count: number) => {
    let totalePagine = Math.ceil(count / 9);
    setTotalPages(totalePagine);
    setLastIndex(8);
    setPagLastIndex(8);
  };

  const doSearch = () => {
    if (user.selectedCompany !== null && props.selectedService !== null) {
      let selectedCompanyId = user.selectedCompany.company.id;
      thunkDispatch(countInvitations(props.selectedService.id, user.selectedCompany.company.id, currentPage + 1, queryString)).then((res: any) => {
        if (res.payload.data > 0) {
          calculatePagesFromCount(res.payload.data);
        }
        thunkDispatch(searchInvitations(props.selectedService.id, selectedCompanyId, currentPage + 1, queryString));
      });
    }
  };

  useEffect(() => {
    doSearch();
    thunkDispatch(getEnvironment(props.selectedService.id, props.selectedCompany.company.id)).then((res: any) => {
      setAppBaseUrl(res.payload.data.baseUrl);
      setBaseUrl(res.payload.data.baseUrl);
    });
    if (user.selectedCompany !== null && props.selectedService !== null) {
      thunkDispatch(getLicenses(props.selectedService.code, user.selectedCompany.company.id));
      // thunkDispatch(searchInvitations(props.selectedService.id, user.selectedCompany.company.id, 1));
    }
  }, [props.selectedService]);

  useEffect(() => {
    doSearch();
  }, [queryString]);

  useEffect(() => {
    doSearch();
  }, [currentPage]);

  useEffect(() => {
    if (inviti.length > 0) {
      let arrayCheckAppoggio: boolean[] = [];
      inviti.map((invito) => {
        if (invito.isUsed !== null) {
          arrayCheckAppoggio.push(false);
        }
      });
      setCheckArray(arrayCheckAppoggio);
    }
  }, [inviti]);

  useEffect(() => {
    if (licenses.data !== null && licenses.isLoaded) {
      let obj = {
        value: false,
        label: ''
      };
      let array: any[] = [];
      licenses.data.map((lice, i) => {
        obj = {
          value: false,
          label: lice.name
        };
        array[i] = obj;
        obj = { value: false, label: '' };
      });
      setSolutionsFilterArray(array);
    }
  }, [licenses]);

  useEffect(() => {
    if (inviti.filter((invito) => { return invito.isUsed; }).length !== inviti.length) {
      setHidePagination(false);
    } else {
      setHidePagination(true);
    }
  }, [inviti]);

  const renderAvailableLicenseDropdown = () => {
    if (licenses.data !== null) {
      let dropItems = licenses.data.map((lice, i) => {
        return (
          <React.Fragment key={i}>
            <DropdownItem><span>{lice.name}</span><span
              style={{ color: 'rgb(119, 188, 92)', marginLeft: "30px" }}><b>{lice.totalLicences}</b></span></DropdownItem>
          </React.Fragment>
        );
      });
      return (
        <ButtonDropdown className="dropdown-licenze" isOpen={availableLicensesDropdown} toggle={() => { setAvailableLicensesDropdown(!availableLicensesDropdown); }}>
          <DropdownToggle>
            <FontAwesomeIcon icon={faEllipsisH} />
          </DropdownToggle>
          <DropdownMenu right>
            {dropItems}
          </DropdownMenu>
        </ButtonDropdown>
      );
    }
  };

  const renderUsedLicensesDropdown = () => {
    if (licenses.data !== null) {
      let dropItems = licenses.data.map((lice, i) => {
        return (
          <React.Fragment key={i}>
            <DropdownItem key={i}><span>{lice.name}</span><span style={{
              color: 'rgb(218, 88, 72)',
              marginLeft: "30px"
            }}><b>{lice.totalLicences - lice.tempBalance}</b></span></DropdownItem>
          </React.Fragment>
        );
      });
      return (
        <ButtonDropdown className="dropdown-licenze" isOpen={usedLicenseDropdown} toggle={() => {
          setusedLicenseDropdown(!usedLicenseDropdown);
        }}>
          <DropdownToggle>
            <FontAwesomeIcon icon={faEllipsisH} />
          </DropdownToggle>
          <DropdownMenu right>
            {dropItems}
          </DropdownMenu>
        </ButtonDropdown>
      );
    }
  };

  const infoLicenze = () => {
    let avail = 0;
    let used = 0;
    if (licenses.data !== null) {
      licenses.data.map((license: ILicense, i) => {
        avail = avail + license.totalLicences;
        used = license.totalLicences - license.tempBalance;
      });
    }
    const totaleSpan: JSX.Element = <span style={{ color: 'rgb(119, 188, 92)' }}><b>{avail}</b></span>;
    const usedSpan: JSX.Element = <span style={{ color: 'rgb(218, 88, 72)' }}><b>{used}</b></span>;
    return (
      <Row className="riga-licenze">
        <Col>
          <div className="div-licenze">
            <strong>{ dictionaryGet(dictionary, "global.console.invitations.total_licenses") }</strong>
            <span className="div-numero-licenze">
              {totaleSpan}
              {renderAvailableLicenseDropdown()}
            </span>
          </div>
        </Col>
        <Col>
          <div className="div-licenze">
            <strong>{ dictionaryGet(dictionary, "global.console.invitations.used_licenses") }</strong>
            <span className="div-numero-licenze">
              {usedSpan}
              {renderUsedLicensesDropdown()}
            </span>
          </div>
        </Col>
      </Row>
    );
  };

  const cercaUtente = () => {
    applyFilters(0);
  };

  const resetAllFilters = () => {
    let orderClone = [...orderFiltersArray];
    orderClone.fill(0);
    setUserTypeFilterArray([{ value: false, label: 'ADMINISTRATOR' }, { value: false, label: 'USER' }]);
    setOrderFiltersArray(orderClone);
    setSearchText('');
    setQueryString('');
  };

  const renderFilter = (idx: number) => {
    switch (orderFiltersArray[idx]) {
      case 0:
        return faSort;
      case 1:
        return faSortDown;
      case 2:
        return faSortUp;
      default:
        return faSort;
    }
  };

  console.log(isModaleInvitiOpen);


  const IntefacciaRicerca = () => {
    return (
      <Row className="riga-utenti">
        <Col style={{ display: 'inline-flex', maxWidth: "180px" }} xs="2" md="2" lg="2" xl="2">
          <Button className="btn-form-blue" color="primary" size="md" onClick={() => {
            setIsEdit(false);
            setModaleInvitiOpen(true);
          }}>{ dictionaryGet(dictionary, "global.console.invitations.new_invitation") }</Button>
        </Col>
        <Col style={{ display: 'inline-flex', padding: "0px" }} xs="8" md="8" lg="7" xl="8">
          <Col md="10" lg="8" style={{ padding: "0px", maxWidth: "400px" }}>
            <Input value={searchText} className="input-cerca-inviti" name="nominativo" id="nominativo"
              placeholder={ dictionaryGet(dictionary, "global.console.invitations.search") } onChange={(e) => {
                setSearchText(e.target.value);
              }} />
          </Col>
          <Col md="1" lg="2" style={{ padding: "0px", maxWidth: "80px" }}>
            <Button className="btn-form-blue-cerca" color="primary" size="md"
              onClick={() => cercaUtente()}><FontAwesomeIcon icon={faSearch} className="font-1xl" /></Button>
          </Col>
        </Col>
        <Col md="1" xs="2" lg="2" xl="2" style={{ padding: "0px" }}>
          <div className="div-btn-right">
            <Button className="btn-form-secondary interfaccia" color="secondary" size="md" onClick={() => {
              resetAllFilters();
            }}>{ dictionaryGet(dictionary, "global.console.invitations.remove_filters") }</Button>
          </div>
        </Col>
      </Row>
    );
  };

  const applyFilters = (idx: number) => {
    let stringPayload = queryString;
    let esito = orderFiltersArray.some((val) => {
      return val > 0;
    });
    let usTypeFilter = '';
    let solFilter = '';
    let orderFilter = '';
    let typeOrderFilter = '';
    let search = '';

    userTypeFilterArray.map((val, i) => {
      if (val.value) {
        usTypeFilter = '&userType=' + val.label;
      }
    });

    setTypeFilter(typeFilter);

    if (searchText.length > 1) {
      search = '&name=' + searchText;
    }

    solutionsFilterArray.map((solution) => {
      if (solution.value) {
        solFilter = '&license=' + solution.label;
      }
    });

    if (esito === true) {
      orderFiltersArray.map((val, i) => {
        if (val > 0) {
          if (val === 1) {
            orderFilter = '&orderDir=' + ORDER_DIR[0];
            typeOrderFilter = '&orderBy=' + ORDER_BY[i];
          }
          if (val === 2) {
            orderFilter = '&orderDir=' + ORDER_DIR[1];
            typeOrderFilter = '&orderBy=' + ORDER_BY[i];
          }
        }
      });
    }

    stringPayload = usTypeFilter + solFilter + typeOrderFilter + orderFilter + search;

    setSolutionsFilter(solFilter);
    setQueryString(stringPayload);

    if (idx === 1) {
      setDropTipo(!dropTipo);
    }
    if (idx === 2) {
      setDropSoluzioni(!dropSoluzioni);
    }
  };

  const checkAll = () => {
    let copyArray: boolean[] = [...checkArray];
    copyArray.fill(!masterCheck);
    setCheckArray(copyArray);
    setMasterCheck(!masterCheck);
  };

  const handleChangeFiltro = (e: any, i: number, idx: number) => {
    e.nativeEvent.stopImmediatePropagation();
    e.preventDefault();
    e.stopPropagation();
    if (idx === 1) {
      let array = [...userTypeFilterArray];
      array.map((val) => {
        val.value = false;
      });
      array[i].value = !array[i].value;
      setUserTypeFilterArray(array);
    }
    if (idx === 2) {
      let array = [...solutionsFilterArray];
      array[i].value = !array[i].value;
      setSolutionsFilterArray(array);
    }
  };

  const toggleDrop = (e: any, idx: number) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    if (idx === 1) {
      setDropSoluzioni(!dropSoluzioni);
      setDropTipo(false);
    }
    if (idx === 2) {
      setDropSoluzioni(false);
      setDropTipo(!dropTipo);
    }
  };

  const resetTypeFilters = () => {
    let clone = [...userTypeFilterArray];
    clone.map((res) => {
      res.value = false;
    });
    let cloneSolutions = [...solutionsFilterArray];
    cloneSolutions.map((sol) => {
      sol.value = false;
    });
    setSolutionsFilter('');
    setSolutionsFilterArray(cloneSolutions);
    setTypeFilter('');
    setUserTypeFilterArray(clone);
    setOrderFiltersArray([0, 0, 0]);
    applyFilters(1);
  };

  const resetSolutionFilter = () => {
    let clone = [...solutionsFilterArray];
    clone.map((res) => {
      res.value = false;
    });
    setSolutionsFilter('');
    setSolutionsFilterArray(clone);
    applyFilters(2);
  };

  const dropDownSoluzioni = () => {
    if (solutionsFilterArray.length > 0) {
      return (
        <ButtonDropdown toggle={() => {
        }} isOpen={dropSoluzioni} className="dropdown-filtro">
          <DropdownToggle onClick={(e) => {
            toggleDrop(e, 1);
          }}>
            <FontAwesomeIcon icon={faFilter} />
          </DropdownToggle>
          <DropdownMenu className="dropmenu-filtro">
            {licenses.data !== null && licenses.isLoaded ?
              licenses.data.map((license, i) => {
                return (
                  <DropdownItem key={i} className="drop-item">
                    <div className="item-filtro">
                      <span>
                        <CustomInput checked={solutionsFilterArray[i] != undefined ? solutionsFilterArray[i].value : false} value={license.name} type="checkbox"
                          id={"check-sol" + i} onChange={(e) => {
                            handleChangeFiltro(e, i, 2);
                          }} />
                      </span>
                      <span className="opzione-filtro">{license.name}</span>
                    </div>
                  </DropdownItem>
                );
              }) : ''}
            <div className="drop-menu-controls">
              <Button className="secondary" onClick={() => {
                applyFilters(2);
              }}>Ok</Button>
              <Button className="secondary" onClick={() => {
                resetSolutionFilter();
              }}>Reset</Button>
            </div>
          </DropdownMenu>
        </ButtonDropdown>
      );
    }
  };

  const dropDownTipo = () => {
    return (
      <ButtonDropdown toggle={() => {
      }} isOpen={dropTipo} className="dropdown-filtro">
        <DropdownToggle onClick={(e) => {
          toggleDrop(e, 2);
        }}>
          <FontAwesomeIcon icon={faFilter} />
        </DropdownToggle>
        <DropdownMenu className="dropmenu-filtro">
          {userTypeFilterArray.length > 0 ?
            userTypeFilterArray.map((usrType: any, i) => {
              return (
                <DropdownItem key={i} className="drop-item">
                  <div className="item-filtro">
                    <span>
                      <CustomInput checked={usrType.value} value={usrType.label} type="radio" id={"check-user" + i}
                        onChange={(e) => {
                          handleChangeFiltro(e, i, 1);
                        }} />
                    </span>
                    <span className="opzione-filtro">{USER_TYPE[usrType.label]}</span>
                  </div>
                </DropdownItem>
              );
            }) : ''}
          <div className="drop-menu-controls">
            <Button className="secondary" onClick={() => {
              applyFilters(1);
            }}>Ok</Button>
            <Button className="secondary" onClick={() => {
              resetTypeFilters();
            }}>Reset</Button>
          </div>
        </DropdownMenu>
      </ButtonDropdown>
    );
  };

  useEffect(() => {
    applyFilters(0);
  }, [orderFiltersArray]);

  const setOrderFilter = (idx: number) => {
    let clone = [...orderFiltersArray];

    switch (clone[idx]) {
      case 0:
        clone.fill(0);
        clone[idx] = 1;
        break;
      case 1:
        clone.fill(0);
        clone[idx] = 2;
        break;
      case 2:
        clone.fill(0);
        clone[idx] = 0;
        break;
    }
    setOrderFiltersArray(clone);
  };

  const TableHeader = (): JSX.Element => {
    return (
      <thead>
        <tr>
          <th><CustomInput id="check-table-00" type="checkbox" checked={masterCheck} onChange={() => { checkAll(); }} /></th>
          <th><span>{ dictionaryGet(dictionary, "global.console.invitations.first_name") }</span></th>
          <th><span>{ dictionaryGet(dictionary, "global.console.invitations.last_name") }</span><span onClick={() => { setOrderFilter(0); }}><FontAwesomeIcon className="icona-filtro" icon={renderFilter(0)} /></span></th>
          <th><span>Email</span><span onClick={() => { setOrderFilter(1); }}><FontAwesomeIcon className="icona-filtro" icon={renderFilter(1)} /></span></th>
          <th style={{ textAlign: "center" }}><span>{ dictionaryGet(dictionary, "global.console.invitations.type") }</span>{dropDownTipo()}</th>
          <th><span>{ dictionaryGet(dictionary, "global.console.invitations.solutions") }</span>{dropDownSoluzioni()}</th>
          <th style={{ textAlign: "center" }}><span>{ dictionaryGet(dictionary, "global.console.invitations.sent") }</span><span onClick={() => { setOrderFilter(2); }}><FontAwesomeIcon className="icona-filtro" icon={renderFilter(2)} /></span></th>
          <th></th>
        </tr>
      </thead>
    );
  };

  const productsTD = (invito: IInvitation) => {
    if (invito.license.length > 1) {
      const dropItems = () => {
        for (let i = 1; i < invito.license.length; i++) {
          const lice = invito.license[i];
          return (
            <React.Fragment key={i}>
              <DropdownItem className="drop-item-solutions">
                <span className="single-tag-blue">{lice.name}</span>
              </DropdownItem>
            </React.Fragment>
          );
        }
      };

      return (
        <>
          <span className="single-tag-blue">{invito.license.length>0?invito.license[0].name:""}</span>
          <UncontrolledButtonDropdown direction="right">
            <DropdownToggle className="more-solutions">
              <FontAwesomeIcon icon={faEllipsisH} />
            </DropdownToggle>
            <DropdownMenu className="dropmenu-filtro">
              <DropdownItem className="drop-item-solutions">Altre</DropdownItem>
              {dropItems()}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </>
      );
    } else {
      return <span className="single-tag-blue">{invito.license.length>0?invito.license[0].name:""}</span>;
    }
  };

  const handleChange = (e: any, i: any) => {
    let arrayCopia: boolean[] = [...checkArray];
    arrayCopia[i] = !arrayCopia[i];
    setCheckArray(arrayCopia);
  };

  const userType = (userType: string) => {
    if (userType === USER_TYPE_AS_API.ADMINISTRATOR) {
      return <span className="single-tag-red">{USER_TYPE.ADMINISTRATOR}</span>;
    } else {
      return '';
    }
  };

  const paginaSelezionata = (i: any) => {
    let pagFindex = pagFirstIndex;
    let pagLindex = pagLastIndex;
    if (i === 'prev') {
      if (currentPage === 0) {
        i = 0;
      } else {
        i = currentPage - 1;
        if (i < pagFirstIndex) {
          pagFindex = pagFindex - 1;
          pagLindex = pagLindex - 1;
        }
      }
    }

    if (i === 'next') {
      if (currentPage === totalPages) {
        i = totalPages;
      } else {
        i = currentPage + 1;
        if (i >= pagLastIndex) {
          pagFindex = pagFindex + 1;
          pagLindex = pagLindex + 1;
        }
      }
    }

    let fIndex = (i * 8);
    let lIndex = fIndex + 7;

    setCurrentPage(i);
    setFirstIndex(fIndex);
    setLastIndex(lIndex);
    setPagFirstIndex(pagFindex);
    setPagLastIndex(pagLindex);
    renderPagination();
  };

  const pagination = () => {
    let buttons = [];
    if (totalPages < 1) {
      buttons.push(<button className={'btn-pagine-active'} key={1} id={'btn' + 1} onClick={() => {
        paginaSelezionata(0);
      }}>{1}</button>);
    }
    for (let i = 0; i < totalPages; i++) {
      buttons.push(
        <button className={currentPage === i ? 'btn-pagine-active' : 'btn-pagine'} key={i} id={'btn' + i}
          onClick={() => {
            paginaSelezionata(i);
          }}>{i + 1}</button>
      );
    }
    return buttons;
  };

  const renderPagination = () => {
    let buttons = pagination();
    let serie = [];
    for (let i = pagFirstIndex; i < pagLastIndex; i++) {
      serie.push(buttons[i]);
    }
    return serie;
  };

  const TableBody = () => {
    if (inviti.length > 0) {
      if (inviti.filter((invito) => { return invito.isUsed; }).length !== inviti.length) {
        return inviti.map((invito, i) => {
          if (!invito.isUsed) {
            return (
              <tr key={i}>
                <td scope="row">{invito.isUsed ? '' :
                  <CustomInput type="checkbox" checked={checkArray[i] != undefined ? checkArray[i] : false} id={"check-table" + i} key={'check-table' + i} onChange={(e) => { handleChange(e, i); }} />}</td>
                <td>{invito.firstName}</td>
                <td>{invito.lastName}</td>
                <td>{invito.email}</td>
                <td style={{ textAlign: "center" }}>{userType(invito.userType)}</td>
                <td>{productsTD(invito)}</td>
                <td
                  style={{ textAlign: "center" }}>{invito.lastSend !== null ? formatDateFromTimestamp(invito.lastSend) : '-'}</td>
                <td style={{ textAlign: "center" }}><Button disabled={invito.isUsed ? true : invito.lastSend !== null ? true : false} onClick={() => {
                  setEditInvite(invito);
                  setIsEdit(true);
                  setModaleInvitiOpen(!isModaleInvitiOpen);
                }} className="btn-modifica" style={{ marginRight: "5px" }} type="button"><FontAwesomeIcon icon={faEdit} /></Button><Button
                  onClick={() => { setEditInvite(invito); setDeleteModal(true); }} className="btn-secondario-danger" type="button"><FontAwesomeIcon icon={faTrashAlt} /></Button>
                </td>
              </tr>
            );
          }
        });

      } else {
        return (
          <tr>
            <th colSpan={8} style={{ textAlign: "center" }}>{ dictionaryGet(dictionary, "global.console.invitations.no_invitations")}</th>
          </tr >
        );
      }
    } else {
      return (
        <tr>
          <th colSpan={8} style={{ textAlign: "center" }}>{ dictionaryGet(dictionary, "global.console.invitations.no_invitations")}</th>
        </tr>
      );
    }
  };

  const thunkSendInviteWrapper = (arrayPayload: any) => {
    thunkDispatch(sendInvitation(arrayPayload, props.selectedService.id, props.selectedCompany.company.id, 1, queryString)).then(() => {
      setIsSending(false);
    });
  };

  const sendInvites = () => {

    let arrayPayload: number[] = [];
    let visArray: any[] = [];

    if (!checkArray.includes(true)) {
      setErrorModal(true);
    } else {
      if (checkArray.length > 0) {
        checkArray.map((check: boolean, i: number) => {
          if (check) {
            arrayPayload.push(inviti[i].id);
            visArray.push(inviti[i]);
          }
        });
        if (arrayPayload.length > 0) {
          setPayloadArray(arrayPayload);
          setVisPayloadArray(visArray);
        }
      }

      if (props.selectedService.code === "AN") {
        setIsSending(true);
        let invitationsDto = {
          inviteIds: arrayPayload
        };
        thunkSendInviteWrapper(invitationsDto);
      } else {
        setModaleRiepilogoOpen(true);
      }
    }
  };

  const chiudiModale = () => {
    if (user.selectedCompany !== null && props.selectedService !== null) {
      thunkDispatch(getLicenses(props.selectedService.code, user.selectedCompany.company.id));
      thunkDispatch(searchInvitations(props.selectedService.id, user.selectedCompany.company.id, 1));
    }
    setModaleInvitiOpen(false);
  };

  const renderTable = (): JSX.Element => {
    let paginazione = <Col className="text-right col-12">
      <button className="btn-pagine-left" key={'prev'} id="prev" onClick={() => { paginaSelezionata('prev'); }}><FontAwesomeIcon icon={faAngleLeft} /></button>
      {renderPagination()}
      <button className="btn-pagine-right" key={'next'} id="next" onClick={() => { paginaSelezionata('next'); }}><FontAwesomeIcon icon={faAngleRight} /></button>
    </Col>;
    return (
      <>
        <Table>
          <TableHeader />
          <tbody>
            {TableBody()}
          </tbody>
        </Table>
        {hidePagination ? "" : paginazione}
      </>
    );
  };

  const chiudiServiceBaserUrlModal = () => {
    setModaleRiepilogoOpen(false);
  };

  const saveBaseUrl = () => {
    thunkDispatch(setEnvironment(baseUrl, props.selectedService.id, props.selectedCompany.company.id)).then(() => {
      thunkDispatch(getEnvironment(props.selectedService.id, props.selectedCompany.company.id)).then((res: any) => {
        setAppBaseUrl(res.payload.data.baseUrl);
        setBaseUrl(res.payload.data.baseUrl);
        setBaseUrldropdownOpen(false);
      });
    });
  };

  const renderBaseUrlDropdown = () => {
    let button = document.getElementById("btn-invia");
    if (button !== null) {
      button.style.borderBottomRightRadius = "0";
      button.style.borderTopRightRadius = "0";
    }

    return (
      <>
        <Button id="PopoverUrl" className="drop-toggle-baseurl" color="primary" size="md"><FontAwesomeIcon icon={faEllipsisH} /></Button>
        <Popover placement="top-start" isOpen={baseUrldropdownOpen} target="PopoverUrl" toggle={() => { setBaseUrldropdownOpen(!baseUrldropdownOpen); }}>
          <PopoverHeader><strong>{ dictionaryGet(dictionary, "global.console.invitations.invitation_link") }</strong></PopoverHeader>
          <PopoverBody>
            <Label>{ dictionaryGet(dictionary, "global.console.invitations.application_path") }</Label>
            <Input className="input-base-url" onChange={(e: any) => { e.stopPropagation(); setBaseUrl(e.target.value); }} value={baseUrl} />
            <div className="controls-pop-env">
              <Button className="btn-form btn-annulla" color="primary" size="md" onClick={() => { setBaseUrldropdownOpen(false); setBaseUrl(appBaseUrl); }}>{ dictionaryGet(dictionary, "global.console.cancel") }</Button>
              <Button className="btn-form" color="primary" size="md" onClick={() => { saveBaseUrl(); }}>{ dictionaryGet(dictionary, "global.console.save") }</Button>
            </div>
          </PopoverBody>
        </Popover>
      </>
    );
  };

  return (
    <div>
      <NoInvitationsSelected isModalOpen={errorModalOpen} toggleModal={setErrorModal} />
      <DeleteConfirmationModal queryString={queryString} editInvite={editInvite} selectedService={props.selectedService}
        selectedCompany={props.selectedCompany} isModalOpen={isDeleteModalOpen}
        toggleModal={setDeleteModal} />
      <ModaleInviti queryString={queryString} selectedService={props.selectedService}
        selectedCompany={props.selectedCompany} isEdit={isEdit} editInvite={editInvite}
        serviceCode={props.selectedService.code} isModalOpen={isModaleInvitiOpen}
        toggleModal={chiudiModale} />
      <ModaleRiepilogo baseURL={baseUrl} visArray={visArray} payloadArray={payloadArray} selectedCompany={props.selectedCompany} selectedService={props.selectedService}
        toggleModal={chiudiServiceBaserUrlModal} isModalOpen={isModaleRiepilogoOpen} />
      {infoLicenze()}
      {IntefacciaRicerca()}
      <Row className="riga-console-tabella">
        <Col className="colonna-console-tabella" xs="12" md="12">
          {renderTable()}
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <Button id="btn-invia" className="btn-form-primary" style={{ padding: "8px 25px" }} color="primary" size="md"
            onClick={() => { sendInvites(); }}>
            {props.selectedService.code == "AN" ? isSending ? <Spinner size="sm" /> : dictionaryGet(dictionary, "global.console.invitations.send") :
           dictionaryGet(dictionary, "global.console.invitations.send")}
            
            
          </Button>
          {props.selectedService.code == "BI" ? renderBaseUrlDropdown() : ""}
        </Col>
        <Col md="6">
        </Col>
      </Row>
    </div >
  );
}
