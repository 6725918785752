import React from 'react';
import { ISelectedCompany } from '../../store/user/types';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { useSelector } from "react-redux";
import { AppState } from "../../store";

type Props = {
  activeTab: number,
  selectedCompany: ISelectedCompany,
  changeService: Function;
};

export default function ServiceNav(props: Props) {
  const currentService = useSelector((state: AppState) => state.user.currentService);

  const renderNavs = () => {
    if (props.selectedCompany != null) {
      if (props.selectedCompany.service !== null) {
        return props.selectedCompany.service.map((service, i) => {
          return (
            <NavItem key={i}>
              <NavLink
                className={'utenti ' + classnames({ active: props.activeTab === i })}
                onClick={() => { props.changeService(i); }}
              >
                {service.name}
              </NavLink>
            </NavItem>
          );
        });
      }
    }
  };

  return (
    <Nav tabs >
      {renderNavs()}
    </Nav>
  );
}
