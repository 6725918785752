import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../store';
import { faBuilding, faCheck } from '@fortawesome/free-solid-svg-icons';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { switchDefaultCompany, switchSelectedCompany, fetchServicesList } from '../../actions/user/actions';
import { ICompanies } from '../../store/user/types';

export default function DropdownAziende() {
  const selectedAzienda = useSelector((state: AppState) => state.user.current.selectedCompany);
  const userCurrent = useSelector((state: AppState) => state.user.current);
  const [dropdownOpen, setDropdown] = useState<boolean>(false);
  const thunkDispatch = useDispatch<ThunkDispatch<AppState, any, AnyAction>>();

  const creaListaAziende = (x: boolean) => {
    return userCurrent.companies.map((soc: ICompanies, i) => {
      if (userCurrent.selectedCompany !== null) {
        return (
          <DropdownItem key={i} onClick={() => { setSelectedCompany(soc.company.id) }}>
            {userCurrent.selectedCompany.company.id == soc.company.id ? <span><FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} /></span> : <span style={{ minWidth: "20px" }}></span>}
            <div style={{ width: "90%", float: "left" }}>{soc.company.name}<br /><span style={{"fontSize":"10px"}}>CF: {soc.company.fiscalCode}</span></div>
            {/* <span style={{ float: "right" }} id={"pin-icon" + i} onClick={(e) => { setDefaultCompany(e, soc) }} ><FontAwesomeIcon icon={faThumbtack} className={"font-1xl pinCompany " + (userCurrent.selectedCompany.company.id == soc.company.id && soc.isDefault ? "defaultCompany" : "")} style={{ float: "right", marginTop: "4px", marginLeft: '10px' }} /></span>
                        <UncontrolledTooltip className="tooltip-company" innerClassName="inner-tooltip-company" arrowClassName="arrow-tooltip-company" placement="bottom" target={"pin-icon" + i}>
                            Imposta come azienda di default
            </UncontrolledTooltip> */}
          </DropdownItem>
        )
      }
    });
  }

  const setDefaultCompany = (e: any, soc: ICompanies) => {
    e.stopPropagation();
    if (soc.service === null) {
      if (soc.isOwner) {
        thunkDispatch(fetchServicesList(soc.company.id)).then(() => {
          userCurrent.companies.map((comp: ICompanies) => {
            if (comp.company.id === soc.company.id) {
              thunkDispatch(switchDefaultCompany(comp.service.code, comp.company.id));
            }
          });
        });
      }
    } else {
      thunkDispatch(switchDefaultCompany(soc.service.code, soc.company.id));
    }
  }

  const setSelectedCompany = (id: number) => {
    thunkDispatch(switchSelectedCompany(id));
  }

  if (selectedAzienda !== null) {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return (
        <Dropdown inNavbar isOpen={dropdownOpen} toggle={() => { setDropdown(!dropdownOpen) }} className="lista-aziende-drop">
          <DropdownToggle nav caret style={{ color: 'white', fontSize: '15px' }}>
            {selectedAzienda.company.name}
          </DropdownToggle>
          <DropdownMenu>
            {creaListaAziende(false)}
          </DropdownMenu>
        </Dropdown>
      );
    } else {
      return (
        <Dropdown inNavbar isOpen={dropdownOpen} toggle={() => { setDropdown(!dropdownOpen) }} className="lista-aziende-drop">
          <DropdownToggle caret style={{ color: 'white', fontSize: '15px' }} >
            <FontAwesomeIcon icon={faBuilding} className="font-2xl" style={{ color: '#FFFFFF' }}></FontAwesomeIcon> {selectedAzienda.company.name}
          </DropdownToggle>
          <DropdownMenu>
            {creaListaAziende(true)}
          </DropdownMenu>
        </Dropdown>
      );
    }
  } else {
    return null;
  }
}
