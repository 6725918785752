import React, { useEffect, useState } from "react";
import { ISelectedCompany } from "../../store/user/types";
import { Button, Col, Input, Row, Spinner, Table } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import { IObject } from "../../store/catalog/types";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { getObjectCatalog, getObjectAuthorizedPrincipal } from "../../actions/authgroups/actions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import { dictionaryGet } from "../../common/functions";

type Props = {
  selectedService: any,
  selectedCompany: ISelectedCompany
}

export default function VerificaAuthPage(props: Props) {
  const dictionary = useSelector((state: AppState) => state.dictionary);
  const thunkDispatch = useDispatch<ThunkDispatch<AppState, any, AnyAction>>();
  const [searchText, setSearchText] = useState<string>("");
  const [userSearchText, setUserSearchText] = useState<string>("");
  const [selectedCatalog, setSelectedCatalog] = useState<any>();
  const catalog = useSelector((state: AppState) => state.catalog.data);
  const [filteredCatalog, setFilteredCatalog] = useState<any[]>([]);
  const [filteredUsersList, setFilteredUsersList] = useState<any[]>([]);
  const [userList, setUserList] = useState<Array<any>>([]);
  const [orderFiltersArray, setOrderFiltersArray] = useState<Array<number>>([0, 0, 0]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [displayOverlay, setDisplayOverlay] = useState<boolean>(true);

  useEffect(() => {
    loadCatalog();
  }, []);

  useEffect(() => {
    loadCatalog();
    setUserList([]);
    setFilteredCatalog([]);
    let div = document.getElementById("scrollable-catalaog");
    if (div !== null) {
      div.scrollTop = 0;
    }
  }, [props.selectedService]);

  const loadCatalog = () => {
    // if (props.selectedCompany !== null && props.selectedService !== null) {
    thunkDispatch(getObjectCatalog(props.selectedCompany.company.id, props.selectedService.id));
    // }
  }

  const applyFilters = () => {

  };

  const searchInterfaceCatalog = () => {
    return (
      <Col md={12}>
        <span> {dictionaryGet(dictionary, "global.console.authorization_verification.verification_instructions")}</span><br />
        <Row style={{ marginTop: "10px" }}>
          <Col md={12}>
            <Input value={searchText} className="input-cerca" name="nominativo" id="nominativo"
              placeholder={dictionaryGet(dictionary, "global.console.authorization_verification.search_scopes_kpi")} onChange={(e) => {
                setSearchText(e.target.value);
              }} />
          
            <Button className="btn-form-blue-cerca" color="primary" size="md" onClick={() => (searchCatalog())}>
              <FontAwesomeIcon icon={faSearch} className="font-1xl" />
            </Button>
          </Col>
        </Row>
      </Col>
    );
  };

  const searchCatalog = () => {
    let regx = new RegExp(searchText, "i");
    let res = catalog[props.selectedService.id].filter((cat: any) => cat !== null).filter((cata: any) => cata.description !== null).filter((ca: any) => ca.description.match(regx));
    setFilteredCatalog(res);
  };

  const searchUsers = () => {
    let res = userList.filter((usr: any) => usr !== null).filter((user: any) => !user.lastName.search(userSearchText) || !user.firstName.search(userSearchText) || !user.email.search(userSearchText));
    setFilteredUsersList(res);
  }

  const scopeOrKpi = (objectType: string) => {
    switch (objectType) {
      case "Scope":
        return dictionaryGet(dictionary, "global.console.authorization_verification.scope");
      case "Kpi":
        return dictionaryGet(dictionary, "global.console.authorization_verification.kpi");
      case "sheet":
        return dictionaryGet(dictionary, "global.console.authorization_verification.sheet");
      case "app":
        return dictionaryGet(dictionary, "global.console.authorization_verification.app");
    }
  }

  const renderSearchUserInterface = () => {
    if (userList.length > 0) {
      return (
        <Col md={12}>
          <span> {dictionaryGet(dictionary, "global.console.authorization_verification.users_can_view")} {scopeOrKpi(selectedCatalog.objectType) + selectedCatalog.description} </span><br />
          <Row style={{ marginTop: "10px" }}>
            <Col md={12}>
              <Input value={userSearchText} className="input-cerca" name="nominativo" id="nominativo"
                placeholder={dictionaryGet(dictionary, "global.console.authorizations.search")} onChange={(e) => {
                  setUserSearchText(e.target.value);
                }} />

              <Button className="btn-form-blue-cerca" color="primary" size="md"
                onClick={() => (searchUsers())}><FontAwesomeIcon icon={faSearch}
                  className="font-1xl" /></Button>
            </Col>
          </Row>
        </Col>
      );
    }
  };

  const openAuth = (catalog: any) => {
    setDisplayOverlay(false);
    setSelectedCatalog(catalog);
    setLoading(true);
    if (props.selectedCompany.company.id && props.selectedService.id) {
      thunkDispatch(getObjectAuthorizedPrincipal(props.selectedCompany.company.id, props.selectedService.id, catalog.id)).then((res) => {
        setLoading(false);
        setUserList(res.payload.data);
      });
    }
  }

  const renderCatalog = (objCatal: any) => {
    return objCatal.map((catal: IObject, i: number) => {
      let className = 'span-cat-object-hoverable level' + catal.level;
      className += selectedCatalog !== undefined ? selectedCatalog.id == catal.id ? " selected" : "" : "";
      return (
        <div onClick={() => { openAuth(catal); }} key={i} className={className} style={{ marginLeft: 20 * catal.level, fontWeight: catal.level == 1 || catal.level == 0 ? "bold" : "normal" }}>
          <span>
            {catal.description}
          </span>

        </div>
      );
    });
  }

  const renderObjectCatalog = () => {
    if (filteredCatalog.length > 0) {
      if (filteredCatalog !== undefined) {
        return renderCatalog(filteredCatalog);
      }
    } else {
      if (Object.keys(catalog).length > 0) {
        if (catalog[props.selectedService.id] !== undefined && catalog[props.selectedService.id].length > 0) {
          return renderCatalog(catalog[props.selectedService.id]);
        } else {
          return <Spinner />;
        }
      } else {
        return <Spinner />;
      }
    };
  }

  const renderUsers = (usersArray: any[]) => {
    return usersArray.map((user: any, i: number) => {
      return (
        <tr key={i}>
          <td>{user.firstName}</td>
          <td>{user.lastName}</td>
          <td>{user.email}</td>
          <td>{user.isInvite ? <FontAwesomeIcon icon={faEnvelope as IconProp} /> : ""}</td>
        </tr>
      );
    });
  }

  const renderTableBody = () => {
    if (filteredUsersList.length > 0) {
      return renderUsers(filteredUsersList);
    } else {
      if (userList.length > 0) {
        return renderUsers(userList);
      }
    }
  };

  // const setOrderFilter = (idx: number) => {
  //   let clone = [...orderFiltersArray];

  //   switch (clone[idx]) {
  //     case 0:
  //       clone.fill(0);
  //       clone[idx] = 1;
  //       break;
  //     case 1:
  //       clone.fill(0);
  //       clone[idx] = 2;
  //       break;
  //     case 2:
  //       clone.fill(0);
  //       clone[idx] = 0;
  //       break;
  //   }
  //   setOrderFiltersArray(clone);
  // };

  // const renderFilter = (idx: number) => {
  //   switch (orderFiltersArray[idx]) {
  //     case 0:
  //       return faSort;
  //     case 1:
  //       return faSortDown;
  //     case 2:
  //       return faSortUp;
  //     default:
  //       return faSort;
  //   }
  // };

  const TableHeader = (): JSX.Element => {
    return (
      <thead>
        <tr>
          <th><span>{dictionaryGet(dictionary, "global.console.invitations.first_name")}</span></th>
          <th>
            <span>{dictionaryGet(dictionary, "global.console.invitations.last_name")}</span>
            {/* <span onClick={() => { setOrderFilter(0) }}>
              <FontAwesomeIcon className="icona-filtro" icon={renderFilter(0)} />
            </span> */}
          </th>
          <th>
            <span>Email</span>
            {/* <span onClick={() => { setOrderFilter(1) }}>
              <FontAwesomeIcon className="icona-filtro" icon={renderFilter(1)} />
            </span> */}
          </th>
        </tr>
      </thead>
    );
  };

  const renedrUserRelated = () => {
    return (
      <>
        {renderSearchUserInterface()}
        <div style={{ marginTop: "20px" }}>
          <div className="div-catalog-verifica">
            {renderTable()}
          </div>
        </div>
      </>
    );
  }

  const renderTable = () => {
    if (isLoading) {
      return (
        <>
          <div style={{ textAlign: "center", padding: "30%" }}>
            <Spinner size="80" />
          </div>
        </>
      );
    } else {
      return (
        <>
          <Table>
            <TableHeader />
            <tbody>
              {renderTableBody()}
            </tbody>
          </Table>
        </>
      );
    }
  };

  return (
    <>
      <Row>
        <Col md={6}>
          {searchInterfaceCatalog()}
          <div style={{ marginTop: "20px" }}>
            <div className="catalog-title-verifica">Ambiti e KPI</div>
            <div className="div-catalog-verifica" id="scrollable-catalaog">
              {renderObjectCatalog()}
            </div>
          </div>
        </Col>
        <Col md={6}>
          <div id="overlay" className={"overlay show"} style={{ display: displayOverlay ? "unset" : "none" }}></div>
          {renedrUserRelated()}
        </Col>
      </Row>
    </>
  );
}
