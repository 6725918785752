export const LANGUAGES = [
  { value: 'it-IT', label: 'Italiano' },
  { value: 'en-GB', label: 'English' },
  { value: 'de-DE', label: 'Deutsch' },
  { value: 'es-ES', label: 'Español'}
];

export function getLanguageByCode(langCode:string) {
  const l = LANGUAGES.find(o => o.value === langCode);
  return l;
}
