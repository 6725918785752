import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export default function Loading() {
  return (
    <div className="loading-chart-kpi">
      <FontAwesomeIcon icon={faSpinner} className="loading-spinner" spin />
    </div>
  );
}