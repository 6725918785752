import React, { useEffect } from "react";
import { Switch, Route, HashRouter, Redirect } from 'react-router-dom';
import Login from '../Login/Login';
import Main from "../Main/Main";
import { setAccessTokenCookie } from '../../common/functions';
import ErrorPage from "../Error/ErrorPage";
import InvitationAccept from '../Main/InvitationAccept';

export default function Proxy(props: any) {

  useEffect(() => {
    let url = props.location.search;
    const urls = url.split('?auth=');
    if (urls[1] !== undefined) {
      setAccessTokenCookie(urls[1]);
    }
  }, [props.location.searh]);

  return (
    <div id="portal-main">
      <div className="main-body">
        <HashRouter>
          <Switch>
            <Route path="/status" name="Status"><h3>Service is up and running</h3></Route>
            <Route path='/login' name='login' render={()=><Redirect to="/dashboard"/>}/>
            <Route path="/error-page" name="Errore" component={ErrorPage} />
            <Route path="/invitation-accept" name="Home" exact component={InvitationAccept} />
            <Route path="/" component={Main} />
          </Switch>
        </HashRouter>
      </div>
    </div>
  );
}
