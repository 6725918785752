import { AppState } from '../../store/index';
import { Actions } from '../index';
import { TUserActions } from './types';
import axios from 'axios';
import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { URL, SERVICE_CODE } from '../../common/constants';
import { setIdTokenCookie, getAccessTokenCookie, removeAccessTokenCookie, checkAuthState, removeAccessTokenCookieWithoutRedirect, getRefreshTokenCookie, setAccessTokenCookie, removeRefreshTokenCookie } from '../../common/functions';
// import { createHeader, getMobileUrlCookie } from '../../common/functions';
// declare var aptrinsic: any;

export function userCount(serviceId: number, companyId: number, page: number, otherProps?: string): ThunkAction<Promise<any>, AppState, {}, AnyAction> {
  const AuthStr = 'Bearer '.concat(getAccessTokenCookie());
  const url = otherProps !== undefined ? `${URL.API_URL}/user/count/${serviceId}/${companyId}?${otherProps}` : `${URL.API_URL}/user/search/${serviceId}/${companyId}`;
  return async (dispatch: ThunkDispatch<AppState, {}, AnyAction>): Promise<any> => {
    return axios.get(url, { headers: { Authorization: AuthStr, 'Content-Type': 'application/json' } }).then((response) => {
      return dispatch(userCountSuccess(response));
    }).catch((error) => {
      if (error.response) {
        if (error.response.status == 403) {
          refreshToken(userCount(serviceId, companyId, page, otherProps), dispatch);
        }
      }
      return dispatch(userCountFailure(error));
    });
  };
}

function userCountSuccess(response: any): any {
  return {
    type: Actions.USER_COUNT_SUCCESS,
    payload: response.data
  };
}

function userCountFailure(error: any): any {
  return {
    type: Actions.USER_COUNT_FAILURE,
    payload: error.response
  };
}

export function userSearch(serviceId: number, companyId: number, page: number, otherProps?: string): ThunkAction<Promise<any>, AppState, {}, AnyAction> {
  const AuthStr = 'Bearer '.concat(getAccessTokenCookie());
  const url = otherProps !== undefined ? `${URL.API_URL}/user/search/${serviceId}/${companyId}?page=${page}${otherProps}` : `${URL.API_URL}/user/search/${serviceId}/${companyId}?page=${page}`;
  return async (dispatch: ThunkDispatch<AppState, {}, AnyAction>): Promise<any> => {
    return axios.get(url, { headers: { Authorization: AuthStr, 'Content-Type': 'application/json' } }).then((response) => {
      return dispatch(searchUsersSuccess(response));
    }).catch((error) => {
      if (error.response) {
        if (error.response.status == 403) {
          refreshToken(userSearch(serviceId, companyId, page, otherProps), dispatch);
        }
      }
      return dispatch(searchUsersFailure(error));
    });
  };
}

function searchUsersSuccess(response: any): TUserActions {
  return {
    type: Actions.USER_SEARCH_SUCCESS,
    payload: response.data
  };
}

function searchUsersFailure(error: any): TUserActions {
  return {
    type: Actions.USER_SEARCH_FAILURE,
    payload: error.response
  };
}

export function userLogin(tsid: string, password: string, invToken?: string): ThunkAction<Promise<void>, AppState, {}, AnyAction> {
  let url = "";
  if (invToken != undefined) {
    url = `${URL.API_URL}/user/login?invtoken=${invToken}`;
  } else {
    url = `${URL.API_URL}/user/login`;
  }
  return async (dispatch: ThunkDispatch<AppState, {}, AnyAction>): Promise<void> => {
    return axios.post(url, {
      email: tsid,
      password: password,
      serviceCode: SERVICE_CODE.ADMIN
    }).then((response: any) => {
      if (response.data) {
        if (response.data.result === "NOK") {
          dispatch(userLoginFailure(response.data));
        } else {
          dispatch(userLoginSuccess(response));
        }
      }
    }).catch((error) => {
      dispatch(userLoginError(error));
    });
  };
}

function userLoginSuccess(response: any): TUserActions {
  return {
    type: Actions.LOGIN_SUCCESS,
    payload: response.data
  };
}

function userLoginError(error: any): TUserActions {
  // removeAccessTokenCookie()
  return {
    type: Actions.LOGIN_ERROR,
    payload: error.response
  };
}

function userLoginFailure(response: any): TUserActions {
  // removeAccessTokenCookie()
  return {
    type: Actions.LOGIN_FAILURE,
    payload: response
  };
}

export function userCurrent(): ThunkAction<Promise<void>, AppState, {}, AnyAction> {
  const url = `${URL.API_URL}/user/${SERVICE_CODE.ADMIN}/current`;
  const AuthStr = 'Bearer '.concat(getAccessTokenCookie());
  return async (dispatch: ThunkDispatch<AppState, {}, AnyAction>): Promise<void> => {
    return axios.post(url, null, { headers: { Authorization: AuthStr, 'Content-Type': 'application/json' } }).then((response) => {

      let defaultCompany = response.data.data.companies[0].company;
      for (let i = 0; i < response.data.data.companies.length; i++) {
        if (response.data.data.companies[i].isDefault) {
          defaultCompany = response.data.data.companies[i].company;
        }
      }


      // aptrinsic('identify', {
      //   'id': response.data.data.user.id,
      //   'email': response.data.data.user.teamsystemId,
      //   'firstName': response.data.data.user.firstName,
      //   'lastName': response.data.data.user.lastName,
      //   "userHash": response.data.data.user.guid,
      // }, {
      //   'id': defaultCompany.id,
      //   'name': defaultCompany.name
      // });

      dispatch(userCurrentSuccess(response));
    }).catch((error) => {
      console.log(error);
      if (error.response) {
        if (error.response.status == 403) {
          refreshToken(userCurrent(), dispatch);
        };
        if (error.response.status == 401) {
          dispatch(setErrore("not_active_console"));
        };
      };
      // dispatch(userCurrentFailure(error));
      // checkAuthState(error.response.status);
    });
  };
}

function userCurrentSuccess(response: any): TUserActions {
  return {
    type: Actions.CURRENT_SUCCESS,
    payload: response.data
  };
}

function userCurrentFailure(error: any): TUserActions {
  // document.location.href = '#/login'
  // removeAccessTokenCookie();
  return {
    type: Actions.CURRENT_FAILURE,
    payload: error.response
  };
}

export function fetchServicesList(companyId: number): ThunkAction<Promise<any>, AppState, {}, AnyAction> {
  const url = `${URL.API_URL}/service/list/${companyId}`;
  const AuthStr = 'Bearer '.concat(getAccessTokenCookie());
  return async (dispatch: ThunkDispatch<AppState, {}, AnyAction>): Promise<any> => {
    return axios.get(url, { headers: { Authorization: AuthStr, 'Content-Type': 'application/json' } }).then((response) => {
      dispatch(fetchServicesListSuccess(response));
    }).catch((error) => {
      if (error.response) {
        if (error.response.status == 403) {
          refreshToken(fetchServicesList(companyId), dispatch);
        }
      }
      dispatch(fetchServicesListFailure(error));
      checkAuthState(error.response.status);
    });
  };
}

export function resetServiceList(): any {
  return {
    type: Actions.RESET_SERVICES_LIST,
  };
}

function fetchServicesListSuccess(response: any): TUserActions {
  return {
    type: Actions.FETCH_SERVICES_LIST_SUCCESS,
    payload: response.data
  };
}

function fetchServicesListFailure(error: any): TUserActions {
  // document.location.href = '#/login'
  return {
    type: Actions.FETCH_SERVICES_LIST_FAILURE,
    payload: error.response
  };
}

export function updateUser(userId: number, isActive: boolean, userType: String): ThunkAction<Promise<void>, AppState, {}, AnyAction> {
  const url = `${URL.API_URL}/user/update/${userId}`;
  const AuthStr = 'Bearer '.concat(getAccessTokenCookie());
  let data = {
    active: isActive,
    userType: userType
  };
  return async (dispatch: ThunkDispatch<AppState, {}, AnyAction>): Promise<any> => {
    return axios.put(url, data, { headers: { Authorization: AuthStr } }).then((response) => {
      // if (response.data.result === "OK") {
      return dispatch(updateUserSuccess(response));
      // } else {
      //   return response.data;
      // }
    }).catch((error) => {
      if (error.response) {
        if (error.response.status == 403) {
          refreshToken(updateUser(userId, isActive, userType), dispatch);
        }
      }
      dispatch(updateUserFailure(error));
    });
  };
}

function updateUserSuccess(response: any): TUserActions {
  return {
    type: Actions.UPDATE_USER_SUCCESS,
    payload: response.data
  };
}

function updateUserFailure(error: any): TUserActions {
  return {
    type: Actions.UPDATE_USER_FAILURE,
    payload: error.response
  };
}

export function deleteUser(userId: Number): ThunkAction<Promise<void>, AppState, {}, AnyAction> {
  const url = `${URL.API_URL}/user/delete/${userId}`;
  const AuthStr = 'Bearer '.concat(getAccessTokenCookie());
  return async (dispatch: ThunkDispatch<AppState, {}, AnyAction>): Promise<void> => {
    return axios.delete(url, { headers: { Authorization: AuthStr } }).then((response) => {
      dispatch(deleteUserSuccess(response));
    }).catch((error) => {
      if (error.response) {
        if (error.response.status == 403) {
          refreshToken(deleteUser(userId), dispatch);
        }
      }
      dispatch(deleteUserFailure(error));
    });
  };
}

function deleteUserSuccess(response: any): TUserActions {
  return {
    type: Actions.DELETE_USER_SUCCESS,
    payload: response.data
  };
}

function deleteUserFailure(error: any): TUserActions {
  return {
    type: Actions.DELETE_USER_FAILURE,
    payload: error.response
  };
}

export function switchDefaultCompany(serviceCode: string, companyId: number): ThunkAction<Promise<void>, AppState, {}, AnyAction> {
  const AuthStr = 'Bearer '.concat(getAccessTokenCookie());
  const url = `${URL.API_URL}/user/${serviceCode}/default_company/${companyId}`;
  return async (dispatch: ThunkDispatch<AppState, {}, AnyAction>): Promise<void> => {
    return axios.get(url, { headers: { Authorization: AuthStr } }).then((response) => {
      dispatch(userCurrent());
    }).catch((error) => {
      if (error.response) {
        if (error.response.status == 403) {
          refreshToken(switchDefaultCompany(serviceCode, companyId), dispatch);
        }
      }
    });
  };
}

export function switchSelectedCompany(companyId: number) {
  return {
    type: Actions.SWITCH_SELECTED_COMPANY,
    payload: companyId
  };
}

export function userActivateOwner(userCompanyId: number): ThunkAction<Promise<void>, AppState, {}, AnyAction> {
  const url = `${URL.API_URL}/user/activate_owner/${userCompanyId}`;
  const AuthStr = 'Bearer '.concat(getAccessTokenCookie());
  return async (dispatch: ThunkDispatch<AppState, {}, AnyAction>): Promise<any> => {
    return axios.get(url, { headers: { Authorization: AuthStr, 'Content-Type': 'application/json' } }).then((response) => {
      return dispatch(userActivateOwnerSuccess(response));
    }).catch((error) => {
      dispatch(userActivateOwnerFailure(error));
      if (error.response) {
        if (error.response.status == 403) {
          refreshToken(userActivateOwner(userCompanyId), dispatch);
        }
      }
    });
  };
}

function userActivateOwnerSuccess(response: any): TUserActions {
  return {
    type: "",
    payload: response.data
  };
}

function userActivateOwnerFailure(error: any): TUserActions {
  return {
    type: "",
    payload: error.response
  };
}

export function canActivateOwner(userCompanyId: number): ThunkAction<Promise<void>, AppState, {}, AnyAction> {
  const url = `${URL.API_URL}/user/can_activate_owner/${userCompanyId}`;
  const AuthStr = 'Bearer '.concat(getAccessTokenCookie());
  return async (dispatch: ThunkDispatch<AppState, {}, AnyAction>): Promise<any> => {
    return axios.get(url, { headers: { Authorization: AuthStr, 'Content-Type': 'application/json' } }).then((response) => {
      return dispatch(canActivateOwnerSuccess(response));
    }).catch((error) => {
      dispatch(canActivateOwnerFailure(error));
      if (error.response) {
        if (error.response.status == 403) {
          refreshToken(canActivateOwner(userCompanyId), dispatch);
        };
      }
    });
  };
}

function canActivateOwnerSuccess(response: any): TUserActions {
  return {
    type: "",
    payload: response.data
  };
}

function canActivateOwnerFailure(error: any): TUserActions {
  return {
    type: "",
    payload: error.response
  };
}

export function setCurrentService(tab: number): any {
  return {
    type: Actions.SET_CURRENT_SERVICE,
    payload: tab
  };
}

export function userLogout(): ThunkAction<Promise<any>, AppState, {}, AnyAction> {
  const url = `${URL.API_URL}/user/logout`;
  const AuthStr = 'Bearer '.concat(getAccessTokenCookie());
  return async (dispatch: ThunkDispatch<AppState, {}, AnyAction>): Promise<void> => {
    return axios.get(url, { headers: { Authorization: AuthStr } })
      .then(() => { dispatch(userLogoutSuccess()); })
      .catch((error) => {
        dispatch(userLogoutFailure());
        if (error.response) {
          if (error.response.status == 403) {
            refreshToken(userLogout(), dispatch);
          }
        }
      });
  };
}

function userLogoutSuccess(): TUserActions {
  return {
    type: Actions.LOGOUT,
    payload: null
  };
}

function userLogoutFailure(): TUserActions {
  return {
    type: Actions.LOGOUT,
    payload: null
  };
}

export function refreshToken(action: any, dispatch: any) {
  let url = `${URL.API_URL}/jwt`;
  let AuthStr = 'Bearer '.concat(getRefreshTokenCookie());
  axios.get(url, { headers: { Authorization: AuthStr } }).then((response) => {
    setAccessTokenCookie(response.data.data.token);
    dispatch(action);
  }).catch((err) => {
    console.log(err);
    removeAccessTokenCookie();
    removeRefreshTokenCookie();
  });
}

export function fetchDictionary(language: string): ThunkAction<Promise<any>, AppState, {}, AnyAction> {
  const url = `${URL.ANALYTICS_URL}/getlangresource/${language}`;
  return async (dispatch: ThunkDispatch<AppState, {}, AnyAction>): Promise<void> => {
    axios.get(url).then((response) => {
      return dispatch({
        type: Actions.FETCH_DICTIONARY,
        payload: response
      });
    });
  };
}

export function userLanguageSwitch(lang: string) {
  return {
    type: Actions.USER_LANGUAGE_SWITCH,
    payload: lang
  };
}

export function switchUserLanguage(langCode: string) {
  const url = `${URL.API_URL}/user/switch_language/${langCode}`;
  const AuthStr = 'Bearer '.concat(getAccessTokenCookie());
  return async (dispatch: ThunkDispatch<AppState, {}, AnyAction>): Promise<void> => {
    axios
      .post(url, null, { headers: { Authorization: AuthStr } })
      .then(() => {
        return dispatch(userLanguageSwitch(langCode));
      });
  };
}

export function setUserTSIDLoginSuccess(access_token: string, id_token: string) {
  setAccessTokenCookie(access_token);
  setIdTokenCookie(id_token);
  return {
    type: Actions.LOGIN_TSID_SUCCESS
  };
}

export function setErrore(errore: string) {
  window.location.href = "#/error-page?error_code=" + errore;
  return {
    type: Actions.SET_ERRORE,
    payload: errore
  };
}

export function simpleTokenCheck() {
  const url = `${URL.API_URL}/authorization/user/check`;
  const AuthStr = 'Bearer '.concat(getAccessTokenCookie());

  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: { Authorization: AuthStr } })
      .then((response) => { resolve(response); })
      .catch((err) => { reject(err); });
  });
}