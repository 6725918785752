import { IObjectStore } from './types';
import { Actions } from '../../actions';
import { TAuthGroup, IGetObjectCatalogSuccess } from '../../actions/authgroups/types';

const initialState: IObjectStore = {
  errorMsg: '',
  isError: false,
  isLoaded: false,
  data: {}
}

export function catalogReducer(state = initialState, action: IGetObjectCatalogSuccess): IObjectStore {
  switch (action.type) {
    case Actions.GET_OBJECT_CATALOG_SUCCESS:
      let appData: any = {};
      Object.assign(appData, state.data);
      appData[action.meta] = action.payload.data;

      return Object.assign({}, state, {
        isLoaded: true,
        isError: false,
        errorMsg: '',
        data: appData
      });

    default:
      return state;
  }
}