import { AppState } from '../../store/index';
import { Actions } from '../index';
import { TAuthGroup } from './types';
import axios from 'axios';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { URL, AUTH_ACTIONS } from '../../common/constants';
import { getAccessTokenCookie } from '../../common/functions';
import { refreshToken } from "../user/actions";
// import { createHeader, getMobileUrlCookie } from '../../common/functions';

export function createAuthGroup(idCompany: Number, codice: string, name: string, idParent: any) {
  const AuthStr = 'Bearer '.concat(getAccessTokenCookie());
  const url = `${URL.API_URL}/group/create/${idCompany}`;

  return async (dispatch: ThunkDispatch<AppState, {}, AnyAction>): Promise<void> => {
    return axios.post(url, { "code": codice, "name": name, "parentId": idParent }, { headers: { Authorization: AuthStr } }).then((response) => {
      dispatch(createAuthGroupSuccess(response));
    }).catch((error) => {
      dispatch(createAuthGroupFailure(error));
      if (error.response) {
        if (error.response.status == 403) {
          refreshToken(createAuthGroup(idCompany, codice, name, idParent), dispatch);
        }
      }
    });
  };
}

function createAuthGroupSuccess(response: any): TAuthGroup {
  return {
    type: Actions.CREATE_AUTHGROUP_SUCCESS,
    payload: response.data
  };
}

function createAuthGroupFailure(error: any): TAuthGroup {
  return {
    type: Actions.CREATE_AUTHGROUP_FAILURE,
    payload: error.response
  };
}

export function addUserToAuthGroup(payload: any) {
  const AuthStr = 'Bearer '.concat(getAccessTokenCookie());
  const url = `${URL.API_URL}/group/principal/add`;

  return async (dispatch: ThunkDispatch<AppState, {}, AnyAction>): Promise<void> => {
    return axios.post(url, payload, { headers: { Authorization: AuthStr } }).then((response) => {
      dispatch(addUserToAuthGroupSuccess(response));
    }).catch((error) => {
      dispatch(addUserToAuthGroupFailure(error));
      if (error.response) {
        if (error.response.status == 403) {
          refreshToken(addUserToAuthGroup(payload), dispatch);
        }
      }
    });
  };
}

function addUserToAuthGroupSuccess(response: any): TAuthGroup {
  return {
    type: Actions.AUTHGROUP_ADD_USER_SUCCESS,
    payload: response.data
  };
}

function addUserToAuthGroupFailure(error: any): TAuthGroup {
  return {
    type: Actions.AUTHGROUP_ADD_USER_FAILURE,
    payload: error.response
  };
}

export function moveUserToAuthGroup() {
  const AuthStr = 'Bearer '.concat(getAccessTokenCookie());
  const url = `${URL.API_URL}/group/principal/add`;

  return async (dispatch: ThunkDispatch<AppState, {}, AnyAction>): Promise<void> => {
    return axios.post(url, { headers: { Authorization: AuthStr } }).then((response) => {
      dispatch(moveUserToAuthGroupSuccess(response));
    }).catch((error) => {
      dispatch(moveUserToAuthGroupFailure(error));
      if (error.response) {
        if (error.response.status == 403) {
          refreshToken(moveUserToAuthGroup(), dispatch);
        }
      }
    });
  };
}

function moveUserToAuthGroupSuccess(response: any): TAuthGroup {
  return {
    type: Actions.AUTHGROUP_MOVE_USER_SUCCESS,
    payload: response.data
  };
}

function moveUserToAuthGroupFailure(error: any): TAuthGroup {
  return {
    type: Actions.AUTHGROUP_MOVE_USER_FAILURE,
    payload: error.response
  };
}

export function dragUserToAuthGroup(changeDto: any) {
  const AuthStr = 'Bearer '.concat(getAccessTokenCookie());
  const url = `${URL.API_URL}/group/principal/move`;
  return async (dispatch: ThunkDispatch<AppState, {}, AnyAction>): Promise<void> => {
    return axios.put(url, changeDto, { headers: { Authorization: AuthStr } }).then((response) => {
      dispatch(dragUserToAuthGroupSuccess(response));
    }).catch((error) => {
      dispatch(dragUserToAuthGroupFailure(error));
      if (error.response) {
        if (error.response.status == 403) {
          refreshToken(dragUserToAuthGroup(changeDto), dispatch);
        }
      }
    });
  };
}

function dragUserToAuthGroupSuccess(response: any): TAuthGroup {
  return {
    type: Actions.AUTHGROUP_MOVE_USER_SUCCESS,
    payload: response.data
  };
}

function dragUserToAuthGroupFailure(error: any): TAuthGroup {
  return {
    type: Actions.AUTHGROUP_MOVE_USER_FAILURE,
    payload: error.response
  };
}

export function getAuthGroupHierarchy(idCompany: Number) {
  const AuthStr = 'Bearer '.concat(getAccessTokenCookie());
  const url = `${URL.API_URL}/group/hierarchy/${idCompany}`;
  return async (dispatch: ThunkDispatch<AppState, {}, AnyAction>): Promise<any> => {
    return axios.get(url, { headers: { Authorization: AuthStr } }).then((response) => {
      return dispatch(getAuthGroupHierarchySuccess(response));
    }).catch((error) => {
      dispatch(getAuthGroupHierarchyFailure(error));
      if (error.response) {
        if (error.response.status == 403) {
          refreshToken(getAuthGroupHierarchy(idCompany), dispatch);
        }
      }
    });
  };
}

function getAuthGroupHierarchySuccess(response: any): TAuthGroup {
  return {
    type: Actions.AUTHGROUP_HIERARCHY_SUCCESS,
    payload: response.data
  };
}

function getAuthGroupHierarchyFailure(error: any): TAuthGroup {
  return {
    type: Actions.AUTHGROUP_HIERARCHY_FAILURE,
    payload: error.response
  };
}

export function getObjectCatalog(idCompany: Number, idService: Number) {
  const AuthStr = 'Bearer '.concat(getAccessTokenCookie());
  const url = `${URL.API_URL}/object_catalog/${idService}/${idCompany}`;
  return async (dispatch: ThunkDispatch<AppState, {}, AnyAction>): Promise<any> => {
    return axios.get(url, { headers: { Authorization: AuthStr } }).then((response) => {
      return dispatch(getObjectCatalogSuccess(response, idService));
    }).catch((error) => {
      dispatch(getObjectCatalogFailure(error));
      if (error.response) {
        if (error.response.status == 403) {
          refreshToken(getObjectCatalog(idCompany, idService), dispatch);
        }
      }
    });
  };
}

function getObjectCatalogSuccess(response: any, idService: Number): TAuthGroup {
  return {
    type: Actions.GET_OBJECT_CATALOG_SUCCESS,
    payload: response.data,
    meta: idService
  };
}

function getObjectCatalogFailure(error: any): TAuthGroup {
  return {
    type: Actions.GET_OBJECT_CATALOG_FAILURE,
    payload: error.response
  };
}

export function getCompanyAuthorizations(idCompany: Number, idService: Number, idPrincipal: string, principalType: string) {
  const AuthStr = 'Bearer '.concat(getAccessTokenCookie());
  const url = `${URL.API_URL}/authorization/${idService}/${idCompany}?principalId=${idPrincipal}&principalType=${principalType}`;
  return async (dispatch: ThunkDispatch<AppState, {}, AnyAction>): Promise<any> => {
    return axios.get(url, { headers: { Authorization: AuthStr } }).then((response) => {
      return dispatch(getCompanyAuthorizationsSuccess(response, idService));
    }).catch((error) => {
      dispatch(getCompanyAuthorizationsFailure(error));
      if (error.response) {
        if (error.response.status == 403) {
          refreshToken(getCompanyAuthorizations(idCompany, idService, idPrincipal, principalType), dispatch);
        }
      }
    });
  };
}

function getCompanyAuthorizationsSuccess(response: any, idService: Number): TAuthGroup {
  return {
    type: Actions.GET_COMPANY_AUTHORIZATIONS_SUCCESS,
    payload: response.data,
    meta: idService
  };
}

function getCompanyAuthorizationsFailure(error: any): TAuthGroup {
  return {
    type: Actions.GET_COMPANY_AUTHORIZATIONS_FAILURE,
    payload: error.response
  };
}

export function changeCompanyAuthorizations(idCompany: Number, idService: Number, actionIndex: number) {
  const AuthStr = 'Bearer '.concat(getAccessTokenCookie());
  const url = `${URL.API_URL}/authorization/${idService}/${idCompany}`;
  return async (dispatch: ThunkDispatch<AppState, {}, AnyAction>): Promise<any> => {
    return axios.put(url, JSON.stringify(AUTH_ACTIONS[actionIndex]), { headers: { Authorization: AuthStr, "Content-Type": "application/json" } }).then((response) => {
      return dispatch(changeCompanyAuthorizationsSuccess(response, idService));
    }).catch((error) => {
      dispatch(changeCompanyAuthorizationsFailure(error));
      if (error.response) {
        if (error.response.status == 403) {
          refreshToken(changeCompanyAuthorizations(idCompany, idService, actionIndex), dispatch);
        }
      }
    });
  };
}

function changeCompanyAuthorizationsSuccess(response: any, idService: Number): TAuthGroup {
  return {
    type: Actions.CHANGE_COMPANY_AUTHORIZATIONS_SUCCESS,
    payload: response.data,
    meta: idService
  };
}

function changeCompanyAuthorizationsFailure(error: any): TAuthGroup {
  return {
    type: Actions.CHANGE_COMPANY_AUTHORIZATIONS_FAILURE,
    payload: error.response
  };
}

export function changePrincipalAuthorization(payload: any, idCompany: number) {
  const AuthStr = 'Bearer '.concat(getAccessTokenCookie());
  const url = `${URL.API_URL}/authorization/principal/change/${idCompany}`;
  return async (dispatch: ThunkDispatch<AppState, {}, AnyAction>): Promise<any> => {
    return axios.post(url, payload, { headers: { Authorization: AuthStr, 'Content-Type': 'application/json' } }).then((response) => {
      return dispatch(changePrincipalAuthorizationSuccess(response));
    }).catch((error) => {
      dispatch(changePrincipalAuthorizationFailure(error));
      if (error.response) {
        if (error.response.status == 403) {
          refreshToken(changePrincipalAuthorization(payload, idCompany), dispatch);
        }
      }
    });
  };
}

function changePrincipalAuthorizationSuccess(response: any): TAuthGroup {
  return {
    type: Actions.GET_NODE_AUTHORIZATIONS_SUCCESS,
    payload: response.data,
  };
}

function changePrincipalAuthorizationFailure(error: any): TAuthGroup {
  return {
    type: Actions.GET_NODE_AUTHORIZATIONS_FAILURE,
    payload: error.response
  };
}

export function getGroupAuthorizations(idCompany: Number, idService: Number, idPrincipal: string, principalType: string) {
  const AuthStr = 'Bearer '.concat(getAccessTokenCookie());
  const url = `${URL.API_URL}/authorization/${idService}/${idCompany}/${principalType}/${idPrincipal}`;
  return async (dispatch: ThunkDispatch<AppState, {}, AnyAction>): Promise<any> => {
    return axios.get(url, { headers: { Authorization: AuthStr, 'Content-Type': 'application/json' } }).then((response) => {
      return dispatch(getGroupAuthorizationsSuccess(response, idService));
    }).catch((error) => {
      dispatch(getGroupAuthorizationsFailure(error));
      if (error.response) {
        if (error.response.status == 403) {
          refreshToken(getGroupAuthorizations(idCompany, idService, idPrincipal, principalType), dispatch);
        }
      }
    });
  };
}

function getGroupAuthorizationsSuccess(response: any, idService: Number): TAuthGroup {
  return {
    type: Actions.GET_NODE_AUTHORIZATIONS_SUCCESS,
    payload: response.data,
  };
}

function getGroupAuthorizationsFailure(error: any): TAuthGroup {
  return {
    type: Actions.GET_NODE_AUTHORIZATIONS_FAILURE,
    payload: error.response
  };
}

export function deleteUserFromGroup(idCompany: Number, idGroup: Number, idPrincipal: string, principalType: string) {
  const AuthStr = 'Bearer '.concat(getAccessTokenCookie());
  const url = `${URL.API_URL}/group/principal/remove/${idGroup}/${principalType}/${idPrincipal}`;
  return async (dispatch: ThunkDispatch<AppState, {}, AnyAction>): Promise<any> => {
    return axios.delete(url, { headers: { Authorization: AuthStr, 'Content-Type': 'application/json' } }).then((response) => {
      dispatch(deleteUserFromGroupSuccess(response));
      dispatch(getAuthGroupHierarchy(idCompany));
    }).catch((error) => {
      dispatch(deleteUserFromGroupFailure(error));
      if (error.response) {
        if (error.response.status == 403) {
          refreshToken(deleteUserFromGroup(idCompany, idGroup, idPrincipal, principalType), dispatch);
        }
      }
    });
  };
}

function deleteUserFromGroupSuccess(response: any): TAuthGroup {
  return {
    type: Actions.DELETE_USER_FROM_GROUP_SUCCESS,
    payload: response.data,
  };
}

function deleteUserFromGroupFailure(error: any): TAuthGroup {
  return {
    type: Actions.DELETE_USER_FROM_GROUP_FAILURE,
    payload: error.response
  };
}

export function updateGroup(idCompany: Number, idGroup: Number, data: any) {
  const AuthStr = 'Bearer '.concat(getAccessTokenCookie());
  const url = `${URL.API_URL}/group/update/${idCompany}/${idGroup}`;
  return async (dispatch: ThunkDispatch<AppState, {}, AnyAction>): Promise<any> => {
    return axios.put(url, data, { headers: { Authorization: AuthStr, 'Content-Type': 'application/json' } }).then((response) => {
      dispatch(updateGroupSuccess(response));
      dispatch(getAuthGroupHierarchy(idCompany));
    }).catch((error) => {
      dispatch(updateGroupFailure(error));
      if (error.response) {
        if (error.response.status == 403) {
          refreshToken(updateGroup(idCompany, idGroup, data), dispatch);
        }
      }
    });
  };
}

function updateGroupSuccess(response: any): TAuthGroup {
  return {
    type: Actions.UPDATE_GROUP_SUCCESS,
    payload: response.data,
  };
}

function updateGroupFailure(error: any): TAuthGroup {
  return {
    type: Actions.UPDATE_GROUP_FAILURE,
    payload: error.response
  };
}

export function deleteGroup(idCompany: Number, idGroup: Number) {
  const AuthStr = 'Bearer '.concat(getAccessTokenCookie());
  const url = `${URL.API_URL}/group/delete/${idGroup}`;
  return async (dispatch: ThunkDispatch<AppState, {}, AnyAction>): Promise<any> => {
    return axios.delete(url, { headers: { Authorization: AuthStr, 'Content-Type': 'application/json' } }).then((response) => {
      dispatch(deleteGroupSuccess(response));
      dispatch(getAuthGroupHierarchy(idCompany));
    }).catch((error) => {
      dispatch(deleteGroupFailure(error));
      if (error.response) {
        if (error.response.status == 403) {
          refreshToken(deleteGroup(idCompany, idGroup), dispatch);
        }
      }
    });
  };
}

function deleteGroupSuccess(response: any): TAuthGroup {
  return {
    type: Actions.DELETE_AUTHGROUP_SUCCESS,
    payload: response.data,
  };
}

function deleteGroupFailure(error: any): TAuthGroup {
  return {
    type: Actions.DELETE_AUTHGROUP_FAILURE,
    payload: error.response
  };
}

export function usersGroupList(idGroup: Number) {
  const AuthStr = 'Bearer '.concat(getAccessTokenCookie());
  const url = `${URL.API_URL}/group/usersToAdd/${idGroup}`;
  return async (dispatch: ThunkDispatch<AppState, {}, AnyAction>): Promise<any> => {
    return axios.get(url, { headers: { Authorization: AuthStr, 'Content-Type': 'application/json' } }).then((response) => {
      return dispatch(usersGroupListSuccess(response));
    }).catch((error) => {
      dispatch(usersGroupListFailure(error));
      if (error.response) {
        if (error.response.status == 403) {
          refreshToken(usersGroupList(idGroup), dispatch);
        }
      }
    });
  };
}

function usersGroupListSuccess(response: any): TAuthGroup {
  return {
    type: Actions.GET_USERS_TO_ADD_SUCCESS,
    payload: response.data,
  };
}

function usersGroupListFailure(error: any): TAuthGroup {
  return {
    type: Actions.GET_USERS_TO_ADD_FAILURE,
    payload: error.response
  };
}

export function getObjectAuthorizedPrincipal(idCompany: number, idService: number, idObject: number) {
  const AuthStr = 'Bearer '.concat(getAccessTokenCookie());
  const url = `${URL.API_URL}/authorization/${idService}/${idCompany}/${idObject}`;
  return async (dispatch: ThunkDispatch<AppState, {}, AnyAction>): Promise<any> => {
    return axios.get(url, { headers: { Authorization: AuthStr, 'Content-Type': 'application/json' } }).then((response) => {
      return dispatch(getObjectAuthorizedPrincipalSuccess(response));
    }).catch((error) => {
      dispatch(getObjectAuthorizedPrincipalFailure(error));
      if (error.response) {
        if (error.response.status == 403) {
          refreshToken(getObjectAuthorizedPrincipal(idCompany, idService, idObject), dispatch);
        }
      }
    });
  };
}

function getObjectAuthorizedPrincipalSuccess(response: any): TAuthGroup {
  return {
    type: Actions.GET_USERS_TO_ADD_SUCCESS,
    payload: response.data,
  };
}

function getObjectAuthorizedPrincipalFailure(error: any): TAuthGroup {
  return {
    type: Actions.GET_USERS_TO_ADD_FAILURE,
    payload: error.response
  };
}

export function changePrincipalAuthorizationBatch(idCompany: number, obj: any) {
  const AuthStr = 'Bearer '.concat(getAccessTokenCookie());
  const url = `${URL.API_URL}/authorization/principal/change/batch/${idCompany}`;
  return async (dispatch: ThunkDispatch<AppState, {}, AnyAction>): Promise<any> => {
    return axios.post(url, obj, { headers: { Authorization: AuthStr, 'Content-Type': 'application/json' } }).then((response) => {
      return dispatch(changePrincipalAuthorizationBatchSuccess(response));
    }).catch((error) => {
      dispatch(changePrincipalAuthorizationBatchFailure(error));
      if (error.response) {
        if (error.response.status == 403) {
          refreshToken(changePrincipalAuthorizationBatch(idCompany, obj), dispatch);
        }
      }
    });
  };
}

function changePrincipalAuthorizationBatchSuccess(response: any): TAuthGroup {
  return {
    type: Actions.CHANGE_PRINCIPAL_AUTH_BATCH_SUCCESS,
    payload: response.data,
  };
}

function changePrincipalAuthorizationBatchFailure(error: any): TAuthGroup {
  return {
    type: Actions.CHANGE_PRINCIPAL_AUTH_BATCH_FAILURE,
    payload: error.response
  };
}
