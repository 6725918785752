import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheckSquare } from '@fortawesome/pro-light-svg-icons';
import { faTimesSquare } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Spinner } from 'reactstrap';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { getCompanyAuthorizations } from '../../actions/authgroups/actions';
import { AppState } from '../../store';
import ServiceNav from '../Navigation/ServiceNav';
import ModaleCompanyAuths from './ModaleCompanyAuths';
import { setCurrentService } from "../../actions/user/actions";
import { dictionaryGet, stringTemplateReplace } from '../../common/functions';

type Props = {
  node: any,
  setActiveNode: Function;
};

export default function CompanyAuthorizations(props: Props) {
  const currentService = useSelector((state: AppState) => state.user.currentService);
  const thunkDispatch = useDispatch<ThunkDispatch<AppState, any, AnyAction>>();
  const current = useSelector((state: AppState) => state.user.current);
  const [authorization, setAuthorization] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selServ, setSelServ] = useState<any>();
  const [company, setCompany] = useState<number>(999);
  const [actionIndex, setActionIndex] = useState<number>(999);
  const [principal, setPrincipal] = useState<any>();
  const dispatch = useDispatch();
  const dictionary = useSelector((state: AppState) => state.dictionary);

  useEffect(() => {
    if (props.node !== undefined && current.selectedCompany != null) {
      thunkDispatch(getCompanyAuthorizations(current.selectedCompany.company.id, current.selectedCompany.service[currentService].id, props.node.id, props.node.type)).then((data) => {
        setAuthorization(data.payload.data);
      });
    }
  }, [props.node]);

  const changeService = (tab: number) => {
    dispatch(setCurrentService(tab));
  };

  const changeAuth = (actionIndex: number) => {
    if (current.selectedCompany !== null) {
      const idCompany = current.selectedCompany.company.id;
      const idService = current.selectedCompany.service[currentService].id;
      setSelServ(current.selectedCompany.service[currentService]);
      setCompany(idCompany);
      setActionIndex(actionIndex);
      setPrincipal(props.node);
      setIsModalOpen(true);
    }
  };

  const closeModal = (idCompany: number, idService: number, principal: any) => {
    setIsModalOpen(false);
    thunkDispatch(getCompanyAuthorizations(idCompany, idService, principal.id, principal.type)).then((data) => {
      setAuthorization(data.payload.data);
    });
  };

  const authCaption = (serviceName:string) => {
    let lab = dictionaryGet(dictionary, "global.console.authorizations.page_caption");
    let key = {"serviceName": serviceName};
    lab = stringTemplateReplace(lab, key);
    return lab;
  }

  if (props.node !== undefined && current.selectedCompany != null) {
    return (
      <div className="company-auth-wrapper">
        {selServ !== undefined ? <ModaleCompanyAuths principal={principal} actionIndex={actionIndex} closeModal={closeModal} idCompany={company} isModalOpen={isModalOpen} selectedServ={selServ} /> : ""}
        <div className="container">
          <div className="auth-nav">
            <ServiceNav activeTab={currentService} changeService={changeService} selectedCompany={current.selectedCompany} />
          </div>
          <div className="text-wrapper">
            <span>{ authCaption(current.selectedCompany.service[currentService].name) }:</span>
          </div>
          <div style={{ marginTop: "10px" }} className="company-buttons">
            <Button style={{ width: "50%" }} outline className={"btn-authgroup green " + (authorization === 'ALLOW' ? "selected" : "")} onClick={() => { changeAuth(0); }}><FontAwesomeIcon style={{ marginRight: "5px" }} icon={faCheckSquare} /> { dictionaryGet(dictionary, "global.console.authorizations.allow") }</Button>
            <Button style={{ width: "50%" }} outline className={"btn-authgroup red " + (authorization === 'DENY' ? "selected" : "")} onClick={() => { changeAuth(1); }}  ><FontAwesomeIcon style={{ marginRight: "5px" }} icon={faTimesSquare as IconProp} /> { dictionaryGet(dictionary, "global.console.authorizations.deny") }</Button>
          </div>
        </div>
      </div>
    );
  } else {
    return <Spinner />;
  }
}
