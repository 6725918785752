import React from "react";
import { Row, Col, Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { dictionaryGet} from '../../common/functions';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';

type Props = {
  isModalOpen: boolean,
  toggleModal: Function
}

export default function NoInvitationsSelected(props: Props) {
  const dictionary = useSelector((state: AppState) => state.dictionary);
  const modalBody = () => {
    return (
      <>
        <Row style={{ marginBottom: '25px' }}>
          <Col md="1"><FontAwesomeIcon icon={faExclamationTriangle as IconProp} style={{ color: "orange" }} /></Col>
          <Col md="11"><span><strong>{ dictionaryGet(dictionary, "global.console.invitations.no_invitations_selected")}</strong></span>
          </Col>
        </Row>
        <Row style={{ marginTop: '25px', fontSize: '14px' }}>
          <Col md="1"></Col>
          <Col
            md="11"><span> { dictionaryGet(dictionary, "global.console.invitations.invitation_select_recipients")}</span>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Modal isOpen={props.isModalOpen} toggle={() => {
      props.toggleModal()
    }} style={{ width: '35vw', height: 'auto', maxWidth: '20vw' }}>
      <ModalBody style={{ marginLeft: '15px', marginRight: '15px' }}>
        {modalBody()}
      </ModalBody>
      <ModalFooter>
        <Button className="btn-form-primary" color="primary" onClick={() => {
          props.toggleModal()
        }}>OK</Button>
      </ModalFooter>
    </Modal>
  );
}
